<template>
  <div class="grid-x">
    <div class="cell">
      <v-card>
        <v-card-title>
          <h1 class="display-2 grey--text">Old Orders Report</h1>
        </v-card-title>
        <v-card-text>
          <div class="search-input">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search by Order ID or Client Name"
              @keyup="applySearchQuery"
            />
          </div>
          <div class="count italic">Total Results: {{ count }}</div>
          <v-data-table
            :headers="headers"
            :items="packages"
            hide-actions
            item-key="name"
            class="elevation-1"
          >
            <template slot="headers" slot-scope="props">
              <tr>
                <th v-for="header in props.headers" :key="header.text">{{ header.text }}</th>
              </tr>
            </template>
            <template slot="items" slot-scope="props">
              <tr>
                <td class="text-xs-center">{{ props.item.client }}</td>
                <td class="text-xs-center">
                  <router-link
                    :to="{ name: 'order.detail', params: { id: props.item.id } }"
                    target="_blank"
                  >{{ props.item.id }}</router-link>
                </td>
                <td class="text-xs-center">{{ props.item.package_count }}</td>
                <td class="text-xs-center">{{ props.item.hasInvoice }}</td>
                <td class="text-xs-center">{{ props.item.invoiceCreated || 'no' }}</td>
                <td class="text-xs-center">{{ props.item.created_at }}</td>
                <td class="text-xs-center">
                  <button
                    type="button"
                    class="approve-btn"
                    @click="approveForSale(props.item.id, props.item.invoiceCreated)"
                  >Approve</button>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="load-more-container" v-if="!loading">
            <!-- <v-btn
              color="primary"
              class="float-center"
              @click="loadMore"
              :loading="loadingMore"
              :disabled="!canLoadMore"
            >Load More</v-btn>-->
            <label>
              Page:
              <select v-model="currentPageIndex" @change="getPackages()">
                <option :value="page" v-for="(page, i) in totalPages" :key="i">{{page}}</option>
              </select>
            </label>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import debounce from "lodash/debounce";
// import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    // InfiniteLoading
  },

  created() {
    this.getPackages();
  },

  data() {
    return {
      loading: false,
      packages: [],
      currentPageIndex: 1,
      totalPages: null,
      canLoadMore: false,
      loadingMore: false,
      count: 0,
      searchQuery: "",
      headers: [
        { text: "Client" },
        { text: "Order" },
        { text: "# of Pkgs." },
        { text: "Has Invoice" },
        { text: "Inv. Created" },
        { text: "Created On" },
        { text: "Approved For Sale" }
      ]
    };
  },

  computed: {
    //   pages () {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.packages.length / this.pagination.rowsPerPage) : 0
    //   }
  },

  validations: {
    c13Form: {
      date: {
        required
      }
    }
  },

  methods: {
    ...mapActions(["fetchOldPackages"]),
    applySearchQuery: debounce(function() {
      this.currentPageIndex = 1;
      this.getPackages();
    }, 500),
    getPackages() {
      this.loading = true;
      let payload = {
        page: this.currentPageIndex,
        search: this.searchQuery
      };
      this.fetchOldPackages(payload).then(response => {
        this.loading = false;
        this.packages = response.data;
        this.currentPageIndex = response.meta.current_page;
        this.totalPages = response.meta.last_page;
        this.count = response.meta.total;
        this.canLoadMore =
          response.meta.current_page < response.meta.last_page ? true : false;
      });
    },
    approveForSale(orderId, invoiceCreated) {
      // if (!invoiceCreated) {
      //   alert("Invoice must be created first.");
      //   return false;
      // }

      this.$store.dispatch("approveForSale", orderId).then(res => {
        this.getPackages();
      });
    },

    // loadMore() {
    //   let newIndex = this.currentPageIndex + 1;
    //   this.loadingMore = true;
    //   this.fetchOldPackages(newIndex).then(response => {
    //     this.loadingMore = false;
    //     this.packages.push(...response.data);
    //     this.currentPageIndex = response.meta.current_page;
    //     this.totalPages = response.meta.last_page;
    //     this.canLoadMore =
    //       response.meta.current_page < response.meta.last_page ? true : false;
    //   });
    // },

    goToOrder(id) {
      this.$router.push({ name: "order.detail", params: { id: id } });
    }
  }
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  margin-top: 2rem;
}
.load-more-container {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}
.load-more-container select {
  background: #ddd;
  padding: 5px 15px;
}
.approve-btn {
  padding: 5px 10px;
  background: #ddd;
}
.approve-btn:hover {
  background: #1976d2;
  color: white;
}
.search-input {
  margin-bottom: 10px;
  input {
    padding: 5px 15px;
    background: #ddd;
    width: 300px;
    outline: none;
  }
}
</style>


