<template>
  <div>
    <form autocomplete="off">
      <div class="grid-x grid-margin-x grid-margin-y">
        <div class="cell small-12 medium-6">
          <v-text-field
            required
            label="First Name"
            name="first_name"
            v-model="form.first_name"
          >
          </v-text-field>
        </div>
        <div class="cell small-12 medium-6">
          <v-text-field
            required
            label="Last Name"
            name="last_name"
            v-model="form.last_name"
          >
          </v-text-field>
        </div>
        <div class="cell small-12 medium-6">
          <v-text-field
            required
            label="Email"
            name="email"
            v-model="form.email"
          >
          </v-text-field>
        </div>
        <div class="cell small-12 medium-6">
          <v-text-field
            label="Password"
            name="password"
            type="password"
            v-model="form.password"
          >
          </v-text-field>
        </div>
        <div class="cell small-12 medium-6">
          <v-text-field
            required
            label="Home Phone"
            name="home"
            v-model="form.home_phone"
          >
          </v-text-field>
        </div>
        <div class="cell small-12 medium-6">
          <v-text-field
            required
            label="Mobile Phone"
            name="mobile"
            v-model="form.mobile_phone"
          >
          </v-text-field>
        </div>
        <div class="cell small-12 medium-6">
          <v-select
            required
            label="Preferred Pickup Location"
            name="location"
            :items="locations"
            item-text="name"
            item-value="id"
            v-model="form.location"
          >
          </v-select>
        </div>
        <div class="cell">
          <v-btn
            color="primary"
            @click="editProfile"
            :disabled="$v.form.$invalid"
          >
            Update Profile
          </v-btn>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  props: ["user", "locations"],

  data() {
    return {
      form: {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        password: "",
        home_phone: this.user.home_phone,
        mobile_phone: this.user.mobile_phone,
        location: this.user.location.data.id,
        notes: this.user.notes,
      },
    };
  },

  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      // home_phone: {
      //     required
      // },
      mobile_phone: {
        required,
      },
      location: {
        required,
      },
    },
  },

  methods: {
    editProfile() {
      this.$emit("update-user", this.form);
    },
  },
};
</script>

