<template>
  <div class="grid-container fluid mt-3">
    <div class="grid-x">
      <div class="cell">
        <v-card>
          <span class="float-right">
            <v-btn color="primary" @click.prevent="newUserDialog = true"
              >Create New User</v-btn
            >
          </span>
          <v-card-title>
            <h1 class="display-2 grey--text">Customers</h1>
          </v-card-title>
          <v-card-text>
            <div class="grid-x grid-margin-y">
              <div class="cell medium-4">
                <v-text-field
                  v-model="key"
                  label="Search Customers"
                  name="search"
                ></v-text-field>
              </div>
              <div class="cell medium-4">
                <v-btn color="primary" @click="filterCustomers()">
                  Search
                </v-btn>
              </div>
              <div class="cell">
                <CustomersGrid
                  :customers="customers"
                  v-if="!loadingCustomers"
                  @flag-customer="flag"
                  @load-customer="loadCustomerModal"
                  @get-orders="loadOrdersModal"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="newUserDialog" max-width="750">
      <RegistrationForm
        :islands="islands"
        :locations="locations"
        @register-user="register"
      />
    </v-dialog>
    <v-dialog v-model="editUserDialog" max-width="750">
      <v-card>
        <v-card-text>
          <UserProfileAdmin
            :user="loadedUser"
            :locations="locations"
            @update-user="updateUserData"
            v-if="userLoaded"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="userOrdersDialog" max-width="600">
      <v-card>
        <v-card-title>{{ loadedUserOrders.length }} orders</v-card-title>
        <v-card-text>
          <v-card
            hover
            class="internal-order-card"
            v-for="(order, index) in loadedUserOrders"
            :key="index"
            :to="{ name: 'order.detail', params: { id: order.id } }"
          >
            <v-card-text
              >{{ order.id }} - {{ order.description }} -
              {{ order.created_at }}</v-card-text
            >
          </v-card>
          <div class="cell small-12 text-xs-center">
            <strong>Page #{{ currentPage }}</strong>
            <br />
            <v-btn color="red lighten-1" dark @click="switchPage('prev')"
              >Previous</v-btn
            >
            <v-btn color="red lighten-1" dark @click="switchPage('next')"
              >Next</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="cell small-12 text-xs-center mt-4">
      <label>
        Page #
        <select v-model="currentPage" @change="getCustomers" class="font-bold">
          <option :value="page" v-for="(page, i) in totalPages" :key="i">
            {{ page }}
          </option>
        </select>
      </label>
      <br />
      <v-btn color="red lighten-1" dark @click="switchPage(-1)">Previous</v-btn>
      <v-btn color="red lighten-1" dark @click="switchPage(1)">Next</v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RegistrationForm from "../../components/register/RegistrationForm.vue";
import CustomersGrid from "../../components/admin/CustomersGrid.vue";
import UserProfileAdmin from "../../components/client/UserProfileAdmin.vue";

export default {
  created() {
    this.getLocations();
    this.getIslands();
    this.getCustomers();
  },

  components: {
    RegistrationForm,
    CustomersGrid,
    UserProfileAdmin,
  },

  data() {
    return {
      islands: [],
      locations: [],
      loadedUserOrders: [],
      search: "",
      loadingCustomers: false,
      loadingOrders: false,
      success: false,
      newUserDialog: false,
      editUserDialog: false,
      userOrdersDialog: false,
      userLoaded: false,
      loadedUser: null,
      totalPages: null,
      currentPage: 1,
      customers: [],
      key: "",
    };
  },
  methods: {
    ...mapActions([
      "fetchLocations",
      "fetchIslands",
      "fetchCustomersPaginated",
      "fetchUserProfile",
      "createUser",
      "editUserProfile",
      "fetchUserOrdersList",
      "flagUser",
    ]),

    switchPage(num) {
      this.currentPage = this.currentPage + num;
      this.getCustomers();
    },

    filterCustomers() {
      this.currentPage = 1;
      this.getCustomers();
    },

    getCustomers() {
      this.loadingCustomers = true;
      let payload = {
        page: this.currentPage,
        key: this.key,
      };
      this.fetchCustomersPaginated(payload).then((response) => {
        this.customers = response.data;
        this.currentPage = response.meta.pagination.current_page;
        this.totalPages = response.meta.pagination.total_pages;
        this.loadingCustomers = false;
      });
    },

    flag(user) {
      this.flagUser(user).then((res) => {
        this.getCustomers();
      });
    },

    getLocations() {
      this.fetchLocations().then((response) => {
        this.locations = response.data;
      });
    },

    getIslands() {
      this.fetchIslands().then((response) => {
        this.islands = response.data;
      });
    },

    register(data) {
      this.createUser(data).then((response) => {
        this.getCustomers();
        this.newUserDialog = false;
      });
    },

    loadCustomerModal(user) {
      this.userLoaded = false;
      this.fetchUserProfile(user).then((response) => {
        this.loadedUser = response.data;
        this.userLoaded = true;
        this.editUserDialog = true;
      });
    },

    loadOrdersModal(user) {
      this.loadingOrders = true;
      this.fetchUserOrdersList(user).then((response) => {
        this.loadedUserOrders = response.data;
        this.loadingOrders = false;
        this.userOrdersDialog = true;
      });
    },

    updateUserData(data) {
      let payload = {
        user: this.loadedUser.id,
        data: data,
      };

      this.editUserProfile(payload).then((response) => {
        this.getCustomers();
        this.editUserDialog = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.internal-order-card {
  margin-bottom: 1rem;
}
</style>


