import { reportService } from "../../../services";

export default {
	fetchEODReports({ commit }, payload) {
		return new Promise((resolve, reject) => {
			reportService.fetchEODReports().then(
				(response) => {
					resolve(response.data.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	createEOD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			reportService.createEOD(payload).then(
				(response) => {
					resolve(response.data.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchAccEODReports({ commit }, payload) {
		return new Promise((resolve, reject) => {
			reportService.fetchAccEODReports().then(
				(response) => {
					resolve(response.data.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	createAccEOD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			reportService.createAccEOD(payload).then(
				(response) => {
					resolve(response.data.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchEOD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			reportService.fetchEOD(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchAccEOD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			reportService.fetchAccEOD(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchOldPackages({ commit }, payload) {
		return new Promise((resolve, reject) => {
			reportService.fetchOldPackages(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchInvoiceUploads({ commit }) {
		return new Promise((resolve, reject) => {
			reportService.fetchInvoiceUploads().then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchClientOrderReport({ commit }, payload) {
		return new Promise((resolve, reject) => {
			reportService.fetchClientOrderReport(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
};
