<template>
  <div
      class="grid-container fluid mt-3"
      v-if="authUser.account_type == 'admin' || authUser.account_type == 'worker' || authUser.account_type == 'basic'"
  >
    <div class="grid-x grid-margin-x grid-margin-y">
      <div class="cell medium-4">
        <PackagesSearchInput @on-search="fetchPackages()" />
      </div>
      <div class="cell medium-5 medium-offset-2">
        <PackagesGridFilters @on-filter="fetchPackages()" @clear-filters="clearFilter()" />
      </div>
      <div class="cell small-12">
        <PackagesTable :packages="packages" v-if="packages.length > 0" />
      </div>
      <div class="cell small-12 text-xs-center">
        <strong>Page #{{ currentPage }}</strong>
        <br />
        <v-btn color="red lighten-1" dark @click="fetchNewPackagesPage('prev')">Previous</v-btn>
        <v-btn color="red lighten-1" dark @click="fetchNewPackagesPage('next')">Next</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PackagesTable from "../../components/packages/PackagesTable.vue";
import PackagesGridFilters from "../../components/packages/PackagesGridFilters.vue";
import PackagesSearchInput from "../../components/packages/PackagesSearchInput.vue";

export default {
  components: {
    PackagesTable,
    PackagesGridFilters,
    PackagesSearchInput
  },
  created() {
    if(this.authUser.account_type != "basic") {
      this.fetchPackages();
    }
  },
  data() {
    return {
      loadingPackages: false,
      nextPage: null,
      totalPages: null,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      packages: "packagesGridItems",
      authUser: "authUser"
    }),
    isSabrina() {
      return this.authUser.id == 5387 || this.authUser.id == 1243
          ? true
          : false;
    }
  },
  methods: {
    ...mapActions(["fetchPackagesGridItems"]),
    fetchPackages() {
      let payload = {
        query: this.$route.query.search,
        dates: this.$route.query.dates
            ? this.$route.query.dates.split(",")
            : null,
        method: this.$route.query.method,
        checkpoint: this.$route.query.checkpoint,
        page: 1
      };
      this.loadingPackages = true;
      this.fetchPackagesGridItems(payload).then(response => {
        this.loadingPackages = false;
        this.currentPage = response.meta.current_page;
        this.nextPage = response.meta.current_page + 1;
        this.totalPages = response.meta.last_page;
      });
    },
    fetchNewPackagesPage(button) {
      let payload = {
        query: this.$route.query.search,
        method: this.$route.query.method,
        checkpoint: this.$route.query.checkpoint,
        dates: this.$route.query.dates
            ? this.$route.query.dates.split(",")
            : null,
        page: button == "next" ? this.nextPage : this.currentPage - 1
      };
      this.loadingPackages = true;
      this.fetchPackagesGridItems(payload).then(response => {
        this.loadingPackages = false;
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            page: response.meta.current_page
          })
        });
        this.currentPage = response.meta.current_page;
        this.nextPage = response.meta.current_page + 1;
        this.totalPages = response.meta.last_page;
      });
    },
    clearFilter() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { page: 1 })
      });
      this.$router.push({
        query: Object.assign({}, this.$route.query, { search: "" })
      });
      this.$router.push({
        query: Object.assign({}, this.$route.query, { method: "" })
      });
      this.$router.push({
        query: Object.assign({}, this.$route.query, { dates: "" })
      });
      this.fetchPackages();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>