<template>
  <div class="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-3 large-up-4 main">
      <OrdersGridItem 
        v-for="(order, index) in orders"
        :key="index"
        :order="order"
        class="cell"/>
  </div>
</template>

<script>
import OrdersGridItem from '../../components/orders/OrdersGridItem.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        orders: Array
    },
    components: {
        OrdersGridItem
    },
     computed: {
      ...mapGetters([
          'authUser'
      ])
    },
}
</script>

<style lang="scss" scoped>
</style>


