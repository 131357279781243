<template>
  <v-app>
    <div class="calc-page-wrapper">
      <img
        src="../../assets/images/logo.png"
        alt="Ship 242"
        class="float-center logo"
      />
      <v-dialog persistent max-width="700px" v-model="modal" hide-overlay>
        <v-card>
          <v-card-title class="headline grey--text"
            >Estimate Your Ship242 Shipping Cost</v-card-title
          >
          <v-card-text>
            <div class="grid-x grid-margin-x grid-margin-y">
              <div class="cell small-12 medium-6">
                <!-- <v-select
                  label="Shipping Method"
                  v-model="form.shippingMethod"
                  @input="data = null"
                  :items="methods"
                  item-value="value"
                  item-text="label"
                ></v-select> -->
                <label>
                  <span class="block text-lg">Shipping Method</span>
                  <div class="border border-gray-500">
                    <select
                      v-model="form.shippingMethod"
                      class="h-10 px-2 w-full"
                      @input="data = null"
                    >
                      <option
                        :value="method.value"
                        v-for="(method, i) in methods"
                        :key="i"
                        :disabled="
                          method.value == 'consolidateN' ||
                            method.value == 'consolidateS'
                        "
                      >
                        {{ method.label }}
                      </option>
                    </select>
                  </div>
                </label>
              </div>
              <div class="cell small-12 medium-6">
                <!-- <v-select
                  label="Arrival In Miami"
                  v-model="form.arrival"
                  @input="data = null"
                  :items="days"
                ></v-select> -->
                <label>
                  <span class="block text-lg">Arrival in Miami</span>
                  <div class="border border-gray-500">
                    <select
                      v-model="form.arrival"
                      class="h-10 px-2 w-full"
                      @input="data = null"
                    >
                      <option :value="day" v-for="(day, i) in days" :key="i">
                        {{ day }}
                      </option>
                    </select>
                  </div>
                </label>
              </div>
              <div class="cell small-12 medium-3">
                <v-text-field
                  label="Weight (lbs)"
                  v-model="form.weight"
                  @input="data = null"
                  required
                ></v-text-field>
              </div>
              <div class="cell small-12 medium-3">
                <v-text-field
                  label="Length (in)"
                  v-model="form.length"
                  @input="data = null"
                  required
                ></v-text-field>
              </div>
              <div class="cell small-12 medium-3">
                <v-text-field
                  label="Width (in)"
                  v-model="form.width"
                  @input="data = null"
                  required
                ></v-text-field>
              </div>
              <div class="cell small-12 medium-3">
                <v-text-field
                  label="Height (in)"
                  v-model="form.height"
                  @input="data = null"
                  required
                ></v-text-field>
              </div>
            </div>
            <div class="grid-x total" v-if="data">
              <div class="cell">
                <span class="headline"
                  >Estimated Shipping Cost: ${{ data.cost }}</span
                >
              </div>
              <div class="cell">
                <span class="headline"
                  >Estimated Delivery Day: {{ data.eta }}</span
                >
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="calculate"
              color="primary"
              :disabled="$v.form.$invalid"
              >Calculate</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      modal: true,
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      methods: [
        {
          value: "standard",
          label: "Standard",
        },
        {
          value: "next-day",
          label: "Priority",
        },
        {
          value: "consolidation",
          label: "Consolidation",
        },
        // {
        //   value: "code-red",
        //   label: "Code Red",
        // },
        {
          value: "consolidate-sm",
          label: "Consolidation (Small - 10 packages)",
        },
        {
          value: "consolidate-lg",
          label: "Consolidation (Large - 20 packages)",
        },
        // {
        //   value: "consolidateN",
        //   label: "Consolidate (next-day) - NOT CURRENTLY AVAILABLE",
        // },
        // {
        //   value: "consolidateS",
        //   label: "Consolidate (standard) - NOT CURRENTLY AVAILABLE",
        // },
        {
          value: "special",
          label: "Special",
        },
      ],
      form: {
        arrival: "",
        shippingMethod: "",
        length: "",
        width: "",
        height: "",
        weight: "",
      },
      data: null,
    };
  },

  validations: {
    form: {
      arrival: {
        required,
      },
      shippingMethod: {
        required,
      },
      length: {
        required,
      },
      width: {
        required,
      },
      height: {
        required,
      },
      weight: {
        required,
      },
    },
  },

  methods: {
    ...mapActions(["calculateETA"]),

    calculate() {
      this.calculateETA(this.form).then((response) => {
        this.data = response;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.calc-page-wrapper {
  height: 100vh;
  background-color: #fff;
}
.logo {
  margin-top: 100px;
}
</style>
