<template>
  <div class="wrapper">
      <table>
          <thead>
              <tr>
                  <th>Shipment ID</th>
                  <th>Manifest</th>
                  <th>Carrier</th>
                  <th>Packages</th>
                  <th>Arrival Date</th>
                  <th>Edit</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(shipment, index) in shipments" :key="index" class="shipment-row">
                  <td>
                      <router-link :to="{ name: 'shipments.detail', params: { id: shipment.id } }">
                          #{{ shipment.id }}
                      </router-link>
                  </td>
                  <td>
                      {{ shipment.manifest_key }}
                  </td>
                  <td>
                      {{ shipment.carrier }}
                  </td>
                  <td>
                      {{ shipment.packages_count }}
                  </td>
                  <td>
                      {{ shipment.arrival_date }}
                  </td>
                  <td>
                      <router-link :to="{ name: 'shipments.edit', params: { id: shipment.id } }">
                          <v-icon>fa-pencil</v-icon>
                      </router-link>
                  </td>
              </tr>
          </tbody>
      </table>
      <!-- <div class="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-3 x-large-up-4" v-if="!loading">
      <v-card class="cell shipment" v-for="(shipment, index) in shipments" :key="index">
          <v-card-title class="headline">
              #{{ shipment.id }}
          </v-card-title>
          <v-card-text>
              <div class="grid-x grid-margin-x grid-margin-y">
                  <div class="cell medium-6">
                      Manifest: {{ shipment.manifest_key }}
                  </div>
                  <div class="cell medium-6 text-sm-right">
                      Carrier: {{ shipment.carrier }}
                  </div>
                  <div class="cell medium-6">
                      Packages: {{ shipment.packages_count }}
                  </div>
                  <div class="cell medium-6 text-sm-right">
                      Arrival Date: {{ shipment.arrival_date }}
                  </div>
              </div>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat icon color="success" :to="{ name: 'shipments.detail', params: { id: shipment.id } }">
                  <v-icon>fa-file-o</v-icon>
              </v-btn>
              <v-btn flat icon color="accent" :to="{ name: 'shipments.edit', params: { id: shipment.id } }">
                  <v-icon>fa-pencil</v-icon>
              </v-btn>
          </v-card-actions>
      </v-card>
  </div> -->
  <div class="load-more-container" v-if="!loading">
                <v-btn color="primary" class="float-center" @click="loadMore" :loading="loadingMore" :disabled="!canLoadMore">
                    Load More
                </v-btn>
            </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    created() {
        this.getShipments();
    },

    data() {
        return {
            loading: false,
            loadingMore: false,
            shipments: [],
            currentPageIndex: 1,
            totalPages: 0,
            canLoadMore: false
        }
    },

    methods: {
        ...mapActions([
            'fetchShipmentsList'
        ]),

        getShipments() {
            this.loading = true;
            this.fetchShipmentsList(this.currentPageIndex)
            .then((response) => {
                this.loading = false;
                this.shipments = response.data;
                this.currentPageIndex = response.meta.current_page;
                this.totalPages = response.meta.last_page;
                this.canLoadMore = response.meta.current_page < response.meta.last_page ? true : false;
            })
        },

        loadMore() {
            let newIndex = this.currentPageIndex + 1;
            this.loadingMore = true;
            this.fetchShipmentsList(newIndex)
            .then((response) => {
                this.loadingMore = false;
                this.shipments.push(...response.data);
                this.currentPageIndex = response.meta.current_page;
                this.totalPages = response.meta.last_page;
                this.canLoadMore = response.meta.current_page < response.meta.last_page ? true : false;
            })
        },

        viewShipment(id) {
            this.$router.push( { name: 'shipments.detail', params: { id: id } } )
        },

        editShipment(id) {
            this.$router.push( { name: 'shipments.edit', params: { id: id } } )
        }
    }
  
}
</script>

<style lang="scss" scoped>
    .load-more-container {
        margin-top: 1rem;
        width: 100%;
    }
    table {
        width: 90%;
        border-collapse: collapse;
        background-color: #ffffff;
        margin: 0 auto;
        th {
            padding: .75rem;
        }
    }
    table tr:nth-child(even) {
        background-color: #ddd;
    }
    .shipment-row {
        // cursor: pointer;
        td {
            text-align: right;
            padding: .75rem;
            a {
                color: black;
            }
        }
    }
    .shipment-row:hover {
        background-color: #1976d2;
        color: white;
    }
</style>


