<template>
    <div class="grid-container fluid mt-3">
        <div class="grid-x">
            <div class="cell">
                <v-card>
                    <v-card-title>
                        <h1 class="display-2 grey--text">
                            My Account
                        </h1>
                    </v-card-title>
                    <v-card-text>
                        <div class="grid-x">
                            <div class="cell">
                                <UserProfile :user="user" :locations="locations" @update-user="updateProfile" v-if="!loadingUser"/>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
        <v-snackbar
            :timeout="timeout"
            color="success"
            v-model="success"
            >
            Your profile was updated successfully
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserProfile from '../../components/client/UserProfile.vue';

export default {
    created() {
        this.getUserData();
        this.getLocations();
    },

    components: {
        UserProfile
    },

    data() {
        return {
            success: false,
            timeout: 4000,
            user: Object,
            locations: [],
            loadingUser: false
        }
    },

    computed: {
        ...mapGetters([
            'authUser'
        ])
    },

    methods: {
        ...mapActions([
            'fetchUserProfile',
            'editUserProfile',
            'fetchLocations'
        ]),

        getUserData() {
            this.loadingUser = true;
            this.fetchUserProfile(this.authUser.id)
            .then((response) => {
                this.user = response.data;
                this.loadingUser = false;
            })
        },

        getLocations() {
            this.fetchLocations()
            .then((response) => {
                this.locations = response.data;
            })
        },

        updateProfile(data) {
            let payload = {
                user: this.authUser.id,
                data: data
            }

            this.editUserProfile(payload)
            .then((response) => {
                this.success = true;
            })
        }
    }
}
</script>

