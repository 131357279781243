<template>
    <div class="grid-container fluid mt-3">
        <v-card>
            <v-card-title>
                <h1 class="display-2 grey--text">
                    Edit Shipment #{{ shipment.id }}
                </h1>
            </v-card-title>
            <v-card-text>
                <div class="grid-x">
                    <div class="cell">
                        <ShipmentForm v-if="!loadingShipment && !loadingPackages" @shipment-edited="submit" :shipment="shipment" :packages="packages"/>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <!-- <ShipmentActionButtons /> -->
    </div>
</template>

<script>
import ShipmentForm from '../../components/shipments/ShipmentForm.vue';
import { mapActions } from 'vuex';

export default {
    components: {
        ShipmentForm
    },
    
    created() {
        this.getShipmentData();
        this.getGroupedPackages();
    },

    data() {
        return {
            shipment: Object,
            loadingShipment: false,
            loadingPackages: false,
            packages: []
        }
    },

    methods: {
        ...mapActions([
            'fetchGroupedPackages',
            'fetchShipment',
            'editShipment'
        ]),

        getGroupedPackages() {
            this.loadingPackages = true;
            this.fetchGroupedPackages(this.$route.params.id)
            .then((response) => {
                this.packages = response.data;
                this.loadingPackages = false;
            })
        },

        getShipmentData() {
            this.loadingShipment = true;
            this.fetchShipment(this.$route.params.id)
            .then((response) => {
                this.shipment = response.data;
                this.loadingShipment = false;
            })
        },

        submit(shipment) {
            let payload = {
                id: this.$route.params.id,
                data: shipment
            }

            this.editShipment(payload)
            .then((response) => {
                this.$router.push( { name: 'shipments.detail', params: { id: response.data.id } } )
            })
        }
    }
  
}
</script>

