<template>
    <v-app id="register">
        <div class="register-page-container">
      <div class="grid-x wrapper">
          <div class="cell medium-5 float-center form-wrapper" v-if="!success">
            <img src="../../assets/images/logo.png" alt="" class="float-center logo">
              <RegistrationForm :islands="islands" :locations="locations" @register-user="register"/>
          </div>
          <div class="cell medium-5 float-center form-wrapper" v-if="success">
            <v-alert color="success" icon="check_circle" value="true">
                Account creation successful. You may now login.
                <v-btn :to="{ name: 'login' }" color="primary">
                    Login
                </v-btn>
            </v-alert>
        </div>
      </div>
  </div>
    </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import RegistrationForm from '../../components/register/RegistrationForm.vue';

export default {
    created() {
        this.getLocations();
        this.getIslands();
    },

    components: {
        RegistrationForm
    },

    data() {
        return {
            islands: [],
            locations: [],
            success: false
        }
    },
    
    methods: {
        ...mapActions([
            'fetchLocations',
            'fetchIslands',
            'createUser'
        ]),

        getLocations() {
            this.fetchLocations()
            .then((response) => {
                this.locations = response.data;
            })
        },

        getIslands() {
            this.fetchIslands()
            .then((response) => {
                this.islands = response.data;
            })
        },

        register(data) {
            this.createUser(data)
            .then((response) => {
                this.success = true;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .register-page-container{
    background-color: $primary;
    background: url('../../assets/images/clouds-bg.gif') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
  }

  .logo{
    margin: 4rem auto 2rem auto;
  }
</style>

