import { authService } from '../../../services';

export default {
    login ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            authService.login(payload)
            .then((response) => {
                // call the SET_AUTH_USER mutation
                commit('SET_AUTH_USER', response.data);
                // http success, let the calling function know that the action worked
                resolve(response);
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })
    },

    logout ({ commit }) {
        commit('REMOVE_AUTH_USER');
    }
}