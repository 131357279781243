<template>
  <div class="shipment-action-buttons">
      <v-speed-dial
      large
      bottom
      right
      direction="top"
      transition="slide-y-reverse-transition"
      v-model="fab"
      fixed
    >
      <v-btn
        slot="activator"
        color="blue darken-2"
        dark
        fab
        hover
        v-model="fab"
      >
        <v-icon>fa-list</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
      <v-tooltip left>
          <v-btn
            fab
            dark
            color="primary"
            slot="activator"
            @click="goToCreate"
            >
            <v-icon>add</v-icon>
        </v-btn>
      <span>Create Shipment</span>
      </v-tooltip>
      <v-tooltip left v-if="onDetail">
          <v-btn
            fab
            dark
            color="accent"
            slot="activator"
            @click="goToEdit"
            >
            <v-icon>fa-pencil</v-icon>
        </v-btn>
      <span>Edit Shipment</span>
      </v-tooltip>
      <v-tooltip left>
          <v-btn
            fab
            dark
            color="info"
            slot="activator"
            @click="openNotesDialog"
            >
            <v-icon>note</v-icon>
        </v-btn>
      <span>Notes & Attachments</span>
      </v-tooltip>
      <v-tooltip left v-if="onDetail">
          <v-btn
            fab
            dark
            color="success"
            slot="activator"
            @click="sendNotifications"
            :disabled="notified || notificationSent"
            >
            <v-icon>email</v-icon>
        </v-btn>
      <span>Send Notifications</span>
      </v-tooltip>
      </v-speed-dial>
  </div>
</template>

<script>
export default {
    props: ['notificationSent', 'notified'],

    created() {
        this.onDetail = this.$route.name == 'shipments.detail' ? true : false;
        this.onEdit = this.$route.name == 'shipments.edit' ? true : false;
        this.onCreate = this.$route.name == 'shipments.create' ? true : false;
        this.onIndex = this.$route.name == 'shipments.index' ? true : false;
    },

    data() {
        return{
            fab: false,
            createBox: false,
            onDetail: Boolean,
            onEdit: Boolean,
            onCreate: Boolean,
            onIndex: Boolean,
        }
    },

    methods: {
        goToCreate() {
            this.$router.push( { name: 'shipments.create' } );
        },

        goToEdit() {
            this.$router.push( { name: 'shipments.edit', params: { id: this.$route.params.id } } )
        },

        sendNotifications() {
            this.$emit('send-notifications');
        },

        openNotesDialog() {
            this.$emit('open-notes');
        }
    }
}
</script>

