export default {
    customers: ( state ) => {
        return state.customers;
    },

    cartVisible: ( state ) => {
        return state.cartVisible;
    },

    cartItems: ( state ) => {
        return state.cartItems;
    },

    cartTotal: ( state ) => {
        return +state.cartTotal.toFixed(2);
    }
}