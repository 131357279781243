<template>
    <v-card>
        <v-card-title>
            <span class="headline grey--text">Cart</span>
            <v-spacer></v-spacer>
            <v-icon @click="closeCart">fa-times</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <div class="cart-contents" v-if="invoices.length > 0">
                <div class="grid-x">
                    <div class="cell cart-invoice" v-for="(invoice, index) in invoices" :key="index">
                        <div class="grid-x">
                            <div class="cell medium-1">
                                <v-icon small color="error" @click="removeFromCart(invoice.id)">
                                    fa-minus-circle
                                </v-icon>
                            </div>
                            <div class="cell medium-8">
                                Invoice #{{ invoice.pseudo_id }}
                            </div>
                            <div class="cell medium-3 text-sm-right">
                                ${{ invoice.value }}
                            </div>
                        </div>
                    </div>                    
                </div>
                <v-divider></v-divider>
                <div class="grid-x total-row">
                    <div class="cell medium-9 label">
                        Total:
                    </div>
                    <div class="cell medium-3 text-sm-right amount">
                        ${{ total }}
                    </div>
                </div>
            </div>
            <p v-if="invoices.length < 1">Your cart is currently empty.</p>
        </v-card-text>
        <v-card-actions v-if="invoices.length > 0">
            <v-spacer></v-spacer>
            <v-btn color="warning" flat @click="goToCheckout">
                Checkout
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: ['invoices', 'total'],
    data() {
        return {

        }
    },

    methods: {
        closeCart() {
            this.$emit('close-cart');
        },

        removeFromCart(id) {
            this.$emit('remove-invoice', id);
        },

        goToCheckout() {
            this.closeCart();
            this.$router.push( { name: 'checkout'} )
        }
    }
}
</script>

<style lang="scss" scoped>
    .fa{
        cursor: pointer;
    }
    .fa-minus-circle{
        font-size: .9rem !important;
    }
    .total-row{
        margin-top: 12px;
        .label, .amount{
            font-size: 1.2rem;
            font-size: $success;
        }
    }
    .cart-invoice{
        margin-top: .25rem;
        margin-bottom: .25rem
    }
</style>


