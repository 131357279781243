<template>
  <div class="grid-container fluid mt-3">
    <h1 class="display-2 grey--text">Deliveries</h1>
    <div class="grid-x grid-margin-y">
      <div class="cell small-12 mt-5">
        <table class="w-full border-collapse">
          <thead>
            <tr>
              <th>Delivered</th>
              <th>Customer</th>
              <th>Directions</th>
              <th>Time</th>
              <th>Weight</th>
              <th>Orders</th>
              <th>Total</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="delivery in deliveries" :key="delivery.id" :class="{flagged: delivery.flagged}">
              <td>
                <input type="checkbox" :checked="delivery.delivered" :disabled="delivery.delivered" @change="markAsDelivered(delivery.id)"/>
              </td>
              <td>
                {{ delivery.user.first_name }} {{ delivery.user.last_name }}
              </td>
              <td style="width: 60%">
                {{ delivery.directions }}
              </td>
              <td>
                {{ delivery.time }}
              </td>
              <td>{{ delivery.weight }}lb(s)</td>
              <td>
                {{ delivery.orders }}
              </td>
              <td>${{ delivery.total }}</td>
              <td>
                {{ delivery.date }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  created() {
    this.getDeliveries();
  },

  data: () => {
    return {
      deliveries: [],
    };
  },

  methods: {
    ...mapActions(["fetchDeliveries", "markDelivered"]),

    getDeliveries() {
      this.fetchDeliveries("").then((res) => {
        this.deliveries = res;
      });
    },

    markAsDelivered(deliveryId) {
      this.markDelivered(deliveryId).then(() => {
        this.getDeliveries();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table thead tr th,
table tbody tr td {
  padding: 5px;
}

table tbody tr td,
table thead tr th {
  border: 1px solid #bbb;
}

.flagged {
  background-color: #FFCDD2;
  font-weight: bold;
}
</style>


