import { http } from "../plugins/http.js";

export const cartService = {
	getUserCart(id) {
		return http({
			method: "get",
			url: `/users/${id}/cart`,
		});
	},
	getDeliveryCost(user) {
		return http({
			method: "get",
			url: `/delivery/estimate/${user}`,
		});
	},
};
