import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import AuthModule from './modules/AuthModule/AuthModule';
import OrdersModule from './modules/OrdersModule/OrdersModule';
import InvoiceModule from './modules/InvoiceModule/InvoiceModule';
import UsersModule from './modules/UsersModule/UsersModule';
import TaxesModule from './modules/TaxesModule/TaxesModule';
import CheckpointsModule from './modules/CheckpointsModule/CheckpointsModule';
import ShipmentsModule from './modules/ShipmentsModule/ShipmentsModule';
import ReportsModule from './modules/ReportsModule/ReportsModule';
import OfficesModule from './modules/OfficesModule/OfficesModule';

// Globals
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {},
    mutations,
    actions,
    getters,    
    modules: {
        AuthModule,
        OrdersModule,
        InvoiceModule,
        UsersModule,
        TaxesModule,
        CheckpointsModule,
        ShipmentsModule,
        ReportsModule,
        OfficesModule
    }
});
