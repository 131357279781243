import { invoiceService } from "../../../services";

export default {
	fetchInvoice({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.fetchInvoiceData(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	generateInvoice({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.generateInvoice(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	updateInvoice({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.updateInvoice(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	approveOrderInvoice({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.approveInvoice(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	unapproveOrderInvoice({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.unapproveInvoice(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	updateInvoiceFees({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.updateFees(payload.fees, payload.id).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	makeInvoicePayment({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.makeInvoicePayment(payload.id, payload.type).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	addInvoiceToCart({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.addInvoiceToCart(payload.invoice, payload.user).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	removeInvoiceFromCart({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.removeInvoiceFromCart(payload.invoice, payload.user).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	checkout({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.makePayment(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	postCCPayment({ commit }, payload) {
		return new Promise((resolve, reject) => {
			invoiceService.postCCPayment(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchInjuryData({ commit }) {
		return new Promise((resolve, reject) => {
			invoiceService.fetchInjuryData().then(
				(response) => {
					resolve(response);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
};
