<template>
  <v-form>
    <div class="grid-x grid-margin-x cart-items">
      <div class="cell" v-for="(invoice, index) in cartItems" :key="index">
        <div class="grid-x">
          <div class="cell medium-6">
            <v-icon color="error" @click="removeFromCart(invoice.id)"
              >fa-minus-circle</v-icon
            >
            <v-chip label color="indigo" text-color="white" large
              >Invoice #{{ invoice.pseudo_id }}</v-chip
            >
          </div>
          <div class="cell medium-6 text-sm-right">${{ invoice.value }}</div>
        </div>
      </div>
      <div class="cell mt-4">
        <div class="grid-x">
          <div class="cell">
            <label class="text-lg">
              <input
                type="checkbox"
                name="delivery"
                v-model="payForm.wantsDelivery"
                @change="enableDelivery"
              />
              Would you like to have these orders delivered?
            </label>
          </div>
          <div class="cell mt-2" v-if="payForm.wantsDelivery">
            <div class="cell medium-6">
              <v-menu
                :close-on-content-click="false"
                v-model="menu"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="payForm.date"
                  label="Which date would you like your items delivered?"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="payForm.date"></v-date-picker>
              </v-menu>
            </div>
            <div class="cell medium-6">
              <v-select
                label="What timeframe is best for your delivery?"
                required
                :items="[
                  '10:00am - 12:00pm',
                  '1:00pm - 3:00pm',
                  '4:00pm - 6:00pm',
                ]"
                v-model="payForm.time"
              ></v-select>
            </div>
            <div class="cell">
              <v-text-field
                name="directions"
                label="Directions"
                required
                v-model="payForm.directions"
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="grid-x cart-total">
      <div class="cell medium-6 delivery-label" v-if="payForm.deliveryCost">
        Delivery
      </div>
      <div
        class="cell medium-6 text-sm-right delivery-value"
        v-if="payForm.deliveryCost"
      >
        ${{ payForm.deliveryCost }}
      </div>
      <div class="cell medium-6 total-label">Total</div>
      <div class="cell medium-6 text-sm-right total-value">
        ${{ (cartTotal + payForm.deliveryCost).toFixed(2) }}
      </div>
    </div>
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 memo">
        <p class="grey--text">
          There will be a 4% Convenience Fee added for all online transactions.
        </p>
      </div>
      <div class="cell medium-6">
        <v-text-field
          label="Cardholder's Name"
          name="card_name"
          required
          v-model="payForm.card_name"
        ></v-text-field>
      </div>
      <div class="cell medium-6">
        <v-text-field
          label="Credit Card Number"
          name="card_number"
          required
          type="tel"
          v-model="payForm.card_number"
        ></v-text-field>
      </div>
      <div class="cell medium-6">
        <v-text-field
          label="Expiration Date"
          placeholder="MM/YYYY"
          name="card_exp"
          required
          v-model="payForm.card_exp"
        ></v-text-field>
      </div>
      <div class="cell medium-6">
        <v-text-field
          label="CVV"
          name="cvv"
          type="tel"
          required
          v-model="payForm.card_cvv"
        ></v-text-field>
      </div>
    </div>
    <div class="grid-x">
      <!-- <div class="cell">

      </div>-->
      <div class="cell">
        <v-btn
          color="success"
          @click="pay"
          :disabled="$v.payForm.$invalid || processing"
          >Make Payment</v-btn
        >
      </div>
    </div>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";

export default {
  props: ["processing"],

  data() {
    return {
      menu: false,
      payForm: {
        card_name: "",
        card_number: "",
        card_exp: "",
        card_cvv: "",
        time: "",
        date: "",
        directions: "",
        deliveryCost: 0,
        wantsDelivery: false,
      },
    };
  },

  computed: {
    ...mapGetters(["cartItems", "cartTotal", "authUser"]),
    delivery() {
      return this.payForm.wantsDelivery;
    },
  },

  validations: {
    payForm: {
      card_name: {
        required,
      },
      card_number: {
        required,
        numeric,
      },
      card_exp: {
        required,
      },
      card_cvv: {
        required,
        numeric,
      },
      time: {
        required: requiredIf(function () {
          return this.delivery;
        }),
      },
      date: {
        required: requiredIf(function () {
          return this.delivery;
        }),
      },
      directions: {
        required: requiredIf(function () {
          return this.delivery;
        }),
      },
    },
  },

  methods: {
    ...mapActions([
      "removeInvoiceFromCart",
      "fetchUserCart",
      "getDeliveryCost",
    ]),
    removeFromCart(invoice) {
      let payload = {
        invoice: invoice,
        user: this.authUser.id,
      };

      this.removeInvoiceFromCart(payload).then((response) => {
        this.fetchUserCart(this.authUser.id);
      });
    },
    pay() {
      this.$emit("make-payment", this.payForm);
    },
    enableDelivery() {
      if (this.payForm.wantsDelivery) {
        this.getDeliveryCost(this.authUser.id).then((res) => {
          this.payForm.deliveryCost = res;
        });
      } else {
        this.payForm.deliveryCost = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-items {
  margin-bottom: 1rem;
}
.fa {
  cursor: pointer;
}
p {
  margin-bottom: 0px;
}
.cart-total {
  margin-bottom: 1.5rem;
}
.total-label,
.total-value {
  font-size: 1.5rem;
  color: $primary;
}
.delivery-label,
.delivery-value {
  font-size: 1.5rem;
  color: $success;
}
.memo {
  margin-bottom: 1rem;
}
</style>
