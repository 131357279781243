import { http } from '../plugins/http.js'

export const officeService = {
    fetchOffices() {
        return http({
            method: 'get',
            url: `/offices`,
        });
    },

    createOffice(tax) {
        return http({
            method: 'post',
            url: `/offices`,
            data: {
                data: office
            }
        });
    },

    updateOffice(office) {
        return http({
            method: 'put',
            url: `/offices/${office.id}`,
            data: {
                data: office
            }
        });
    },

    deleteOffice(office) {
        return http({
            method: 'delete',
            url: `/offices/${office}`
        });
    }
}