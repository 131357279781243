<template>
      <v-speed-dial
      fixed
      large
      bottom
      right
      direction="top"
      transition="slide-y-reverse-transition"
      v-model="fab"
    >
      <v-btn
        slot="activator"
        color="blue darken-2"
        dark
        fab
        hover
        v-model="fab"
      >
        <v-icon>account_circle</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
      <v-tooltip left>
          <v-btn
            fab
            dark
            color="primary"
            slot="activator"
            @click="createOrder"
            >
            <v-icon>add</v-icon>
        </v-btn>
      <span>New Order</span>
      </v-tooltip>
      <v-tooltip left>
          <v-btn
            fab
            dark
            color="accent"
            slot="activator"
            @click="scanPackage"
            >
            <v-icon>line_style</v-icon>
        </v-btn>
      <span>Scan Package</span>
      </v-tooltip>
      <v-tooltip left>
          <v-btn
            fab
            dark
            color="secondary"
            slot="activator"
            @click="ccPayment"
            >
            <v-icon>fa-cc-visa</v-icon>
        </v-btn>
      <span>Credit Card Payments</span>
      </v-tooltip>
    </v-speed-dial>
</template>

<script>
export default {
  data() {
      return {
          fab: false
      }
  },
  methods: {
      createOrder() {
          this.$router.push( { name: 'order.create' } )
      },

      scanPackage() {
        this.$router.push( { name: 'packages.scan' } )
      },

      ccPayment() {
        this.$router.push( { name: 'credits' } )
      }
  }
}
</script>

