import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    state: {
        customers: [],
        cartItems: [],
        cartVisible: false,
        cartTotal: 0
    },
    mutations,
    actions,
    getters
}