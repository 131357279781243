<template>
  <div class="grid-container fluid mt-3">
    <div class="grid-x" v-if="authorized">
      <div class="cell">
        <v-card>
          <v-card-title>
            <h1 class="display-2 grey--text">Activity Logs</h1>
          </v-card-title>
          <v-card-text>
            <div class="grid-x grid-margin-x">
              <div class="cell medium-3">
                <v-text-field
                  label="From"
                  type="date"
                  v-model="fromDate"
                ></v-text-field>
              </div>
              <div class="cell medium-3">
                <v-text-field
                  label="To"
                  type="date"
                  v-model="toDate"
                ></v-text-field>
              </div>
              <div class="cell">
                <v-select
                  label="Worker"
                  autocomplete
                  :loading="loadingWorkers"
                  debounce-search="500"
                  :items="workers"
                  cache-items
                  item-text="label"
                  item-value="id"
                  :search-input.sync="searchWorkerString"
                  v-model="workerId"
                ></v-select>
              </div>
              <div class="cell medium-2">
                <v-btn
                  color="primary"
                  :disabled="!fromDate || !toDate"
                  @click="getLogs"
                >
                  Search
                </v-btn>
                <v-btn color="error" @click="clearFilters">
                  Clear Filters
                </v-btn>
              </div>
            </div>
            <div class="logs-area" v-if="!loading">
              <v-data-table
                :headers="headers"
                :items="logs"
                hide-actions
                class="elevation-1"
                v-if="logs.length > 0"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.user }}</td>
                  <td>{{ props.item.activity }}</td>
                  <td>{{ props.item.created_at }}</td>
                </template>
              </v-data-table>
              <p class="text-xs-center" v-if="logs.length < 1">
                No activities have been logged for this time period.
              </p>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  created() {
    // this.getLogs()
    this.getWorkers();
  },

  data() {
    return {
      logs: [],
      workers: [],
      loadingWorkers: false,
      searchWorkerString: "",
      loading: false,
      toDate: null,
      fromDate: null,
      workerId: null,
      headers: [
        {
          text: "ID",
          value: "id",
          align: "left",
          sortable: false,
        },
        {
          text: "Staff Member",
          value: "user",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          value: "activty",
          align: "left",
          sortable: false,
        },
        {
          text: "When",
          value: "created_at",
          align: "left",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["authUser"]),
    authorized() {
      return this.authUser.id == 5387 || this.authUser.id == 1243
        ? true
        : false;
    },
  },

  methods: {
    ...mapActions(["fetchLogs", "fetchWorkers"]),

    getLogs() {
      this.loading = true;
      let payload = {
        toDate: this.toDate,
        fromDate: this.fromDate,
        workerId: this.workerId,
      };

      this.fetchLogs(payload).then((response) => {
        this.logs = response.data;
        this.loading = false;
      });
    },
    getWorkers() {
      this.fetchWorkers().then((res) => {
        this.workers = res.data;
      });
    },
    clearFilters() {
      this.toDate = null;
      this.fromDate = null;
      this.searchWorkerString = "";
      this.workerId = null;
    },
  },
};
</script>

