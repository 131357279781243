<template>
    <table>
        <thead>
            <tr>
                <th>Office #</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(office, i) in offices" :key="i" class="office-row">
                <td>
                    {{ office['id'] }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: ['offices'],
    methods: {
        shortDescription (description) {
            let length = 30
            let ending = '...'

            if (description.length > length) {
                return description.substring(0, length - ending.length) + ending;
            } else {
                return description;
            } 
        }
    }
}
</script>

<style lang="scss" scoped>
    table {
        border-collapse: collapse;
        background-color: #ffffff;
        margin: 0 auto;
        th {
            padding: .75rem;
        }
    }
    table tr:nth-child(even) {
        background-color: #ddd;
    }
    .office-row {
        // cursor: pointer;
        td {
            text-align: right;
            padding: .75rem;
            a {
                color: black;
            }
        }
    }
    .office-row:hover {
        background-color: #1976d2;
        color: white;
    }
</style>


