<template>
  <div class="grid-container fluid mt-3">
      <div class="grid-x grid-margin-y">
          <div class="cell">
              <h1 class="display-2 grey--text">
                  Shipments
              </h1>
          </div>
          <div class="cell">
              <ShipmentList />
          </div>
      </div>
      <ShipmentActionButtons />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ShipmentList from '../../components/shipments/ShipmentList.vue';
import ShipmentActionButtons from '../../components/shipments/ShipmentActionButtons.vue';

export default {
    components: {
        ShipmentList,
        ShipmentActionButtons
    },

    data() {
        return {

        }
    },

    methods: {

    }
  
}
</script>

