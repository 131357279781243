<template>
    <v-app id="register">
        <div class="page-wrapper">
      <div class="grid-x wrapper">
          <div class="cell float-center text-sm-center">
              <img src="../../assets/images/logo.png" alt="">
          </div>
          <div class="cell medium-5 float-center form-wrapper" v-if="!submitted">
              <v-card>
                  <v-card-title>
                      <h1 class="headline">Reset Password</h1>
                  </v-card-title>
                  <v-card-text>
                      <v-form>
                  <div class="grid-x grid-margin-y grid-margin-x">
                      <div class="cell medium-6">
                          <v-text-field
                            required
                            label="Password"
                            name="password"
                            type="password"
                            v-model="form.password">
                            </v-text-field>
                      </div>
                      <div class="cell medium-6">
                          <v-text-field
                            required
                            label="Confirm Password"
                            name="passwordConfirm"
                            type="password"
                            v-model="form.passwordConfirm">
                            </v-text-field>
                      </div>
                  </div>
              </v-form>
                  </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn flat color="success" @click="submit" :disabled="$v.form.$invalid">
                          Submit
                      </v-btn>
                  </v-card-actions>
              </v-card>
          </div>
          <div class="cell medium-5 float-center form-wrapper" v-if="submitted">
            <v-alert color="success" icon="check_circle" value="true">
                Your password has been successfully reset.
                <v-btn :to="{ name: 'login' }" color="primary">
                    Login
                </v-btn>
            </v-alert>
        </div>
      </div>
  </div>
    </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators'; 

export default {
    created() {
        this.token = this.$route.query.token;
    },

    data() {
        return {
            submitted: false,
            token: '',
            form: {
                password: '',
                passwordConfirm: ''
            }
        }
    },

    validations: {
        form: {
            password: {
                required
            },
            passwordConfirm: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    
    methods: {
        ...mapActions([
            'resetPassword'
        ]),

        submit(data) {
            let payload = {
                password: this.form.password,
                token: this.token
            }

            this.resetPassword(payload)
            .then((response) => {
                this.submitted = true;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .form-wrapper{
        margin-top: 2rem;
    }
    .wrapper{
        padding-top: 5rem;
    }
    .page-wrapper{
        background-color: $accent;
        height: 100vh;
    }
</style>

