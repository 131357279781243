import { http } from '../plugins/http.js'

export const taxService = {
    fetchTaxes() {
        return http({
            method: 'get',
            url: `/taxes`,
        });
    },

    createTax(tax) {
        return http({
            method: 'post',
            url: `/taxes`,
            data: {
                data: tax
            }
        });
    },

    updateTax(tax) {
        return http({
            method: 'put',
            url: `/taxes/${tax.id}`,
            data: {
                data: tax
            }
        });
    },

    deleteTax(tax) {
        return http({
            method: 'delete',
            url: `/taxes/${tax}`
        });
    }
}