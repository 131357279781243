<template>
    <div class="grid-x credit">
        <div class="cell medium-6 float-center">
            <v-card v-if="!loadingCredit">
                <v-card-text>
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell medium-6">
                            <img src="../../assets/images/logo.png" alt="">
                        </div>
                        <div class="cell medium-6 text-right headline">
                            Credit Cart Receipt: #{{ credit.id }}
                        </div>
                        <div class="cell list">
                            <ul>
                                <li>Name: {{ credit.name }}</li>
                                <li>Description: {{ credit.description }}</li>
                                <li>Vendor: {{ credit.vendor }}</li>
                            </ul>
                        </div>
                        <div class="cell costs">
                            <div class="grid-x">
                                <div class="cell medium-6">
                                    Cost:
                                </div>
                                <div class="cell medium-6 text-xs-right">
                                    ${{ credit.value }}
                                </div>
                            </div>
                            <div class="grid-x">
                                <div class="cell medium-6">
                                    Processing Fee:
                                </div>
                                <div class="cell medium-6 text-xs-right">
                                    ${{ credit.processingFee }}
                                </div>
                            </div>
                            <div class="grid-x">
                                <div class="cell medium-6 total">
                                    Total:
                                </div>
                                <div class="cell medium-6 text-xs-right total">
                                    ${{ credit.total }}
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    created() {
        this.getCredit();
    },

    data() {
        return {
            loadingCredit: false,
            credit: null
        }
    },

    methods: {
        ...mapActions([
            'fetchCreditById'
        ]),

        getCredit() {
            this.loadingCredit = true;
            this.fetchCreditById(this.$route.params.id)
            .then((response) => {
                this.credit = response;
                this.loadingCredit = false;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.credit {
    margin-top: 4rem;
}
    .list ul {
        list-style: none;
        padding-left: 0px;
        margin-left: 0px;
    }
    .list ul li {
        font-size: 1.2rem;
    }
    .costs .cell {
        font-size: 1.3rem;
    }
    .total {
        color: red;
        font-weight: bold;
    }
</style>


