<template>
    <div class="page-wrapper eod">
        <div class="grid-x">
            <div class="cell">
                <v-card>
                    <v-card-text>
                        <h1 class="display-2 grey--text">
                            End Of Day Reports
                        </h1>
                        <div class="grid-x">
                            <div class="cell">
                                <v-data-table
      v-bind:headers="headers"
      :items="reports"
      hide-actions
      class="elevation-1"
    >
    <template slot="items" slot-scope="props">
      <td><v-btn flat color="success" :to=" { name: 'eod.show', params: { id: props.item.id} } ">{{ props.item.id }}</v-btn></td>
      <td>{{ props.item.date }}</td>
    <td>${{ props.item.beginning }}</td>
          <td>${{ props.item.ending }}</td>
      <td>{{ props.item.notes || 'N/A' }}</td>
    </template>
  </v-data-table>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
        <v-tooltip left>
            <v-btn fixed dark fab bottom right color="pink" slot="activator" @click="createModal = true">
                <v-icon>add</v-icon>
            </v-btn>
            <span>Create EOD Report</span>
        </v-tooltip>
        <v-dialog persistent max-width="600px" v-model="createModal">
            <v-card>
                <v-card-title>
                    Create EOD Report
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <div class="grid-x grid-padding-x">
                            <div class="cell medium-6">
                                <v-text-field
                                label="Beginning Balance"
                                type="number"
                                required
                                @input="$v.form.beginning.$touch()"
                                @blur="$v.form.beginning.$touch()"
                                :error-messages="beginningErrors"
                                v-model="form.beginning"></v-text-field>
                            </div>
                            <div class="cell medium-6">
                                <v-text-field
                                label="Ending Balance"
                                type="number"
                                v-model="form.ending"></v-text-field>
                            </div>
                            <div class="cell medium-6">
                                <v-text-field
                                label="Date"
                                type="date"
                                @input="$v.form.date.formatted.$touch()"
                                @blur="$v.form.date.formatted.$touch()"
                                :error-messages="dateErrors"
                                v-model="form.date.formatted"></v-text-field>
                            </div>
                            <div class="cell small-12">
                                <v-text-field
                                textarea
                                label="Notes"
                                v-model="form.notes"></v-text-field>
                            </div>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat color="error" @click="createModal = false">
                        Cancel
                    </v-btn>
                    <v-btn flat color="success" @click="submitEODForm" :disabled="$v.form.$invalid">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
    created() {
        this.getReports();
    },

    data() {
        return {
            createModal: false,
            reports: [],
            form: {
                beginning: '',
                ending: '',
                date: {
                    formatted: ''
                },
                notes: ''
            },
            headers: [
                {
                    text: 'ID #',
                    align: 'left',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: 'Date',
                    align: 'left',
                    sortable: false,
                    value: 'date'
                },
                {
                    text: 'Beginning Balance',
                    align: 'left',
                    sortable: false,
                    value: 'beginning'
                },
                {
                    text: 'Ending Balance',
                    align: 'left',
                    sortable: false,
                    value: 'ending'
                },
                {
                    text: 'Notes',
                    align: 'left',
                    sortable: false,
                    value: 'notes'
                }
            ]
        }
    },

    computed: {
        beginningErrors () {
            const errors = []
            if (!this.$v.form.beginning.$dirty) return errors
            !this.$v.form.beginning.required && errors.push('Beginning Balance is required')
            return errors
        },
        dateErrors () {
            const errors = []
            if (!this.$v.form.date.formatted.$dirty) return errors
            !this.$v.form.date.formatted.required && errors.push('Date is required')
            return errors
        }
    },

    validations: {
        form: {
            beginning: {
                required
            },
            date: {
                formatted: {
                    required
                }
            }
        }
    },

    methods: {
        ...mapActions([
            'fetchEODReports',
            'createEOD'
        ]),

        getReports() {
            this.fetchEODReports()
            .then((response) => {
                this.reports = response;
            })
        },

        submitEODForm() {
            this.createEOD(this.form)
            .then((response) => {
                this.createModal = false;
                this.$router.push( { name: 'eod.show', params: { id: response.id }} );
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

