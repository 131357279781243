import { http } from "../plugins/http.js";

export const orderService = {
  fetchOrdersGridItems(payload) {
    return http({
      method: "get",
      url: `/orders/paginated`,
      params: {
        page: payload.page,
        method: payload.method,
        search: payload.query,
        dates: payload.dates,
        location: payload.location,
        checkpoint: payload.checkpoint,
      },
    });
  },

  fetchPackagesGridItems(payload) {
    return http({
      method: "get",
      url: `/packages/paginated`,
      params: {
        page: payload.page,
        method: payload.method,
        search: payload.query,
        dates: payload.dates,
        checkpoint: payload.checkpoint,
      },
    });
  },

  fetchAlertsStats() {
    return http({
      method: "get",
      url: `/alerts/stats`,
    });
  },

  fetchOrderDetails(id) {
    return http({
      method: "get",
      url: `/orders/${id}`,
    });
  },

  fetchDeliveries(search) {
    return http({
      method: "get",
      url: `/deliveries?search=${search}`,
    });
  },

  markDelivered(deliveryId) {
    return http({
      method: "post",
      url: `/deliveries/${deliveryId}/delivered`,
    });
  },

  fetchPackageLabel(id) {
    return http({
      method: "get",
      url: `/packages/${id}/label`,
    });
  },

  fetchPickups() {
    return http({
      method: "get",
      url: `/pickups`,
    });
  },

  createOrder(workerID, order) {
    return http({
      method: "post",
      url: `/orders`,
      data: {
        workerID: workerID,
        data: order,
      },
    });
  },
  generateManualInvoice(payload) {
    return http({
      method: "post",
      url: `/orders/${payload.orderId}/create-manual`,
      data: payload.data,
    });
  },
  approveForSale(orderId) {
    return http({
      method: "post",
      url: `/orders/${orderId}/approve-for-sale`,
    });
  },
  editOrder(orderID, order) {
    return http({
      method: "put",
      url: `/orders/${orderID}`,
      data: {
        orderID: orderID,
        data: order,
      },
    });
  },

  softDeleteOrder(orderID) {
    return http({
      method: "delete",
      url: `/orders/${orderID}`,
    });
  },

  fetchCredits(orderID, order) {
    return http({
      method: "get",
      url: `/credits`,
    });
  },

  fetchCreditById(credit) {
    return http({
      method: "get",
      url: `/credits/${credit}`,
    });
  },

  makeCCPayment(payment) {
    return http({
      method: "post",
      url: `/credits`,
      data: {
        data: payment,
      },
    });
  },

  deleteInvoiceUpload(path) {
    return http({
      method: "put",
      url: `/uploads`,
      data: {
        file: path,
      },
    });
  },

  deleteImageUpload(image) {
    return http({
      method: "delete",
      url: `/order-images/${image}`,
    });
  },

  fetchEditedOrdersReport(date) {
    return http({
      method: "get",
      url: `/reports/orders/edited?date=${date}`,
    });
  },

  getRetailOrders(payload) {
    return http({
      method: "get",
      url: `/orders/retail`,
      params: {
        page: payload.page,
        category: payload.category,
      },
    });
  },
  openOrder(order) {
    return http({
      method: "post",
      url: `/orders/${order}/open`,
    });
  },
  closeOrder(order) {
    return http({
      method: "post",
      url: `/orders/${order}/close`,
    });
  },
  createPackageIn(data) {
    return http({
      method: "post",
      url: `/package-ins`,
      data: {
        packages: data.packages,
        notes: data.notes,
      },
    });
  },
  fetchPackageIns() {
    return http({
      method: "get",
      url: `/package-ins`,
    });
  },
  deletePackageIns(id) {
    return http({
      method: "delete",
      url: `/package-ins/${id}`,
    });
  },
  addToSheet(id) {
    return http({
      method: "post",
      url: `/orders/${id}/add-to-sheet`,
    });
  },
  checkHeldPackage(id) {
    return http({
      method: "post",
      url: `/held-packages/${id}/checked`,
    });
  },
};
