<template>
  <div class="grid-x">
    <div class="cell">
      <v-card>
        <v-card-text>
          <h1 class="display-2 grey--text">Client Order Report</h1>
          <div class="filters">
            <div class="filter">
              <input type="date" v-model="filters.start_date" />
            </div>
            <div class="filter">
              <input type="date" v-model="filters.end_date" />
            </div>
            <div>
              <v-btn primary @click="getData()">Search</v-btn>
            </div>
          </div>
          <div class="stats" v-if="meta">
            <p>Total Clients: {{ meta.total }}</p>
            <p>Total Orders: {{ meta.orders_count }}</p>
            <ul>
              <li>Priority: {{ meta.n_count }}</li>
              <li>Standard: {{ meta.s_count }}</li>
              <li>Consolidated: {{ meta.c_count }}</li>
              <li>Special: {{ meta.x_count }}</li>
              <li>Business Plane: {{ meta.bp_count }}</li>
              <li>Business Boat: {{ meta.bb_count }}</li>
            </ul>
          </div>
          <table class v-if="results.length > 0">
            <thead>
              <tr>
                <th>Client ID</th>
                <th>Client Name</th>
                <th>Email</th>
                <th># of Orders</th>
                <th>Last Shipped Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(result, i) in results" :key="i">
                <td>{{ result.id }}</td>
                <td>{{ result.name }}</td>
                <td>{{ result.email }}</td>
                <td>
                  <a
                    @click="showRelatedOrders(result.related_orders)"
                    class="order-link"
                    >{{ result.related_orders_count }}</a
                  >
                </td>
                <td>{{ result.last_shipped_date }}</td>
              </tr>
            </tbody>
          </table>
          <v-dialog max-width="400" v-model="showRelated" v-if="meta">
            <v-card>
              <v-card-text>
                <ul>
                  <li v-for="(result, i) in related_orders">
                    <router-link
                      :to="{ name: 'order.detail', params: { id: result } }"
                      target="_blank"
                      >{{ result }}</router-link
                    >
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-dialog>
          <div class="pagination-dropdown" v-if="meta">
            <select
              v-model="current_page"
              class="pagination"
              @change="changePage()"
            >
              <option
                v-for="(page, i) in meta.last_page"
                :key="i"
                :value="page"
              >
                Page: {{ page }}
              </option>
            </select>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => {
    return {
      results: [],
      meta: null,
      current_page: 1,
      showRelated: false,
      related_orders: [],
      filters: {
        start_date: "2019-01-01",
        end_date: "2019-02-28",
      },
    };
  },
  methods: {
    ...mapActions(["fetchClientOrderReport"]),
    getData() {
      let payload = {
        filters: this.filters,
        page: this.current_page,
      };

      this.fetchClientOrderReport(payload).then((res) => {
        this.results = res.data;
        this.meta = res.meta;
        this.current_page = res.meta.current_page;
      });
    },
    changePage() {
      this.getData();
    },
    showRelatedOrders(orders) {
      this.related_orders = orders;
      this.showRelated = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-link {
  color: blue;
}
.pagination {
  padding: 10px;
  border: 1px solid #000000;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.filters {
  margin-top: 16px;
  margin-bottom: 16px;
}
table {
  border-collapse: collapse;
  background-color: #ffffff;
  margin: 0 auto;
  th {
    padding: 0.75rem;
  }
}
table tr:nth-child(even) {
  background-color: #ddd;
}
tbody tr {
  // cursor: pointer;
  td {
    text-align: right;
    padding: 0.75rem;
    a {
      color: black;
    }
  }
}
.filter input {
  padding: 4px;
  border: 1px solid #000000;
  margin-bottom: 6px;
}
</style>
