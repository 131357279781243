import { http } from "../plugins/http.js";

export const userService = {
	fetchCustomers() {
		return http({
			method: "get",
			url: `/customers`,
		});
	},

	fetchCustomersPaginated(payload) {
		return http({
			method: "get",
			url: `/customers/paginated?page=${payload.page}&key=${payload.key}`,
		});
	},

	fetchWorkers() {
		return http({
			method: "get",
			url: "/workers",
		});
	},

	createUser(data) {
		return http({
			method: "post",
			url: "/users",
			data: {
				data,
			},
		});
	},

	createWorker(data) {
		return http({
			method: "post",
			url: "/workers",
			data: {
				data,
			},
		});
	},

	fetchUserCart(id) {
		return http({
			method: "get",
			url: `/users/${id}/cart`,
		});
	},

	fetchUserInvoices(user) {
		return http({
			method: "get",
			url: `/users/${user}/invoices`,
		});
	},

	fetchUserProfile(user) {
		return http({
			method: "get",
			url: `/customers/${user}`,
		});
	},

	editUserProfile(user, data) {
		return http({
			method: "put",
			url: `/users/${user}`,
			data: {
				data: data,
			},
		});
	},

	fetchUserOrders(user) {
		return http({
			method: "get",
			url: `/users/${user}/orders`,
		});
	},

	fetchUserOrdersList(user) {
		return http({
			method: "get",
			url: `/users/${user}/orders/list`,
		});
	},

	sendPasswordResetLink(email) {
		return http({
			method: "get",
			url: `/forgot/password?email=${email}`,
		});
	},

	resetPassword(password, token) {
		return http({
			method: "post",
			url: `/reset/password`,
			data: {
				password: password,
			},
			params: {
				token: token,
			},
		});
	},

	flagUser(userId) {
		return http({
			method: "post",
			url: `/users/${userId}/flag`,
		});
	},
};
