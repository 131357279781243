<template>
  <div class="main-wrapper">
    <v-data-table
      :headers="headers"
      :items="packages"
      hide-actions
      item-key="name"
      class="elevation-1"
    >
      <template slot="headers" slot-scope="props">
        <tr>
          <th v-for="header in props.headers" :key="header.text">
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <tr>
          <td class="text-xs-center">
            <v-checkbox
              v-model="props.item.checked"
              :disabled="props.item.checked"
              @change="sendPackageNotification(props.item.id)"
            ></v-checkbox>
          </td>
          <td class="text-xs-center">{{ props.item.client_fullname }}</td>
          <td class="text-xs-center">
            <a @click="goToOrder(props.item.orderId)">
              {{ props.item.orderId }}
            </a>
          </td>
          <td class="text-xs-center">{{ props.item.shipping_method }}</td>
          <td class="text-xs-center">{{ props.item.sect }}</td>
          <td class="text-xs-center">{{ props.item.package_type }}</td>
          <td class="text-xs-center">{{ props.item.grand_duty }}</td>
          <td class="text-xs-center">{{ props.item.charged_weight }}</td>
          <td class="text-xs-center">{{ props.item.shipping_total }}</td>
          <td class="text-xs-center">{{ props.item.grand_levy }}</td>
          <td class="text-xs-center">{{ props.item.hasInvoice }}</td>
          <td class="text-xs-center">
            {{ props.item.invoiceCreated || "no" }}
          </td>
          <td class="text-xs-center">{{ props.item.invoicePaid || "no" }}</td>
          <!-- <td class="text-xs-center" v-if="props.item.c13">
            {{ props.item.c13 }}
            <v-icon color="red" @click="removeC13(props.item.id)">close</v-icon>
        </td>
        <td class="text-xs-center" v-if="!props.item.c13">
            <v-btn icon color="primary" @click="openC13Box(props.item.id)" dark>
                <v-icon>add</v-icon>
            </v-btn>
        </td> -->
          <td>
            <v-checkbox
              v-model="props.item.c13"
              @change="addOnC13(props.item.id)"
            ></v-checkbox>
          </td>
          <td class="text-xs-center" v-if="props.item.qb">
            {{ props.item.qb }}
          </td>
          <td class="text-xs-center" v-if="!props.item.qb">
            <v-btn
              :disabled="!props.item.invoicePaid"
              icon
              color="indigo"
              @click="addOnQb(props.item.id, props.item.invoicePaid)"
              dark
            >
              <v-icon>add</v-icon>
            </v-btn>
          </td>
          <td class="text-xs-center">{{ props.item.box }}</td>
        </tr>
      </template>
    </v-data-table>
    <div class="load-more-container" v-if="canLoadMore">
      <v-btn
        color="primary"
        class="float-center"
        @click="loadMore"
        :loading="loading"
      >
        Load More
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
// import InfiniteLoading from 'vue-infinite-loading';

export default {
  props: ["packages", "currentPageIndex", "totalPages"],

  components: {
    // InfiniteLoading
  },

  created() {
    this.canLoadMore = this.currentPageIndex < this.totalPages ? true : false;
  },

  data() {
    return {
      currentPackage: null,
      c13Date: null,
      loading: false,
      canLoadMore: null,
      // pagination: {
      //     rowsPerPage: 150
      // },
      headers: [
        { text: "Checked" },
        { text: "Client" },
        { text: "Order" },
        {text: "Method"},
        { text: "Pkg." },
        {text: "Type"},
        { text: "Duty ($)" },
        { text: "Ch. Weight (lbs)" },
        { text: "Freight ($)" },
        { text: "Levy ($)" },
        { text: "Has Invoice" },
        { text: "Inv. Created" },
        { text: "Inv. Paid" },
        { text: "C13" },
        { text: "Quickbooks" },
        { text: "Box" },
      ],
    };
  },

  computed: {
    //   pages () {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.packages.length / this.pagination.rowsPerPage) : 0
    //   }
  },

  methods: {
    ...mapActions([
      "addToC13",
      "removeFromC13",
      "addToQb",
      "fetchShipmentReport",
      "sendSingleNotification",
    ]),

    loadMore() {
      let newIndex = this.currentPageIndex + 1;
      this.loading = true;
      let payload = {
        id: this.$route.params.id,
        page: newIndex,
      };
      this.fetchShipmentReport(payload).then((response) => {
        this.loading = false;
        let payload = {
          packages: response.data,
          currentPageIndex: response.meta.pagination.current_page,
        };
        this.$emit("load-more-packages", payload);
        this.canLoadMore =
          response.meta.pagination.current_page <
          response.meta.pagination.total_pages
            ? true
            : false;
      });
    },

    addOnC13(packageId) {
      let payload = {
        id: packageId,
      };

      this.addToC13(payload).then((response) => {
        // this.$emit("refresh-table");
      });
    },

    removeC13(id) {
      this.removeFromC13(id).then((response) => {
        this.$emit("refresh-table");
      });
    },

    addOnQb(id, date) {
      let payload = {
        id: id,
        date: date,
      };

      this.addToQb(payload).then((response) => {
        this.$emit("refresh-table");
      });
    },

    goToOrder(id) {
      this.$router.push({ name: "order.detail", params: { id: id } });
    },

    sendPackageNotification(id) {
      this.sendSingleNotification(id).then((response) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  margin-top: 2rem;
}
.load-more-container {
  margin-top: 1rem;
}
</style>


