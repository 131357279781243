<template>
  <div class="grid-container mt-3">
    <div class="grid-x">
      <div class="cell medium-8 float-center">
        <v-card>
          <v-card-title>
            <h1 class="display-3 grey--text">Checkout</h1>
          </v-card-title>
          <v-card-text>
            <v-alert
              color="success"
              icon="check_circle"
              v-model="paySuccess"
              dismissible
              transition="scale-transition"
              >Your transaction was successfully processed.</v-alert
            >
            <v-alert
              color="error"
              icon="info"
              v-model="paymentError"
              dismissible
              transition="scale-transition"
              >There was an error with your transaction. Please check the form
              and try again, or contact a Ship242 agent for further
              assistance.</v-alert
            >
            <div class="grix-x" v-if="cartItems.length > 0">
              <div class="cell">
                <CheckoutForm
                  @make-payment="submitPayment"
                  :processing="processing"
                />
              </div>
            </div>
            <p v-if="cartItems.length < 1">Your cart is currently empty.</p>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CheckoutForm from "../../components/checkout/CheckoutForm.vue";

export default {
  components: {
    CheckoutForm,
  },

  data() {
    return {
      paySuccess: false,
      paymentError: false,
      processing: false,
    };
  },

  computed: {
    ...mapGetters(["cartItems", "cartTotal", "authUser"]),
  },

  methods: {
    ...mapActions(["checkout", "fetchUserCart"]),

    submitPayment(data) {
      this.processing = true;

      let payload = {
        user: this.authUser.id,
        data: data,
        total: this.cartTotal,
      };

      this.checkout(payload).then(
        (response) => {
          this.fetchUserCart(this.authUser.id);
          this.processing = false;
          this.paySuccess = true;
        },
        (error) => {
          this.paymentError = true;
          this.processing = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.display-3 {
  margin-bottom: 0px;
}
.card__title {
  padding-bottom: 0px;
}
</style>
