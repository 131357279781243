<template>
  <v-card>
    <v-card-title>
      <h1 class="display-1 grey--text" v-if="!editingOrder">
        Create New Order
      </h1>
      <h1 class="display-1 grey--text" v-if="editingOrder">
        Editing Order #{{ editingOrder.id }}
      </h1>
    </v-card-title>
    <v-card-text>
      <!-- <div class="cell small-12 location-state" v-if="user.location">
          <p :class="getLocationClass">{{ user.location.data.name }}</p>
      </div>-->
      <p v-if="invoicePaid">
        The invoice attached to this order has already been paid. All editing
        priveleges have been frozen;
      </p>
      <v-form v-if="!invoicePaid">
        <div class="grid-x grid-margin-x">
          <div class="cell">
            <h4 class="headline">General Information</h4>
          </div>
          <div class="cell medium-4">
            <v-select
              label="Customer"
              autocomplete
              :loading="loadingCustomers"
              debounce-search="500"
              :items="tempCustomers"
              cache-items
              item-text="label"
              item-value="id"
              :search-input.sync="searchCustomerString"
              v-model="orderForm.customer"
              @blur="$v.orderForm.customer.$touch()"
              required
              v-if="!editMode"
            ></v-select>
            <v-text-field
              name="customer"
              label="Customer"
              required
              v-if="editMode"
              v-model="orderForm.customer"
              disabled
            ></v-text-field>
          </div>
          <div class="cell medium-4">
            <v-select
              label="Shipping Method"
              required
              :items="shippingMethods"
              v-model="orderForm.shipping_method"
            ></v-select>
          </div>
          <div class="cell medium-4">
            <v-select
              label="Was an invoice attached?"
              required
              :items="booleans"
              item-text="name"
              item-value="value"
              v-model="orderForm.invoice_in_box"
            ></v-select>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell">
            <h3 class="headline">Entered Warehouse Via</h3>
          </div>
          <div class="cell">
            <v-radio-group
              v-model="orderForm.warehouse_via"
              @change="initWarehouseVia"
              row
            >
              <v-radio
                :label="wv"
                :value="wv"
                v-for="(wv, index) in warehouseOptions"
                :key="index"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
        <!-- Individual Group -->
        <div
          class="grid-x grid-margin-x"
          v-if="orderForm.warehouse_via == 'individual'"
        >
          <div class="cell medium-3">
            <v-text-field
              name="first_name"
              label="First Name"
              required
              v-model="orderForm.individual.drop_fname"
            ></v-text-field>
          </div>
          <div class="cell medium-3">
            <v-text-field
              name="last_name"
              label="Last Name"
              required
              v-model="orderForm.individual.drop_lname"
            ></v-text-field>
          </div>
          <div class="cell medium-3">
            <v-text-field
              name="phone_number"
              label="Phone #"
              required
              v-model="orderForm.individual.drop_phone"
            ></v-text-field>
          </div>
          <div class="cell medium-3">
            <v-text-field
              name="email"
              label="Email"
              required
              v-model="orderForm.individual.drop_email"
            ></v-text-field>
          </div>
        </div>
        <!-- Courier Group -->
        <div
          class="grid-x grid-margin-x"
          v-if="orderForm.warehouse_via == 'courier'"
        >
          <div class="cell medium-6">
            <v-select
              label="Freight Company"
              required
              :items="freightCompanies"
              v-model="orderForm.courier.freight_company"
            ></v-select>
          </div>
          <div class="cell medium-6">
            <v-text-field
              name="tracking_number"
              label="Tracking #"
              required
              v-model="orderForm.courier.tracking_number"
            ></v-text-field>
          </div>
        </div>
        <!-- Pickup Group -->
        <div
          class="grid-x grid-margin-x"
          v-if="orderForm.warehouse_via == 'pick-up'"
        >
          <div class="cell medium-5">
            <v-select
              label="US Pickup Customer"
              autocomplete
              :loading="loadingPickups"
              required
              debounce-search="500"
              :items="tempPickups"
              cache-items
              item-text="label"
              item-value="id"
              :search-input.sync="searchPickupString"
              v-model="orderForm.pickup.pickup_order"
            ></v-select>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell medium-5">
            <v-text-field
              name="vendor"
              label="Vendor"
              required
              v-model="orderForm.vendor"
              @blur="$v.orderForm.vendor.$touch()"
            ></v-text-field>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell">
            <h3 class="headline">Packages</h3>
            <v-btn small color="success" @click.prevent="addPackage">
              <v-icon>add</v-icon>
            </v-btn>
          </div>
          <div
            class="cell"
            v-for="(pkg, pindex) of orderForm.packages"
            :key="pindex"
          >
            <v-card>
              <v-card-title>
                <div class="package-title-wrapper">
                  Package #{{ pindex + 1 }}
                  <v-btn
                    small
                    color="error"
                    @click.prevent="removePackage(pindex)"
                    v-if="orderForm.packages.length > 1"
                    class="float-right"
                  >
                    <v-icon>fa-times</v-icon>
                  </v-btn>
                </div>
              </v-card-title>
              <v-card-text>
                <div class="div">
                  <div class="grid-x grid-margin-x">
                    <div class="cell medium-3">
                      <v-select
                        label="Package Type"
                        :items="packageTypes"
                        required
                        v-model="orderForm.packages[pindex].type"
                      ></v-select>
                    </div>
                    <div class="cell medium-3">
                      <v-text-field
                        name="itemsno"
                        label="Number of Items"
                        required
                        v-model="orderForm.packages[pindex].itemsno"
                      ></v-text-field>
                    </div>
                    <div class="cell medium-3">
                      <v-text-field
                        name="vendor_shipping"
                        label="Vendor Shipping"
                        v-model="orderForm.packages[pindex].vendor_shipping"
                      ></v-text-field>
                    </div>
                    <div class="cell medium-3">
                      <v-text-field
                        name="item_tax"
                        label="Item Tax"
                        v-model="orderForm.packages[pindex].item_tax"
                      ></v-text-field>
                    </div>
                    <div class="cell medium-3">
                      <v-text-field
                        name="weight"
                        label="Weight(lbs)"
                        required
                        v-model="orderForm.packages[pindex].weight"
                      ></v-text-field>
                    </div>
                    <div class="cell medium-3">
                      <v-text-field
                        name="length"
                        label="Length(cm)"
                        required
                        v-model="orderForm.packages[pindex].length"
                      ></v-text-field>
                    </div>
                    <div class="cell medium-3">
                      <v-text-field
                        name="width"
                        label="Width(cm)"
                        required
                        v-model="orderForm.packages[pindex].width"
                      ></v-text-field>
                    </div>
                    <div class="cell medium-3">
                      <v-text-field
                        name="height"
                        label="Height(cm)"
                        required
                        v-model="orderForm.packages[pindex].height"
                      ></v-text-field>
                    </div>
                    <div class="cell">
                      <v-text-field
                        name="description"
                        label="Description"
                        required
                        v-model="orderForm.packages[pindex].description"
                      ></v-text-field>
                    </div>
                    <div class="cell">
                      <h3 class="headline">Items</h3>
                      <v-btn
                        small
                        color="success"
                        @click.prevent="addItem(pindex)"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </div>
                    <div
                      class="cell"
                      v-for="(item, index) of pkg.items"
                      :key="index"
                    >
                      <v-card>
                        <v-card-title>
                          <div class="item-title-wrapper">
                            Item #{{ index + 1 }}
                            <v-btn
                              small
                              color="error"
                              @click.prevent="removeItem(pindex, index)"
                              v-if="pkg.items.length > 1"
                              class="float-right"
                            >
                              <v-icon>fa-times</v-icon>
                            </v-btn>
                          </div>
                        </v-card-title>
                        <v-card-text>
                          <div class="grid-x grid-margin-x">
                            <div class="cell medium-3">
                              <v-select
                                label="Duty and Tariffs"
                                autocomplete
                                :loading="loadingTaxes"
                                required
                                :items="tempTaxes"
                                item-text="label"
                                item-value="id"
                                v-model="
                                  orderForm.packages[pindex].items[index].tax_id
                                "
                              ></v-select>
                            </div>
                            <div class="cell medium-3">
                              <v-text-field
                                name="quantity"
                                label="Quantity"
                                required
                                v-model="
                                  orderForm.packages[pindex].items[index]
                                    .quantity
                                "
                              ></v-text-field>
                            </div>
                            <div class="cell medium-3">
                              <v-text-field
                                name="unit_price"
                                label="Unit Price ($)"
                                required
                                v-model="
                                  orderForm.packages[pindex].items[index]
                                    .unit_price
                                "
                              ></v-text-field>
                            </div>
                            <div class="cell medium-3">
                              <v-text-field
                                name="invoice_number"
                                label="Invoice Number"
                                v-model="
                                  orderForm.packages[pindex].items[index]
                                    .invoice_number
                                "
                              ></v-text-field>
                            </div>
                            <div class="cell medium-4">
                              <v-select
                                label="Is this item hazardous?"
                                required
                                :items="booleans"
                                item-text="name"
                                item-value="value"
                                v-model="
                                  orderForm.packages[pindex].items[index].hazard
                                "
                              ></v-select>
                            </div>
                            <div class="cell medium-4">
                              <v-select
                                label="Is this item fragile?"
                                required
                                :items="booleans"
                                item-text="name"
                                item-value="value"
                                v-model="
                                  orderForm.packages[pindex].items[index]
                                    .fragile
                                "
                              ></v-select>
                            </div>
                            <div class="cell medium-4">
                              <v-text-field
                                name="description"
                                label="Description"
                                required
                                v-model="
                                  orderForm.packages[pindex].items[index]
                                    .description
                                "
                              ></v-text-field>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="grid-x">
          <div class="cell">
            <v-text-field
              name="comments"
              label="Comments"
              textarea
              v-model="orderForm.comments"
            ></v-text-field>
          </div>
        </div>
        <div class="grid-x mt-3">
          <div class="cell">
            <v-btn
              color="primary"
              @click.prevent="submit"
              :disabled="$v.orderForm.$invalid || isLoading"
              >Submit</v-btn
            >
          </div>
        </div>
      </v-form>
    </v-card-text>
    <v-snackbar :timeout="4000" color="error" v-model="isError" multi-line>{{
      errorMessage
    }}</v-snackbar>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  created() {
    this.getTaxes();
    if (this.$route.name == "order.edit") {
      this.editMode = true;
      this.fetchEditingOrder();
    } else {
      this.editMode = false;
      this.initCreateForm();
    }
  },
  mounted() {
    if (this.$route.query.tracking_number) {
      this.orderForm.courier.tracking_number =
        this.$route.query.tracking_number;
    }
  },
  data() {
    return {
      errorMessage: "",
      isError: false,
      loadingCustomers: false,
      loadingPickups: false,
      loadingTaxes: false,
      editMode: Boolean,
      tempCustomers: [],
      tempPickups: [],
      tempTaxes: [],
      searchCustomerString: null,
      searchPickupString: null,
      searchTaxString: null,
      orderForm: {},
      packageGroup: {},
      itemGroup: {},
      editingOrder: null,
      isLoading: false,
      booleans: [
        {
          name: "yes",
          value: 1,
        },
        {
          name: "no",
          value: 0,
        },
      ],
      shippingMethods: [
        "next-day",
        "standard",
        // "consolidate",
        "special",
        "business-plane",
        "business-boat",
        "industrial-plane",
        "industrial-boat",
        "code-red",
        'consolidation',
        "consolidate-sm",
        "consolidate-lg",
        // "consolidateN",
      ],
      packageTypes: ["envelope", "box"],
      warehouseOptions: ["individual", "courier", "pick-up"],
      freightCompanies: [
        "ups",
        "dhl",
        "fedex",
        "usps",
        "laser freight",
        "china post",
        "amazon",
      ],
    };
  },

  validations: {
    orderForm: {
      customer: {
        required,
      },
      vendor: {
        required,
      },
      shipping_method: {
        required,
      },
      invoice_in_box: {
        required,
      },
      warehouse_via: {
        required,
      },
      packages: {
        required,
        minLength: minLength(1),
        $each: {
          type: {
            required,
          },
          description: {
            required,
          },
          itemsno: {
            required,
          },
          weight: {
            required,
          },
          length: {
            required,
          },
          height: {
            required,
          },
          width: {
            required,
          },
          vendor_shipping: {
            required,
          },
          item_tax: {
            required,
          },
          items: {
            required,
            minLength: minLength(1),
            $each: {
              description: {
                required,
              },
              tax_id: {
                required,
              },
              quantity: {
                required,
              },
              unit_price: {
                required,
              },
              hazard: {
                required,
              },
              fragile: {
                required,
              },
            },
          },
        },
      },
    },
  },

  computed: {
    ...mapGetters(["customers", "pickups", "taxes", "authUser"]),
    invoicePaid() {
      if (this.editingOrder) {
        return this.editingOrder.invoice_paid;
      }
      return false;
    },
    // getLocationClass() {
    //   return {
    //     'madeira': this.user.location.data.id == 1,
    //     'harbor-bay': this.user.location.data.id == 2
    //   }
    // }
  },

  watch: {
    searchCustomerString(val) {
      val && this.queryCustomers(val);
    },

    searchPickupString(val) {
      val && this.queryPickups(val);
    },

    searchTaxString(val) {
      val && this.queryTaxes(val);
    },
  },

  methods: {
    ...mapActions([
      "fetchCustomers",
      "fetchPickups",
      "fetchTaxes",
      "createOrder",
      "editOrder",
      "fetchOrderDetails",
    ]),

    takeAwayShippingMethods() {
      if (this.authUser.id != 5387 && this.authUser.id != 1243) {
        if (this.editingOrder.delivery == "next-day") {
          this.shippingMethods = this.shippingMethods.filter((method) => {
            return method != "standard" && method != "special";
          });
        }
        if (this.editingOrder.delivery == "standard") {
          this.shippingMethods = this.shippingMethods.filter((method) => {
            return method != "special";
          });
        }
      }
    },

    initCreateForm() {
      this.orderForm = {
        customer: "",
        shipping_method: "standard",
        invoice_in_box: 0,
        warehouse_via: "courier",
        vendor: "",
        comments: "",
        packages: this.initPackages(),
      };
      this.initCourierGroup();
    },

    initWarehouseVia() {
      if (this.orderForm.warehouse_via == "individual") {
        this.initIndividualGroup();
        delete this.orderForm.courier;
        delete this.orderForm.pickup;
      }
      if (this.orderForm.warehouse_via == "courier") {
        this.initCourierGroup();
        delete this.orderForm.individual;
        delete this.orderForm.pickup;
      }
      if (this.orderForm.warehouse_via == "pick-up") {
        this.initPickupGroup();
        delete this.orderForm.courier;
        delete this.orderForm.individual;
      }
    },

    initIndividualGroup() {
      let order = this.editingOrder;

      let individualGroup = {
        drop_fname: order ? order.drop_fname : "",
        drop_lname: order ? order.drop_lname : "",
        drop_phone: order ? order.drop_phone : "",
        drop_email: order ? order.drop_email : "",
      };
      this.orderForm.individual = individualGroup;
    },

    initCourierGroup() {
      let order = this.editingOrder;
      let courierGroup = {
        freight_company: order ? order.freight_name : "",
        tracking_number: order ? order.freight_tracking : "",
      };
      this.orderForm.courier = courierGroup;
    },

    initPickupGroup() {
      let order = this.editingOrder;

      let pickupGroup = {
        pickup_order: order.pickup ? order.pickup.data.id : "",
      };
      this.orderForm.pickup = pickupGroup;
    },

    initPackages() {
      return [
        {
          id: "",
          type: "",
          description: "",
          itemsno: "",
          weight: "",
          height: "",
          width: "",
          length: "",
          vendor_shipping: 0,
          item_tax: 0,
          items: this.initItems(),
        },
      ];
    },

    getPackageGroup() {
      return {
        id: "",
        type: "",
        description: "",
        itemsno: "",
        weight: "",
        height: "",
        width: "",
        length: "",
        vendor_shipping: 0,
        item_tax: 0,
        items: this.initItems(),
      };
    },

    addPackage() {
      this.orderForm.packages.push(this.getPackageGroup());
    },

    removePackage(index) {
      if (this.orderForm.packages.length > 1) {
        this.orderForm.packages.splice(index, 1);
      }
    },

    addItem(pkg) {
      this.orderForm.packages[pkg].items.push(this.getItemGroup());
    },

    removeItem(pkgIndex, index) {
      if (this.orderForm.packages[pkgIndex].items.length > 1) {
        this.orderForm.packages[pkgIndex].items.splice(index, 1);
      }
    },

    initItems() {
      return [
        {
          id: "",
          description: "",
          tax_id: "",
          quantity: "",
          invoice_number: "",
          unit_price: "",
          hazard: 0,
          fragile: 0,
        },
      ];
    },

    getItemGroup() {
      return {
        id: "",
        description: "",
        tax_id: "",
        quantity: "",
        invoice_number: "",
        unit_price: "",
        hazard: 0,
        fragile: 0,
      };
    },

    queryCustomers(val) {
      this.loadingCustomers = true;
      this.fetchCustomers().then((response) => {
        this.tempCustomers = this.customers.filter((e) => {
          return (
            (e.label || "").toLowerCase().indexOf((val || "").toLowerCase()) >
            -1
          );
        });
        this.loadingCustomers = false;
      });
    },

    queryPickups(val) {
      this.loadingPickups = true;
      this.fetchPickups().then((response) => {
        this.tempPickups = this.pickups.filter((e) => {
          return (
            (e.label || "").toLowerCase().indexOf((val || "").toLowerCase()) >
            -1
          );
        });
        this.loadingPickups = false;
      });
    },

    queryTaxes(val) {
      this.loadingTaxes = true;
      this.fetchTaxes().then((response) => {
        this.tempTaxes = this.taxes.filter((e) => {
          return (
            (e.label || "").toLowerCase().indexOf((val || "").toLowerCase()) >
            -1
          );
        });
        this.loadingTaxes = false;
      });
    },

    getTaxes(val) {
      this.loadingTaxes = true;
      this.fetchTaxes().then((response) => {
        this.tempTaxes = this.taxes;
        this.loadingTaxes = false;
      });
    },

    fetchEditingOrder() {
      this.fetchOrderDetails(this.$route.params.id).then((response) => {
        this.editingOrder = response.data;
        this.setEditForm();
        this.takeAwayShippingMethods();
      });
    },

    setEditForm() {
      let order = this.editingOrder;
      this.orderForm = {
        customer: `${order.user.data.id} - ${order.user.data.full_name} -  ${order.user.data.email}`,
        shipping_method: order.delivery,
        invoice_in_box: order.invoice_in_box == "yes" ? 1 : 0,
        warehouse_via: order.warehouse_via,
        vendor: order.vendor,
        comments: order.comments,
        packages: this.editPackages(),
      };
      this.initWarehouseVia();
    },

    editPackages() {
      let existingPackages = [];
      let packages = this.editingOrder.packages.data;
      packages.forEach((pkg) => {
        let data = {
          id: pkg.id,
          type: pkg.package_type,
          description: pkg.description,
          itemsno: pkg.itemsno,
          weight: pkg.weight,
          height: pkg.height,
          width: pkg.width,
          length: pkg.depth,
          vendor_shipping: pkg.vendor_shipp || 0,
          item_tax: pkg.item_tax || 0,
          items: this.editItems(pkg),
        };
        existingPackages.push(data);
      });

      return existingPackages;
    },

    editItems(pkg) {
      let existingItems = [];
      let items = pkg.items.data;
      items.forEach((item) => {
        let data = {
          id: item.id,
          description: item.description,
          tax_id: item.tax_id,
          quantity: item.quantity,
          invoice_number: item.invoice_number,
          unit_price: item.unit_price,
          hazard: item.hazard == "yes" ? 1 : 0,
          fragile: item.fragile == "yes" ? 1 : 0,
        };
        existingItems.push(data);
      });

      return existingItems;
    },

    submit() {
      if (this.editMode) {
        this.submitEditForm();
      } else {
        this.submitCreateForm();
      }
    },

    submitEditForm() {
      let payload = {
        orderID: this.editingOrder.id,
        formData: this.orderForm,
      };

      this.isLoading = true;

      this.editOrder(payload).then(
        (response) => {
          let orderID = response.data.id;

          this.$router.push({
            name: "order.detail",
            params: {
              id: orderID,
            },
          });

          this.isLoading = false;
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error.response.data.message;
        }
      );
    },

    submitCreateForm() {
      let payload = {
        workerID: this.authUser.id,
        formData: this.orderForm,
      };

      this.isLoading = true;

      this.createOrder(payload).then(
        (response) => {
          let orderID = response.data.id;

          this.$router.push({
            name: "order.detail",
            params: {
              id: orderID,
            },
          });

          this.isLoading = false;
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error.response.data.message;

          this.isLoading = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  display: inline-block;
}
.item-title-wrapper,
.package-title-wrapper {
  width: 100%;
}
</style>
