<template>
  <div class="grid-container fluid mt-3">
        <Invoice 
        :invoice="invoice"
        v-if="!loadingInvoice && !invoice['newInvoice']"
        @refresh-invoice="getInvoiceData"
        />
        <InvoiceNew
        :invoice="invoice"
        v-if="!loadingInvoice && invoice['newInvoice']"
        @refresh-invoice="getInvoiceData"
        />
        <InvoiceActionButtons 
            :invoice="invoice" 
            v-if="authUser.account_type == 'admin' || authUser.account_type == 'worker'"
            @refresh-invoice="getInvoiceData"
        />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Invoice from '../../components/invoices/Invoice.vue';
import InvoiceNew from '../../components/invoices/InvoiceNew.vue';
import InvoiceActionButtons from '../../components/invoices/InvoiceActionButtons.vue';

export default {
    components: {
        Invoice,
        InvoiceNew,
        InvoiceActionButtons
    },

    created() {
        this.getInvoiceData();
    },

    data() {
        return {
            invoice: Object,
            loadingInvoice: false
        }
    },

    computed: {
        ...mapGetters([
            'authUser'
        ])
    },

    methods: {
        ...mapActions([
            'fetchInvoice'
        ]),

        getInvoiceData() {
            this.loadingInvoice = true;
            this.fetchInvoice(this.$route.params.id)
            .then((response) => {
                this.invoice = response.data;
                this.loadingInvoice = false;
            })
        }
    }
}
</script>

