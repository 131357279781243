export default {
    ordersGridItems: ( state ) => {
        return state.ordersGridItems;
    },

    packagesGridItems: ( state ) => {
        return state.packagesGridItems;
    },

    pickups: ( state ) => {
        return state.pickups;
    }
}