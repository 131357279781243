<template>
    <div class="grid-container fluid mt-3">
        <h1 class="display-2 grey--text">Checkpoints</h1>
        <div class="grid-x grid-margin-y">
            <div class="cell medium-4">
                <v-text-field
                v-model="search"
                label="Search Checkpoints"
                name="search"
                @input="filterResults(search)">
                </v-text-field>
            </div>
            <div class="cell">
                <CheckpointList :checkpoints="tempCheckpoints" v-if="!loadingCheckpoints" @refresh-checkpoints="getCheckpoints"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CheckpointList from '../../components/checkpoints/CheckpointList.vue';
import debounce from 'lodash/debounce';


export default {
    components: {
        CheckpointList
    },
    created() {
        this.getCheckpoints();
    },

    data() {
        return {
            tempCheckpoints: [],
            search: '',
            loadingCheckpoints: false
        }
    },

    computed: {
        ...mapGetters([
            'checkpoints'
        ])
    },

    methods: {
        ...mapActions([
            'fetchCheckpoints'
        ]),

        getCheckpoints() {
            this.loadingCheckpoints = true;
            this.fetchCheckpoints()
                .then((response) => {
                    this.tempCheckpoints = this.checkpoints;
                    this.loadingCheckpoints = false;
                }, error => {

                })
        },

        filterResults(search) {

            let val = search.toLowerCase();

            let temp = this.checkpoints.filter(function( checkpoint ) {

                let name = checkpoint.name.toLowerCase().indexOf(val) !== -1

                return name || !val;

            });

            let results = temp;
            this.tempCheckpoints = results;
            
        }
    }

}
</script>

