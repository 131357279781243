<template>
  <div class="grid-container fluid mt-3">
      <v-card>
          <v-card-title class="display-2 grey--text">
              Scan Packages
          </v-card-title>
          <v-card-text>
              <v-form>
                  <div class="grid-x grid-margin-y">
                      <div class="cell medium-5">
                        <v-select
                            :items="checkpoints"
                            item-text="name"
                            item-value="id"
                            v-model="scanForm.checkpoint"
                            label="Select Checkpoint"
                            single-line
                            bottom
                        ></v-select>
                      </div>
                  </div>
                  <div class="grid-x grid-margin-y">
                      <div class="cell">
                        <v-text-field
                        label="Package Codes"
                        name="codes"
                        textarea
                        autofocus
                        v-model="scanForm.qrcodes">
                        </v-text-field>
                      </div>
                  </div>
                  <div class="grid-x grid-margin-y">
                      <div class="cell">
                          How to Scan:
                          <ul class="steps">
                              <li>Place cursor in the Parcel Codes text area.</li>
                              <li>Open the scanner app on your phone or tablet (or use hand held scanner).</li>
                              <li>Scan single or multiple parcels (in a batch). Each parcel code will take up one row.</li>
                              <li>After you are done submit the scan.</li>
                          </ul>
                      </div>
                  </div>
                  <div class="grid-x grid-margin-y">
                      <div class="cell">
                          <v-btn color="primary" @click="submit">
                              Submit
                          </v-btn>
                      </div>
                  </div>
              </v-form>
          </v-card-text>
      </v-card>
      <v-snackbar
      :timeout="4000"
      color="success"
      v-model="successMessage"
    >
    Your packages were successfully scanned.
      </v-snackbar>
    <v-snackbar
      :timeout="4000"
      color="error"
      v-model="errorMessage"
      multi-line
    >
    There was an issue scanning these packages.
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators'

export default {
    created() {
        this.fetchCheckpoints();
    },

    data() {
        return {
            scanForm: {
                checkpoint: '',
                qrcodes: ''
            },
            successMessage: false,
            errorMessage: false
        }
    },

    validations: {
        scanForm: {
            checkpoint: {
                required
            },
            qrcodes: {
                required
            }
        }
    },

    computed: {
        ...mapGetters([
            'checkpoints',
            'authUser'
        ])
    },

    methods: {
        ...mapActions([
            'fetchCheckpoints',
            'createTrackings'
        ]),

        submit(){
            let payload = {
                user: this.authUser.id,
                data: this.scanForm
            }

            this.createTrackings(payload)
            .then((response) => {
                this.successMessage = true;
            }, error => {
                this.errorMessage = false;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .steps{
        list-style-type: decimal;
    }
</style>


