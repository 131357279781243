<template>
  <div class="grid-container fluid mt-3">
    <div class="grid-x">
      <div class="cell">
        <v-card>
          <v-card-title>
            <h1 class="display-2 grey--text">Packages In</h1>
          </v-card-title>
          <v-card-text>
            <div class="tracking-number-input">
              <div class="inputs">
                <input
                  type="text"
                  autofocus
                  placeholder="Packages box"
                  class="p-4 bg-gray-200 block text-xl font-semibold"
                  v-model="packageIn.tracking_number"
                  @keydown.enter="addPackage()"
                />
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="5"
                  class="p-4 bg-gray-200 mt-4 font-semibold text-black text-xl"
                  placeholder="Put notes here"
                  v-model="notes"
                ></textarea>
              </div>
              <button
                v-if="packages.length > 0"
                @click="submit"
                type="button"
                class="
                  px-4
                  py-2
                  rounded-md
                  text-lg
                  font-semibold
                  bg-green-500
                  text-white
                  mt-2
                "
              >
                Hold Packages
              </button>
            </div>
            <div class="packages-wrapper">
              <div
                class="packages mt-4 grid grid-cols-8 gap-x-4 gap-y-4"
                v-if="packages.length > 0"
              >
                <span
                  class="
                    border border-black
                    text-center
                    p-2
                    rounded-md
                    cursor-pointer
                    break-all
                  "
                  @click="removePackage(i)"
                  v-for="(pkg, i) in packages"
                  :key="i"
                  >Tracking #: {{ pkg.tracking_number }}</span
                >
              </div>
              <p v-else class="text-lg mt-4">
                Please scan a package while focused on the packages box then
                press "Enter" to add it here.
              </p>
            </div>

            <div class="held-section">
              <h2 class="font-semibold text-2xl mt-12">Held shipments</h2>
              <table class="w-full border-collapse mt-2">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Number of packages</th>
                    <th>Import date</th>
                    <th>Held by</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(entry, i) in packageIns" :key="i">
                    <td>
                      {{ entry.id }}
                    </td>
                    <td>
                      {{ entry.packages_count }}
                      <a
                        @click="showRelatedPackages(entry)"
                        class="
                          underline
                          text-blue-700
                          font-semibold
                          float-right
                        "
                      >
                        See packages</a
                      >
                    </td>
                    <td>
                      {{ entry.created_at }}
                    </td>
                    <td>
                      <span v-if="entry.user">
                        {{ entry.user.first_name }}
                        {{ entry.user.last_name }}
                      </span>
                    </td>
                    <td>
                      <a
                        @click="deletePackageIn(entry.id)"
                        class="underline text-red-700 font-semibold"
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <v-dialog
              max-width="800"
              v-model="showPackages"
              v-if="selectedEntry"
            >
              <v-card>
                <v-card-text>
                  <h2 class="font-semibold text-2xl mb-4">
                    Entry #{{ selectedEntry.id }} -
                    {{ selectedEntry.created_at }}
                  </h2>
                  <p>
                    {{ selectedEntry.notes }}
                  </p>
                  <div class="filter">
                    <input
                      type="text"
                      placeholder="Tracking number"
                      class="p-4 bg-gray-200 block text-xl font-semibold"
                      v-model="searchFilter"
                    />
                  </div>
                  <div class="packages mt-4 grid grid-cols-3 gap-x-4 gap-y-4">
                    <div
                      class="
                        border border-black
                        text-center
                        p-2
                        rounded-md
                        package
                        break-all
                      "
                      v-for="(pkg, i) in filteredPackages"
                      :key="i"
                    >
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        class="mr-4"
                        @change="checkPackage(pkg.id)"
                        v-model="pkg.checked"
                      />
                      <router-link
                        class="
                          cursor-pointer
                          break-all
                          font-semibold
                          text-red-500
                        "
                        :to="{
                          name: 'order.create',
                          query: { tracking_number: pkg.tracking_number },
                        }"
                        target="_blank"
                        >Tracking #{{ pkg.tracking_number }}</router-link
                      >
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {
    // CreditPaymentsForm
  },

  created() {
    this.getData();
  },

  data() {
    return {
      packageIn: { tracking_number: "" },
      packages: [],
      notes: "",
      packageIns: [],
      showPackages: false,
      selectedEntry: null,
      searchFilter: "",
    };
  },

  computed: {
    filteredPackages() {
      return this.selectedEntry.packages.filter((pkg) => {
        return pkg.tracking_number.includes(this.searchFilter);
      });
    },
  },

  methods: {
    ...mapActions([
      "createPackageIn",
      "fetchPackageIns",
      "deletePackageIns",
      "checkHeldPackage",
    ]),
    addPackage() {
      if (!this.packageIn) return false;
      this.packages.push(this.packageIn);
      this.packageIn = { tracking_number: "" };
    },
    removePackage(index) {
      const response = confirm("Are you sure you want to remove this package?");

      if (!response) return false;

      this.packages.splice(index, 1);
    },
    getData() {
      this.fetchPackageIns().then((res) => {
        this.packageIns = res;
      });
    },
    showRelatedPackages(entry) {
      this.selectedEntry = entry;
      this.showPackages = true;
    },
    submit() {
      let data = {
        packages: this.packages,
        notes: this.notes,
      };
      this.createPackageIn(data).then((res) => {
        this.packageIn = { tracking_number: "" };
        this.packages = [];
        this.notes = "";

        this.getData();
      });
    },
    deletePackageIn(id) {
      const res = confirm(
        `Are you sure you want to delete entry number ${id}?`
      );

      if (!res) return false;

      this.deletePackageIns(id).then(() => {
        this.getData();
      });
    },
    checkPackage(id) {
      this.checkHeldPackage(id);
    },
  },
};
</script>

<style lang="scss" scoped>
table thead tr th,
table tbody tr td {
  padding: 5px;
}

table tbody tr td,
table thead tr th {
  border: 1px solid #bbb;
}
.packages a:visited {
  color: green;
}
</style>


