<template>
    <div class="grid-container fluid mt-3">
        <div class="grid-x">
            <div class="cell">
                <v-card>
                    <v-card-title>
                        <h1 class="display-2 grey--text">
                            US Pickups
                        </h1>
                    </v-card-title>
                    <v-card-text>
                        Pickups Index
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  
}
</script>

