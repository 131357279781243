<template>
    <div class="grid-container fluid mt-3" v-if="authUser.account_type == 'admin' || authUser.account_type == 'worker'">
        <OrderForm />
    </div>
</template>

<script>
import OrderForm from '../../components/orders/OrderForm.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        OrderForm
    },
    computed: {
      ...mapGetters([
          'authUser'
      ])
    }
}
</script>
