<template>
  <div class="grid-container fluid main mt-3">
    <div class="grid-x" v-if="!loadingOrderInfo">
      <v-alert
        :value="!order['valid_unit_price'] && isStaff"
        type="error"
      >If the Unit price is below $5 only a qualified Admin can generate the invoice.</v-alert>
      <v-alert
        :value="user.flagged"
        type="error"
      >This user has been flagged for possible invoice fraud.</v-alert>
      <v-card class="cell">
        <v-card-title>
          <div class="grid-x header-wrapper">
            <div class="cell medium-4">
              <h1 class="display-2 grey--text">Order #{{ order.id }}</h1>
            </div>
            <div class="cell medium-8">
              <div class="action-buttons float-right">
                <div v-if="isAdmin" class="inline">
                  <v-tooltip top v-if="!order['completed']">
                    <v-btn dark color="red darken-2" slot="activator" @click="close">
                      <v-icon dark>stop</v-icon>
                    </v-btn>
                    <span>Close Order</span>
                  </v-tooltip>
                  <v-tooltip top v-else>
                    <v-btn dark color="green lighten-1" slot="activator" @click="open">
                      <v-icon dark>check</v-icon>
                    </v-btn>
                    <span>Open Order</span>
                  </v-tooltip>
                </div>
                <v-tooltip top v-if="isStaff">
                  <v-btn dark color="orange lighten-1" slot="activator" @click="initImageUpload">
                    <v-icon dark>camera_alt</v-icon>
                  </v-btn>
                  <span>Images</span>
                </v-tooltip>
                <v-tooltip top>
                  <v-btn dark color="primary" slot="activator" @click="initUpload">
                    <v-icon dark>file_upload</v-icon>
                  </v-btn>
                  <span>Uploads</span>
                </v-tooltip>
                <v-tooltip v-if="isSabrina" top>
                  <v-btn dark color="secondary" slot="activator" @click="manualInvoiceBox = true">
                    <v-icon dark>file_upload</v-icon>
                  </v-btn>
                  <span>Manual upload</span>
                </v-tooltip>
                <v-tooltip top v-if="isStaff && !order.invoice_paid">
                  <v-btn dark color="accent" slot="activator" @click="editOrder">
                    <v-icon dark>mode_edit</v-icon>
                  </v-btn>
                  <span>Edit Order</span>
                </v-tooltip>
                <v-tooltip top v-if="isSabrina">
                  <v-btn
                    dark
                    color="red lighten-1"
                    slot="activator"
                    @click="deleteOrderBox = true"
                    :disabled="order.invoice_paid"
                  >
                    <v-icon dark>delete</v-icon>
                  </v-btn>
                  <span>Delete Order</span>
                </v-tooltip>
                <v-tooltip top v-if="isStaff">
                  <v-btn
                    dark
                    color="red darken-1"
                    slot="activator"
                    @click="flag"
                    :disabled="user.flagged === 1"
                  >
                    <v-icon dark>flag</v-icon>
                  </v-btn>
                  <span>Flag User</span>
                </v-tooltip>
                <v-tooltip top v-if="order.invoice_id">
                  <v-btn dark color="secondary" slot="activator" @click="viewInvoice">
                    <v-icon dark>insert_drive_file</v-icon>
                  </v-btn>
                  <span>View Invoice</span>
                </v-tooltip>
                <v-tooltip top v-if="workerCanGenerate || adminCanGenerate">
                  <v-btn dark color="secondary" slot="activator" @click="createInvoice">
                    <v-icon dark>content_copy</v-icon>
                  </v-btn>
                  <span>Generate Invoice</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="grid-x grid-margin-y medium-up-4">
            <div class="cell">
              <v-icon>person</v-icon>
              {{ order["client_fullname"] }}
            </div>
            <div class="cell">Dept #: {{ order["user"]["data"]["id"] }}</div>
            <div class="cell">
              <v-icon>mail</v-icon>
              {{ order["user"]["data"]["email"] }}
            </div>
            <div class="cell">
              <v-icon>date_range</v-icon>
              {{ order["created_at"] }}
            </div>
            <div class="cell">
              <v-icon>flight</v-icon>
              {{ order["delivery"] }}
            </div>
            <div class="cell">Vendor: {{ order["vendor"] }}</div>
            <div class="cell">Tracking: {{ order["freight_tracking"] }}</div>
            <div class="cell">Invoice In Box: {{ order["invoice_in_box"] }}</div>
            <div class="cell" v-if="isSabrina">Shipment ID: {{ order["shipment_id"] }}</div>
            <div class="cell" v-if="isSabrina">Shipment Box: {{ order["shipment_box"] }}</div>
          </div>
          <div class="grid-x grid-margin-y">
            <div class="cell">
              <p class="headline">Comments:</p>
              <p>{{ order["comments"] }}</p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div
      class="grid-x"
      v-if="authUser.account_type == 'admin' || authUser.account_type == 'worker'"
    >
      <div class="cell small-12 location-state" v-if="user.location">
        <p :class="getLocationClass">{{ user.location.data.name }}</p>
      </div>
    </div>
    <div class="grid-x packages" v-if="!loadingOrderInfo">
      <div class="cell small-12" v-for="(parcel, index) in parcels" :key="parcel.id">
        <v-card class="package cell">
          <v-card-title>
            <div class="wrapper">
              <h3 class="display-1 grey--text">Package #{{ parcel["sect"] }}</h3>
              <div class="action-buttons float-right">
                <v-tooltip top>
                  <v-btn
                    dark
                    small
                    color="indigo"
                    slot="activator"
                    @click="getTrackingData(parcel.id)"
                  >
                    <v-icon dark>place</v-icon>
                  </v-btn>
                  <span>Tracking</span>
                </v-tooltip>
                <v-tooltip top>
                  <v-btn
                    dark
                    small
                    color="green"
                    slot="activator"
                    :to="{ name: 'shipping.label', params: { id: parcel.id } }"
                    v-if="
											authUser['account_type'] == 'admin' || authUser['account_type'] == 'worker'
										"
                    target="_blank"
                  >
                    <v-icon dark>line_weight</v-icon>
                  </v-btn>
                  <span>Shipping Label</span>
                </v-tooltip>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="grid-x grid-margin-x">
              <div class="cell small-12 medium-4">
                <strong>Description:</strong>
                {{ parcel["description"] }}
              </div>
              <div class="cell small-12 medium-4">
                <strong>Weight:</strong>
                {{ parcel["weight"] }}lbs
              </div>
              <div class="cell small-12 medium-4">
                <strong>Dimensions:</strong>
                {{ parcel["height"] }} x {{ parcel["width"] }} x {{ parcel["depth"] }} (LxWxH)
              </div>
              <div class="cell small-12 medium-4">
                <strong># of items:</strong>
                {{ parcel["itemsno"] }}
              </div>
              <div class="cell small-12 medium-4">
                <strong>Vendor Shipping:</strong>
                {{ parcel["vendor_shipp"] }}
              </div>
              <div class="cell small-12 medium-4">
                <strong>Tax:</strong>
                {{ parcel["custom_tax"] }}
              </div>
            </div>
          </v-card-text>
        </v-card>
        <div class="grid-x">
          <v-card class="item cell" v-for="(item, index) in parcel['items']['data']" :key="item.id">
            <v-card-text>
              <div class="grid-x">
                <div class="cell small-12 medium-4">
                  <strong>Description:</strong>
                  {{ item["description"] }}
                </div>
                <div class="cell small-12 medium-4">
                  <strong>Product Type:</strong>
                  {{ item["product_type"] }}
                </div>
                <div class="cell small-12 medium-4">
                  <strong>Quantity</strong>
                  : {{ item["quantity"] }}
                </div>
                <div class="cell small-12 medium-4">
                  <strong>Price/unit:</strong>
                  ${{ item["unit_price"] }}
                </div>
                <div class="cell small-12 medium-4">
                  <strong>Hazard:</strong>
                  {{ item["hazard"] }}
                </div>
                <div class="cell small-12 medium-4">
                  <strong>Fragile:</strong>
                  {{ item["fragile"] }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <v-dialog v-model="uploadBox" persistent max-width="500" lazy>
      <v-card>
        <v-card-title class="headline">Uploaded Invoices</v-card-title>
        <v-card-text>
          <ul>
            <li v-for="(upload, index) in order.uploads" :key="index" class="upload-item">
              <a
                  v-if="upload.origin === 'Customer' || isSabrina"
                  :href="upload.url" target="_blank">Upload #{{ index + 1 }} <span v-if="upload.origin === 'Manual'">({{upload.origin}})</span></a>
              <i
                class="fa fa-times upload-remove-button"
                @click="removeUpload(upload.path)"
                v-if="isAdmin"
              ></i>
            </li>
          </ul>
          <vue-clip :options="uploadOptions" :on-total-progress="uploadsComplete">
            <template slot="clip-uploader-action" slot-scope="params">
              <div v-bind:class="{ 'is-dragging': params.dragging }" class="upload-drop-area">
                <div class="dz-message">
                  <p>Click or Drag and Drop files here to upload</p>
                </div>
              </div>
            </template>

            <template slot="clip-uploader-body" slot-scope="props">
              <div v-for="file in props.files">
                {{ file.name }}
                <v-icon v-if="file.status == 'success'" color="success">done</v-icon>
                <v-icon v-if="file.status == 'error'" color="warn">error</v-icon>
              </div>
            </template>
          </vue-clip>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warn" flat @click.native="uploadBox = false">Cancel</v-btn>
          <v-btn color="success" flat @click.native="uploadBox = false">Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="uploadImageBox" persistent max-width="500" lazy>
      <v-card>
        <v-card-title class="headline">Uploaded Images</v-card-title>
        <v-card-text>
          <ul>
            <li v-for="(image, index) in order.images" :key="index" class="upload-item">
              <a :href="image.url" target="_blank">{{ image.file_name }}</a>
              <i
                class="fa fa-times upload-remove-button"
                @click="removeImageUpload(image.id)"
                v-if="isStaff"
              ></i>
            </li>
          </ul>
          <vue-clip :options="uploadImageOptions" :on-total-progress="imageUploadsComplete">
            <template slot="clip-uploader-action" slot-scope="params">
              <div v-bind:class="{ 'is-dragging': params.dragging }" class="upload-drop-area">
                <div class="dz-message">
                  <p>Click or Drag and Drop files here to upload</p>
                </div>
              </div>
            </template>

            <template slot="clip-uploader-body" slot-scope="props">
              <div v-for="file in props.files">
                {{ file.name }}
                <v-icon v-if="file.status == 'success'" color="success">done</v-icon>
                <v-icon v-if="file.status == 'error'" color="warn">error</v-icon>
              </div>
            </template>
          </vue-clip>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warn" flat @click.native="uploadImageBox = false">Cancel</v-btn>
          <v-btn color="success" flat @click.native="uploadImageBox = false">Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="trackingBox" max-width="500" lazy>
      <v-card>
        <v-card-title class="headline">Tracking Information</v-card-title>
        <v-card-text>
          <p
            v-if="trackings.length < 1"
          >There is currently no tracking information for this package.</p>
          <ul class="tracking-list">
            <li v-for="(tracking, index) in trackings">
              <v-icon>check_circle</v-icon>
              {{ tracking.name }}
              <span class="float-right">{{ tracking.created_at }}</span>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteOrderBox" max-width="500" lazy>
      <v-card>
        <v-card-title class="headline">Delete Order</v-card-title>
        <v-card-text>
          <p>
            Are you sure you want to delete
            <strong>Order #{{ order.id }}</strong>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" @click="deleteOrderBox = false">No</v-btn>
          <v-btn color="red lighten-1" @click="deleteOrder" dark>Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="manualInvoiceBox" max-width="500" lazy>
      <v-card>
        <v-card-title class="headline">Generate customer invoice</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="addManualInvoice">
            <div>
              <v-select
                  label="Type"
                  :items="['Amazon', 'Ebay']"
                  v-model="invoiceForm.type"
                  required
              ></v-select>
            </div>
            <div>
              <v-btn @click="addInvoiceFormItem">+ Add item</v-btn>
            </div>
            <div v-for="(item, index) in invoiceForm.items"
                 class="border border-gray-600 rounded p-4 mb-4"
            >
              <div class="flex justify-between items-center">
                <h3>Item #{{index+1}}</h3>
                <a @click="removeInvoiceFormItem(index)" class="text-red-500 text-sm underline">Remove</a>
              </div>
              <v-text-field
                  label="Description"
                  v-model="invoiceForm.items[index].description"
                  class="col-span-2"
                  required></v-text-field>
              <div class="grid grid-cols-2 gap-x-4">
                <v-text-field
                    label="Quantity"
                    v-model="invoiceForm.items[index].quantity"
                    required></v-text-field>
                <v-text-field
                    label="Price"
                    v-model="invoiceForm.items[index].price"
                    required></v-text-field>
              </div>
            </div>
<!--            <v-text-field-->
<!--                label="Tax"-->
<!--                v-model="invoiceForm.tax"-->
<!--                class="col-span-2"-->
<!--                required></v-text-field>-->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" @click="manualInvoiceBox = false">Cancel</v-btn>
          <v-btn color="red lighten-1" @click="addManualInvoice" dark>Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AdminActionButtons
      v-if="authUser.account_type == 'admin' || authUser.account_type == 'worker'"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AdminActionButtons from "../../components/admin/AdminActionButtons.vue";

export default {
  components: {
    AdminActionButtons,
  },
  created() {
    this.getOrderDetails();
  },

  data() {
    return {
      order: Object,
      parcels: [],
      uploadBox: false,
      uploadImageBox: false,
      token: localStorage.getItem("ship_token"),
      loadingOrderInfo: false,
      apiUrl: process.env.VUE_APP_API_URL || "//ship.test/api/v1/",
      uploadOptions: Object,
      uploadImageOptions: Object,
      trackingBox: false,
      trackings: [],
      user: Object,
      deleteOrderBox: false,
      manualInvoiceBox: false,
      invoiceForm: {
        type: 'Amazon',
        items: [
          {
            description: "",
            quantity: "",
            price: 0,
          },
        ],
        tax: 0
      }
    };
  },

  computed: {
    ...mapGetters(["authUser"]),
    getLocationClass() {
      return {
        madeira: this.user.location.data.id == 1,
        "harbor-bay": this.user.location.data.id == 2,
      };
    },
    isStaff() {
      return this.authUser.account_type == "admin" ||
        this.authUser.account_type == "worker"
        ? true
        : false;
    },
    isAdmin() {
      return this.authUser.account_type == "admin" ? true : false;
    },
    workerCanGenerate() {
      if (
        this.authUser.account_type == "worker" ||
        this.authUser.account_type == "admin"
      ) {
        if (this.order["valid_unit_price"] && !this.order["invoice_id"]) {
          return true;
        }
      }
      return false;
    },
    adminCanGenerate() {
      return this.isSabrina && !this.order["invoice_id"] ? true : false;
    },
    isSabrina() {
      return this.authUser.id == 5387 || this.authUser.id == 1243
        ? true
        : false;
    },
  },

  methods: {
    ...mapActions([
      "fetchOrderDetails",
      "fetchTrackingInfo",
      "generateInvoice",
      "deleteInvoiceUpload",
      "deleteImageUpload",
      "softDeleteOrder",
      "flagUser",
      "openOrder",
      "closeOrder",
      "generateManualInvoice"
    ]),

    addManualInvoice() {
      const info = {data: this.invoiceForm, orderId: this.order.id};

      this.generateManualInvoice(info).then((res) => {
        this.manualInvoiceBox = false;
        this.invoiceForm = {
          items: [
            {
              description: "",
              quantity: "",
              price: 0,
            },
          ],
          tax: 0
        }
      }).catch((error) => {
        alert(error.response.data.message)
        this.invoiceForm = {
          items: [
            {
              description: "",
              quantity: "",
              price: 0,
            },
          ],
          tax: 0
        }
      });
    },

    addInvoiceFormItem() {
      this.invoiceForm.items.push({
        description: "",
        quantity: "",
        price: "",
      });
    },

    removeInvoiceFormItem(index) {
      this.invoiceForm.items.splice(index, 1);
    },

    open() {
      this.openOrder(this.$route.params.id).then((res) => {
        this.getOrderDetails();
      });
    },

    close() {
      this.closeOrder(this.$route.params.id).then((res) => {
        this.getOrderDetails();
      });
    },

    getOrderDetails() {
      this.loadingOrderInfo = true;
      this.fetchOrderDetails(this.$route.params.id).then((response) => {
        this.order = response.data;
        this.parcels = this.order.packages.data;
        this.user = this.order.user.data;
        this.loadingOrderInfo = false;
      });
    },

    flag() {
      const result = confirm(
        `Are you sure you want to flag "${this.user.full_name}" for possible invoice fraud?`
      );

      if (result) {
        this.flagUser(this.user.id).then((res) => {
          this.getOrderDetails();
        });
      }
    },

    initUpload() {
      let url = `${this.apiUrl}orders/${this.$route.params.id}/uploads?token=${this.token}`;
      this.uploadOptions = {
        url: url,
        paramName: "file",
        acceptedFiles: {
          extensions: [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "application/pdf",
            "application/msword",
          ],
        },
        maxFilesize: {
          limit: 5,
          message: "This file exceeds the {{ maxFilesize }} limit.",
        },
      };
      this.uploadBox = true;
    },

    uploadsComplete(progress, totalBytes, bytesSent) {
      if (progress == 100) {
        this.getOrderDetails();
      }
    },

    imageUploadsComplete(progress, totalBytes, bytesSent) {
      if (progress == 100) {
        this.getOrderDetails();
      }
    },

    initImageUpload() {
      let url = `${this.apiUrl}orders/${this.$route.params.id}/images?token=${this.token}`;
      this.uploadImageOptions = {
        url: url,
        paramName: "images",
        acceptedFiles: {
          extensions: ["image/png", "image/jpg", "image/jpeg"],
        },
        maxFilesize: {
          limit: 5,
          message: "This file exceeds the {{ maxFilesize }} limit.",
        },
      };
      this.uploadImageBox = true;
    },

    uploadsComplete(progress, totalBytes, bytesSent) {
      if (progress == 100) {
        this.getOrderDetails();
      }
    },

    getTrackingData(id) {
      this.fetchTrackingInfo(id).then((response) => {
        this.trackings = response.data;
        this.trackingBox = true;
      });
    },

    editOrder() {
      this.$router.push({
        name: "order.edit",
        params: { id: this.$route.params.id },
      });
    },

    viewInvoice() {
      this.$router.push({
        name: "invoice.show",
        params: { id: this.order.invoice_id },
      });
    },

    createInvoice() {
      let payload = {
        order: this.$route.params.id,
        worker: this.authUser.id,
      };

      this.generateInvoice(payload).then(
        (response) => {
          this.$router.push({
            name: "invoice.show",
            params: { id: response.data.id },
          });
        },
        (error) => {
          console.log(error);
        }
      );
    },

    removeUpload(file) {
      this.deleteInvoiceUpload(file).then(
        (response) => {
          this.getOrderDetails();
        },
        (error) => {
          console.log(error);
        }
      );
    },

    removeImageUpload(image) {
      this.deleteImageUpload(image).then(
        (response) => {
          this.getOrderDetails();
        },
        (error) => {
          console.log(error);
        }
      );
    },

    deleteOrder() {
      console.log(this.order.id);
      this.softDeleteOrder(this.order.id).then(
        (response) => {
          this.$router.push({ name: "orders.index" });
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.location-state {
  p {
    margin-bottom: 0px;
    margin-top: 2rem;
    font-size: 2.5rem;
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: center;
  }
  p.harbor-bay {
    color: green;
  }
  p.madeira {
    color: red;
  }
}
.header-wrapper {
  width: 100%;
}
.packages {
  margin-top: 2rem;
  .package {
    margin-bottom: 1rem;
    .wrapper {
      width: 100%;
    }
    h3 {
      margin-bottom: 0px;
      display: inline-block;
    }
    .card__title {
      padding-bottom: 1rem;
    }
    .card__text {
      padding-top: 0px;
    }
  }
  .item {
    margin-bottom: 1rem;
  }
}
.upload-drop-area {
  border: 4px dotted #ccc;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  .dz-message {
    font-size: 1rem;
  }
}
.upload-drop-area.is-dragging {
  border: 4px dotted green;
}
.tracking-list {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  li {
    margin-bottom: 0.5rem;
    i {
      color: green;
    }
  }
}
.upload-remove-button {
  margin-left: 1rem;
  font-size: 1rem;
  cursor: pointer;
}
</style>
