<template>
  <div class="grid-container fluid mt-3 shipment-report">
    <div class="grid-x" v-if="!loadingData">
      <div class="cell">
        <v-card>
          <v-card-title class="header">
            <h1 class="display-2 grey--text">
              Shipment # {{ packages[0].shipmentId }}
            </h1>
          </v-card-title>
          <v-card-text>
            <div class="grid-x">
              <div class="cell">
                <p class="headline">
                  Manifest: {{ packages[0].manifest_key || "TBD" }}
                </p>
              </div>
            </div>
            <div class="grid-x grid-margin-y medium-up-6">
              <div class="cell">Carrier: {{ packages[0].carrier }}</div>
              <div class="cell">
                Packages: {{ meta.total_paid_packages }}/{{
                  meta.total_packages
                }}
              </div>
              <div class="cell">Freight Cost: ${{ packages[0].freight }}</div>
              <div class="cell">C13: ${{ packages[0].customs }}</div>
              <div class="cell">
                Actual Weight: {{ packages[0].actualWeightTotal }} lbs
              </div>
              <div class="cell">
                Dimensional Weight: {{ packages[0].dimensionalWeightTotal }} lbs
              </div>
              <div class="cell">
                Chargeable Weight: {{ packages[0].chargeable_total }} lbs
              </div>
              <div class="cell">Duty Total: ${{ meta.total_duty }}</div>
              <div class="cell">Levy Total: ${{ meta.total_levy }}</div>
              <div class="cell">
                Freight (no vat): ${{ meta.freight_subtotal }}
              </div>
              <div class="cell">Vat (freight): ${{ meta.freight_vat }}</div>
              <div class="cell">
                Vat (no freight): ${{ meta.vat_no_freight }}
              </div>
              <div class="cell">Processing: ${{ meta.processing_total }}</div>
              <div class="cell">US Pickup Total: ${{ meta.pickup_total }}</div>
              <div class="cell">
                Arrival Date: {{ packages[0].arrival_date }}
              </div>
            </div>
            <div class="grid-x">
              <div class="cell medium-1">
                <a @click="calcBox = true">Calculations</a>
              </div>
            </div>
            <div class="grid-x">
              <div class="cell">
                <ShipmentDataTable
                  :packages="packages"
                  :currentPageIndex="currentPageIndex"
                  :totalPages="totalPages"
                  @refresh-table="getShipmentData"
                  @load-more-packages="loadMorePackages"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <ShipmentActionButtons
      v-if="!loadingData"
      :notificationSent="notificationSent"
      :notified="packages[0].notified"
      @send-notifications="notify"
      @open-notes="openNotesBox"
    />
    <v-dialog
      max-width="600px"
      v-model="notesBox"
      v-if="!loadingData && notesBox"
    >
      <v-card>
        <v-icon @click="notesBox = false" class="dialog-close-icon"
          >close</v-icon
        >
        <v-card-title>
          <!-- <div class="headline">
                      Notes & Attachments
          </div>-->
        </v-card-title>
        <v-card-text>
          <div class="grid-x grid-padding-x grid-margin-y">
            <div class="cell">
              <div class="title">Notes</div>
              <span>{{ meta.notes }}</span>
            </div>
            <div class="cell">
              <div class="title">Files</div>
              <div class="attachments">
                <ul v-if="attachments.length > 0">
                  <li v-for="(attachment, index) in attachments" :key="index">
                    <a :href="attachment" target="_blank"
                      >Attachment #{{ index + 1 }}</a
                    >
                  </li>
                </ul>
                <p v-else>There are no attachments on this shipment report.</p>
              </div>
              <div class="upload-box">
                <vue-clip
                  :options="uploadOptions"
                  :on-total-progress="uploadsComplete"
                >
                  <template slot="clip-uploader-action" slot-scope="params">
                    <div
                      v-bind:class="{ 'is-dragging': params.dragging }"
                      class="upload-drop-area"
                    >
                      <div class="dz-message">
                        <p>Click or Drag and Drop files here to upload</p>
                      </div>
                    </div>
                  </template>

                  <template slot="clip-uploader-body" slot-scope="props">
                    <div v-for="file in props.files">
                      {{ file.name }}
                      <v-icon v-if="file.status == 'success'" color="success"
                        >done</v-icon
                      >
                      <v-icon v-if="file.status == 'error'" color="warn"
                        >error</v-icon
                      >
                    </div>
                  </template>
                </vue-clip>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="calcBox"
      max-width="400px"
      v-if="!loadingData"
    >
      <v-card>
        <v-card-text>
          <v-form>
            <div class="grid-x grid-margin-x">
              <div class="cell medium-6">
                <strong
                  >Packages: {{ meta.total_paid_packages }}/{{
                    meta.total_packages
                  }}</strong
                >
                <br />
              </div>
              <div class="cell medium-6">
                <strong>C13 Packages: {{ meta.c13_packages }}</strong>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  disabled
                  :value="meta.duty_paid"
                  label="Duty"
                  prepend-icon="attach_money"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  v-model="calcForm.duty.value"
                  type="number"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  disabled
                  :value="meta.levy_paid"
                  label="Levy"
                  prepend-icon="attach_money"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  v-model="calcForm.levy.value"
                  type="number"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  disabled
                  :value="meta.freight_paid"
                  label="Freight"
                  prepend-icon="attach_money"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  v-model="calcForm.freight.value"
                  type="number"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  disabled
                  :value="meta.processing_paid"
                  label="Processing Fees"
                  prepend-icon="attach_money"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  v-model="calcForm.processing.value"
                  type="number"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  disabled
                  :value="meta.vat_paid"
                  label="Vat Due 12%"
                  prepend-icon="attach_money"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  v-model="calcForm.vat.value"
                  type="number"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field v-model="calcForm.optional1.name"></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  v-model="calcForm.optional1.value"
                  type="number"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field v-model="calcForm.optional2.name"></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  v-model="calcForm.optional2.value"
                  type="number"
                ></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field v-model="calcForm.optional3.name"></v-text-field>
              </div>
              <div class="cell medium-6">
                <v-text-field
                  v-model="calcForm.optional3.value"
                  type="number"
                ></v-text-field>
              </div>
            </div>
          </v-form>
          <div class="totals">
            <strong class="float-left standing-total">{{
              standingTotal()
            }}</strong>
            <strong class="float-right running-total">{{
              runningTotal()
            }}</strong>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="calcBox = false">Cancel</v-btn>
          <v-btn color="success" @click="updateCalc">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ShipmentDataTable from "../../components/shipments/ShipmentDataTable.vue";
import ShipmentActionButtons from "../../components/shipments/ShipmentActionButtons.vue";

export default {
  components: {
    ShipmentDataTable,
    ShipmentActionButtons,
  },

  created() {
    this.getShipmentData();
  },

  data() {
    return {
      loadingData: false,
      calcBox: false,
      packages: [],
      notesBox: false,
      token: localStorage.getItem("ship_token"),
      notificationSent: false,
      currentPageIndex: 1,
      totalPages: null,
      totalPackages: null,
      meta: null,
      apiUrl: process.env.API_URL || "//ship.test/api/v1/",
      uploadOptions: Object,
      attachments: [],
      calcForm: {
        duty: {
          name: "Duty",
          value: 0,
        },
        levy: {
          name: "Levy",
          value: 0,
        },
        freight: {
          name: "Freight",
          value: 0,
        },
        processing: {
          name: "Processing",
          value: 0,
        },
        vat: {
          name: "Vat",
          value: 0,
        },
        optional1: {
          name: "Optional 1",
          value: 0,
        },
        optional2: {
          name: "Optional 2",
          value: 0,
        },
        optional3: {
          name: "Optional 3",
          value: 0,
        },
      },
    };
  },

  methods: {
    ...mapActions([
      "fetchShipmentReport",
      "sendShipmentNotifications",
      "fetchAttachments",
      "deleteAttachment",
      "updateCalculations",
    ]),

    runningTotal() {
      let total = 0;
      Object.entries(this.calcForm).forEach(([key, value]) => {
        total += Number(value.value);
      });
      return Number(total).toFixed(2);
    },

    standingTotal() {
      let total =
        this.meta.duty_paid +
        this.meta.levy_paid +
        this.meta.freight_paid +
        this.meta.processing_paid +
        this.meta.vat_paid;
      return total.toFixed(2);
    },

    initUpload() {
      let url = `${this.apiUrl}shipments/${this.$route.params.id}/attachments?token=${this.token}`;
      this.uploadOptions = {
        url: url,
        paramName: "file",
        acceptedFiles: {
          extensions: [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "application/pdf",
          ],
        },
        maxFilesize: {
          limit: 5,
          message: "This file exceeds the {{ maxFilesize }} limit.",
        },
      };
    },

    uploadsComplete(progress, totalBytes, bytesSent) {
      if (progress == 100) {
        this.getAttachments();
      }
    },

    getAttachments() {
      this.fetchAttachments(this.$route.params.id).then((response) => {
        this.attachments = response;
      });
    },

    openNotesBox() {
      this.getAttachments();
      this.initUpload();
      this.notesBox = true;
    },

    getShipmentData() {
      this.loadingData = true;

      let payload = {
        id: this.$route.params.id,
        page: this.currentPageIndex,
      };

      this.fetchShipmentReport(payload).then((response) => {
        this.packages = response.data;
        this.meta = response.meta;
        this.currentPageIndex = response.meta.pagination.current_page;
        this.totalPages = response.meta.pagination.total_pages;
        this.totalPackages = response.meta.pagination.total;
        if (response.meta.calculations) {
          this.calcForm = response.meta.calculations.calculations;
        }
        this.calcBox = true;
        this.loadingData = false;
      });
    },

    loadMorePackages(payload) {
      this.currentPageIndex = payload.currentPageIndex;
      this.packages.push(...payload.packages);
    },

    notify() {
      this.notificationSent = true;
      this.sendShipmentNotifications(this.$route.params.id).then(
        (response) => {
          this.notificationSent = true;
        },
        (error) => {
          this.notificationSent = false;
        }
      );
    },

    updateCalc() {
      let payload = {
        id: this.$route.params.id,
        calculations: this.calcForm,
      };
      this.updateCalculations(payload).then((response) => {
        this.calcBox = false;
        this.getShipmentData();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  display: block;
  margin-bottom: 0px;
}
.headline {
  margin-bottom: 0px;
}
.header {
  padding-bottom: 0px;
}
.upload-drop-area {
  border: 4px dotted #ccc;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  .dz-message {
    font-size: 1rem;
  }
}
.upload-drop-area.is-dragging {
  border: 4px dotted green;
}
.attachments {
  ul {
    padding-left: 0px;
    list-style: none;
    margin-top: 0.5rem;
  }
}
.totals {
  margin-bottom: 2rem;
  strong {
    font-size: 2rem;
  }
}
.running-total {
  color: $success;
}

.standing-total {
  color: $info;
}
</style>


