import { http } from "../plugins/http.js";

export const invoiceService = {
	fetchInvoiceData(id) {
		return http({
			method: "get",
			url: `/invoices/${id}`,
		});
	},

	generateInvoice(data) {
		return http({
			method: "post",
			url: `/invoices`,
			params: {
				orderID: data.order,
				workerID: data.worker,
			},
		});
	},

	updateInvoice(data) {
		return http({
			method: "put",
			url: `/invoices/${data.id}`,
			data: {
				type: data.type,
			},
		});
	},

	approveInvoice(data) {
		return http({
			method: "get",
			url: `/invoices/${data.invoice}/approve`,
			params: {
				workerID: data.worker,
			},
		});
	},

	unapproveInvoice(data) {
		return http({
			method: "get",
			url: `/invoices/${data.invoice}/unapprove`,
			params: {
				workerID: data.worker,
			},
		});
	},

	updateFees(fees, invoice) {
		return http({
			method: "post",
			url: `/invoices/${invoice}/fees`,
			data: {
				data: fees,
			},
		});
	},

	makeInvoicePayment(invoice, type) {
		return http({
			method: "get",
			url: `/invoices/${invoice}/pay?type=${type}`,
		});
	},

	addInvoiceToCart(invoice, user) {
		return http({
			method: "post",
			url: `/users/${user}/cart`,
			data: {
				invoice: invoice,
			},
		});
	},

	removeInvoiceFromCart(invoice, user) {
		return http({
			method: "delete",
			url: `/users/${user}/cart/${invoice}`,
		});
	},

	makePayment(payload) {
		return http({
			method: "post",
			url: `/users/${payload.user}/cart/pay`,
			data: {
				data: payload.data,
				cartTotal: payload.total,
				delivery: payload.delivery,
			},
		});
	},

	postCCPayment(payload) {
		return http({
			method: "post",
			url: `/credits`,
			data: {
				data: payload.data,
				worker: payload.worker,
			},
		});
	},

	fetchInjuryData() {
		return http({
			method: "get",
			url: `/invoices/incorrect`,
		});
	},
};
