<template>
  <div class="grid-x">
    <div class="cell">
      <v-toolbar color="indigo" dark height="250px">
        <div class="grid-x float-center">
          <div class="cell">
            <div class="address-info">
              <ul>
                <li><strong>US Shipping Address</strong></li>
                <li>
                  {{ authUser["first_name"] }} {{ authUser["last_name"] }} Dept
                  {{ authUser["id"] }}
                </li>
                <li>Unit 1700 c/o SHIP242</li>
                <li>8700 NW 77th Ct</li>
                <li>Medley Florida 33166</li>
              </ul>
              <p>
                <i
                  >Next to your Dept #, add N (priority), S (standard) X (no
                  rush) or C (consolidated)</i
                >
              </p>
              <p>
                <i
                  >c/o SHIP242 -
                  <strong
                    >it is very important this is included on your shipping
                    address</strong
                  ></i
                >
              </p>
            </div>
          </div>
        </div>
      </v-toolbar>
      <v-tabs
        icons
        centered
        v-if="!loadingInvoices && !loadingOrders"
        slot="extension"
      >
        <v-tab href="#invoices">
          <v-icon>fa-files-o</v-icon>
          Invoices
        </v-tab>
        <v-tab href="#orders">
          <v-icon>fa-files-o</v-icon>
          Order History
        </v-tab>
        <v-tabs-items>
          <v-tab-item id="invoices">
            <div class="grid-x grid-padding-x mt-3">
              <div class="cell">
                <InvoicesTabContent
                  :invoices="invoices"
                  :cartItems="cartItems"
                  @invoice-added="addToCart"
                />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item id="orders">
            <div class="grid-x grid-padding-x mt-3">
              <div class="cell">
                <OrdersTabContent :orders="orders"/>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InvoicesTabContent from "../../components/client/InvoicesTabContent.vue";
import OrdersTabContent from "../../components/client/OrdersTabContent.vue";

export default {
  components: {
    InvoicesTabContent,
    OrdersTabContent,
  },
  created() {
    this.getUserData();
  },

  data() {
    return {
      loadingInvoices: false,
      loadingOrders: false,
      invoices: [],
      orders: [],
    };
  },

  computed: {
    ...mapGetters(["authUser", "cartItems"]),
  },

  methods: {
    ...mapActions([
      "fetchUserInvoices",
      "fetchUserOrders",
      "addInvoiceToCart",
      "openCart",
      "fetchUserCart",
    ]),

    getUserData() {
      this.getUserInvoices();
      this.getUserOrders();
    },

    getUserInvoices() {
      this.loadingInvoices = true;
      this.fetchUserInvoices(this.authUser.id).then((response) => {
        this.invoices = response.data;
        this.loadingInvoices = false;
      });
    },

    getUserOrders() {
      this.loadingOrders = true;
      this.fetchUserOrders(this.authUser.id).then((response) => {
        this.orders = response.data;
        this.loadingOrders = false;
      });
    },

    addToCart(invoice) {
      let payload = {
        invoice: invoice,
        user: this.authUser.id,
      };

      this.addInvoiceToCart(payload).then((response) => {
        this.fetchUserCart(this.authUser.id).then((response) => {
          this.openCart();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.address-info {
  text-align: center;
  ul {
    list-style: none;
    li {
      font-size: 1.2rem;
    }
  }
}
.float-center {
  margin: 0 auto !important;
}
</style>
