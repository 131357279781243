import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    state: {
        authUser: localStorage.getItem('ship_user'),
        token: localStorage.getItem('ship_token')
    },
    mutations,
    actions,
    getters
}