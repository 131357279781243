<template>
<div class="grid-x">
    <div class="cell" v-if="!loading">
        <v-card v-if="authUser.account_type == 'admin'">
            <v-card-text>
                <h1 class="display-2 grey--text">
                    EOD for {{ report.meta.report_date }}
                </h1>
                <div class="grid-x grid-margin-x grid-margin-y medium-up-6 mb-3">
                    <!-- <div class="cell medium-3">
                        <v-card color="info">
                            <v-card-text class="total-block">
                                <strong>Cash Total:</strong> ${{ report.meta.cash_total }}
                            </v-card-text>
                        </v-card>
                    </div> -->
                    <div class="cell medium-3">
                        <v-card color="info">
                            <v-card-text class="total-block">
                                <strong>H. Bay Cash Total:</strong> ${{ report.meta.harbor_bay_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell medium-3">
                        <v-card color="info">
                            <v-card-text class="total-block">
                                <strong>Madeira Cash Total:</strong> ${{ report.meta.madeira_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="info">
                            <v-card-text class="total-block">
                                <strong>Bank Total:</strong> ${{ report.meta.bank_total }}
                                <!-- <strong>Bank Total:</strong> ${{ bankTotal() }} -->
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="info">
                            <v-card-text class="total-block">
                                <strong>Check Total:</strong> ${{ report.meta.check_total }}
                                <!-- <strong>Check Total:</strong> ${{ checkTotal() }} -->
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="info">
                            <v-card-text class="total-block">
                                <!-- <strong>Online Total:</strong> ${{ report.meta.online_total }} -->
                                <strong>Online Total:</strong> ${{ report.meta.online_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="info">
                            <v-card-text class="total-block">
                                <strong>CC Payments:</strong> ${{ report.meta.credits_total || 0 }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Online CC Fees:</strong> ${{ report.meta.cc_processing }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <!-- <div class="cell">
                        <v-card color="success">
                            <v-card-text class="total-block">
                                <strong>Grand Total:</strong> ${{ report.meta.grand_total }}
                            </v-card-text>
                        </v-card>
                    </div> -->
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Duty Total:</strong> ${{ report.meta.total_duty }}
                                <!-- <strong>Duty Total:</strong> ${{ dutyTotal() }} -->
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Levy Total:</strong> ${{ report.meta.total_levy }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Additional Weight Fees:</strong> ${{ report.meta.additional_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Vat (add. weight):</strong> ${{ report.meta.additional_vat }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Hazard Fees:</strong> ${{ report.meta.hazard_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Hazard Vat:</strong> ${{ report.meta.hazard_vat }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Fragile Fees:</strong> ${{ report.meta.fragile_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Fragile Vat:</strong> ${{ report.meta.fragile_vat }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Consolidation Fees:</strong> ${{ report.meta.consolidate_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Vat (consolidation):</strong> ${{ report.meta.consolidate_vat }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Managed Fees:</strong> ${{ report.meta.fees_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Vat (fees):</strong> ${{ report.meta.fees_vat }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>US Pickup Total:</strong> ${{ report.meta.pickup_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Vat (us pickup):</strong> ${{ report.meta.pickup_vat }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <!-- <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Freight (shipping):</strong> ${{ report.meta.shippingTotal }}
                            </v-card-text>
                        </v-card>
                    </div> -->
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Freight (no-vat):</strong> ${{ report.meta.shippingTotal }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <!-- <div class="cell">
                        <v-card>
                            <v-card-text>
                                <strong>Vat (freight-old):</strong> ${{ report.meta.freight_vat }}
                            </v-card-text>
                        </v-card>
                    </div> -->
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Vat (freight):</strong> ${{ report.meta.shippingVatTotal }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Vat (no freight):</strong> ${{ report.meta.vat_no_freight }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text>
                                <strong>Insurance Total:</strong> ${{ report.meta.insurance_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell">
                        <v-card color="warning">
                            <v-card-text v-if="report.meta.report_date > '2018-05-10'">
                                <strong>Processing Total:</strong> ${{ report.meta.processing_total }}
                            </v-card-text>
                            <v-card-text v-if="report.meta.report_date < '2018-05-10'">
                                <strong>Processing Total:</strong> ${{ oldProcessingTotal }}
                            </v-card-text>
                        </v-card>
                    </div>
                  <div class="cell">
                    <v-card color="warning">
                      <v-card-text v-if="report.meta.report_date > '2018-05-10'">
                        <strong>Doc. Fee Total:</strong> ${{ report.meta.documentation_fee_total}}
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="cell">
                    <v-card color="warning">
                      <v-card-text v-if="report.meta.report_date > '2018-05-10'">
                        <strong>Doc. Fee Vat:</strong> ${{ report.meta.documentation_fee_vat}}
                      </v-card-text>
                    </v-card>
                  </div>
                    <div class="cell">
                        <v-card>
                            <v-card-text>
                                <strong>Total Vat:</strong> ${{ report.meta.vat_total }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="cell medium-3">
                        <v-card color="error">
                            <v-card-text class="total-block">
                                <strong>Eod Difference:</strong> ${{ report.meta.eod_diff }}
                            </v-card-text>
                        </v-card>
                    </div>
                    <!-- <div class="cell">
                        <v-card>
                            <v-card-text>
                                <strong>Delivery Total:</strong> $0
                            </v-card-text>
                        </v-card>
                    </div> -->
                </div>
                <div class="grid-x">
                    <div class="cell">
                        <strong class="headline">Notes*:</strong> {{ report.meta.report_notes }}
                    </div>
                </div>
                <div class="grid-x eod-table">
                    <div class="cell">
                        <v-data-table
                            v-bind:headers="headers"
                            :items="trans"
                            hide-actions
                            class="elevation-1"
                            >
                            <template slot="items" slot-scope="props">
                            <td>{{ props.item.id }}</td>
                            <td>{{ props.item.type }}</td>
                            <td>{{ props.item.pnp }}</td>
                            <td><router-link color="success" :to=" { target: '_blank', name: 'invoice.show', params: { id: props.item.invoice.data.id} } ">{{ props.item.invoice.data.pseudo_id }}</router-link></td>
                            <td>${{ props.item.invoice.data.levy_total }}</td>
                            <td>${{ props.item.invoice.data.duty_total }}</td>
                            <td>${{ props.item.invoice.data.shipping_total }}</td>
                            <td>${{ props.item.invoice.data.vat_total }}</td>
                            <td>${{ props.item.invoice.data.processing_fee }}</td>
                            <td>${{ props.item.invoice.data.value }}</td>
                            <td>{{ props.item.created_at }}</td>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    created() {
        this.getReport()
    },

    data() {
        return {
            loading: false,
            report: null,
            trans: [],
            headers: [
                {
                    text: 'Trans. #',
                    align: 'left',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: 'Trans. Type',
                    align: 'left',
                    sortable: false,
                    value: 'type'
                },
                {
                    text: 'PnP #',
                    align: 'left',
                    sortable: false,
                    value: 'pnp'
                },
                {
                    text: 'Inv. #',
                    align: 'left',
                    sortable: false,
                    value: 'invoice.data.id'
                },
                {
                    text: 'Inv. Levy',
                    align: 'left',
                    sortable: false,
                    value: 'invoice.data.levy_total'
                },
                {
                    text: 'Inv. Duty',
                    align: 'left',
                    sortable: false,
                    value: 'invoice.data.duty_total'
                },
                {
                    text: 'Inv. Shipping',
                    align: 'left',
                    sortable: false,
                    value: 'invoice.data.shipping_total'
                },
                {
                    text: 'Inv. VAT',
                    align: 'left',
                    sortable: false,
                    value: 'invoice.data.vat_total'
                },
                {
                    text: 'Inv. Processing',
                    align: 'left',
                    sortable: false,
                    value: 'invoice.data.processing_fee'
                },
                {
                    text: 'Inv. Value',
                    align: 'left',
                    sortable: false,
                    value: 'invoice.data.value'
                },
                {
                    text: 'Date',
                    align: 'left',
                    sortable: false,
                    value: 'created_at'
                },
            ]
        }
    },

    computed: {
      ...mapGetters([
        'authUser'
      ]),
      oldProcessingTotal() {
        return this.getOldProcessingTotal()
      }
    },
    
    methods: {
        ...mapActions([
            'fetchEOD'
        ]),

        getOldProcessingTotal() {
          let total = 0;
          this.trans.forEach((trans) => {
            total += trans.invoice.data.processing_fee;
          })
          return total.toFixed(2);
        },

        getReport() {
            this.loading = true;
            this.fetchEOD(this.$route.params.id)
            .then((response) => {
                this.report = response;
                this.trans = response.data;
                this.calculate();
                this.loading = false;
            })
        },
        calculate() {
            let total = 0;
            this.trans.forEach((item) => {
              total += item.invoice.data.vat_total;                
            })
        },
        cashTotal() {
            let total = 0;
            this.trans.forEach((item) => {
              if(item.type == 'cash') {
                total += item.invoice.data.value;                
              }
            })
            return total.toFixed(2)
        },
        onlineTotal() {
            let total = 0;
            this.trans.forEach((item) => {
              if(item.type == 'online') {
                total += item.invoice.data.value;                
              }
            })
            return total.toFixed(2)
        },
        bankTotal() {
            let total = 0;
            this.trans.forEach((item) => {
              if(item.type == 'bank') {
                total += item.invoice.data.value;                
              }
            })
            return total.toFixed(2)
        },
        checkTotal() {
            let total = 0;
            this.trans.forEach((item) => {
              if(item.type == 'check') {
                total += item.invoice.data.value;                
              }
            })
            return total.toFixed(2)
        },
        vatTotal() {
            let total = 0;
            this.trans.forEach((item) => {
              total += item.invoice.data.vat_total;                
            })
            return total.toFixed(2)
        },
        dutyTotal() {
            let total = 0;
            this.trans.forEach((item) => {
              total += item.invoice.data.duty_total;                
            })
            return total.toFixed(2)
        },
        shippingTotal() {
            let total = 0;
            this.trans.forEach((item) => {
              total += item.invoice.data.shipping_total;                
            })
            return total.toFixed(2)
        }
    }
}
</script>

<style lang="scss" scoped>
    .eod-table{
        margin-top: 2rem;
    }

    .total-block {
        color: white;
    }
</style>


