<template>
  <div class="flex flex-wrap -mx-4">
    <div class="w-1/3 px-4">
      <v-menu
        :close-on-content-click="false"
        v-model="menu"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="fromDate"
          label="Start Date:"
          prepend-icon="event"
          readonly
        ></v-text-field>
        <v-date-picker
          v-model="fromDate"
          @input="addFromDate()"
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="w-1/3 px-4">
      <v-menu
        :close-on-content-click="false"
        v-model="menu2"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="endDate"
          label="End Date:"
          prepend-icon="event"
          readonly
        ></v-text-field>
        <v-date-picker v-model="endDate" @input="addEndDate()"></v-date-picker>
      </v-menu>
    </div>
    <div class="w-1/3 px-4">
      <v-select
        :items="shippingMethods"
        v-model="shippingFilter"
        single-line
        bottom
        hint="Shipping Method"
        persistent-hint
        @input="applyFilters()"
      ></v-select>
    </div>
    <div class="w-1/3 px-4">
      <v-select
        :items="locations"
        v-model="locationFilter"
        single-line
        bottom
        hint="Customer Location"
        persistent-hint
        @input="applyFilters()"
      ></v-select>
    </div>
    <div class="w-1/3 px-4">
      <v-select
        :items="checkpoints"
        v-model="checkpointFilter"
        single-line
        bottom
        hint="Checkpoint status"
        persistent-hint
        item-value="id"
        item-text="name"
        @input="applyFilters()"
      ></v-select>
    </div>
    <div class="cell medium-2">
      <v-btn color="blue lighten-2" dark @click="clearFilters()">Clear</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  created() {
    this.$router.push({
      name: "orders.index",
      query: Object.assign({}, this.$route.query),
    });
    this.fetchCheckpoints();
  },
  data() {
    return {
      shippingMethods: [
        "",
        "next-day",
        "standard",
        "consolidate",
        "special",
        "business-plane",
        "business-boat",
        "industrial-plane",
        "industrial-boat",
        "consolidate-sm",
        "consolidate-lg",
      ],
      locations: ["", "Madeira", "Harbor Bay"],
      statuses: ["", "pending", "shipped", "on-route", "canceled", "delivered"],
      shippingFilter: "",
      locationFilter: "",
      statusFilter: "",
      checkpointFilter: "",
      menu: false,
      menu2: false,
      dates: [],
      endDate: this.$route.query.dates
        ? this.$route.query.dates.split(",")[1]
        : "",
      fromDate: this.$route.query.dates
        ? this.$route.query.dates.split(",")[0]
        : "",
    };
  },
  computed: {
    ...mapGetters(["checkpoints"]),
  },
  methods: {
    ...mapActions(["fetchCheckpoints"]),
    applyCountFilter() {
      this.$router.push({ query: Object.assign({}, this.$route.query) });
      this.$emit("on-filter");
    },
    applyFilters() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          dates: this.compileDates().join(","),
          method: this.shippingFilter,
          location: this.locationFilter,
          checkpoint: this.checkpointFilter,
        }),
      });
      this.$emit("on-filter");
    },
    addFromDate() {
      this.dates[0] = this.fromDate;
      this.menu = false;
      this.applyFilters();
    },
    addEndDate() {
      this.dates[1] = this.endDate;
      this.menu2 = false;
      this.applyFilters();
    },
    compileDates() {
      this.dates = this.dates.filter((e) => {
        return e != "";
      });
      return this.dates;
    },
    clearFilters() {
      this.endDate = "";
      this.fromDate = "";
      this.shippingFilter = "";
      this.locationFilter = "";
      this.checkpointFilter = "";
      this.$emit("clear-filters");
    },
  },
};
</script>

<style lang="scss" scoped></style>
