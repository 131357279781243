<template>
  <div class="grid-container fluid mt-3">
    <div class="grid-x">
      <div class="cell">
        <v-card>
          <v-card-title>
            <h1 class="display-2 grey--text">Reports</h1>
          </v-card-title>
          <v-card-text>
            <div class="grid-x">
              <div class="cell small-12 medium-3">
                <v-btn color="info" :to="{ name: 'eod.report' }">End Of Day Report</v-btn>
              </div>
              <div class="cell small-12 medium-3">
                <v-btn color="info" :to="{ name: 'reports.old' }">Old Orders</v-btn>
              </div>
              <div class="cell small-12 medium-3">
                <v-btn color="info" @click="orderReportBox = true">New and Edited Orders</v-btn>
              </div>
              <div class="cell small-12 medium-3">
                <v-btn color="info" :to="{ name: 'clients.report' }">Client Orders Report</v-btn>
              </div>
              <div class="cell small-12 medium-3">
                <v-btn color="info" :to="{ name: 'unpaid.invoices' }">Unpaid Invoices Report</v-btn>
              </div>
              <div class="cell small-12 medium-3">
                <v-btn color="info" :to="{ name: 'delivered.unpaid' }">Delivered Orders / No Invoice</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-dialog v-model="orderReportBox" persistent max-width="500" lazy>
          <v-card>
            <v-card-text>
              <div class="grid-x grid-margin-x">
                <div class="cell small-12">
                  <v-menu
                    :close-on-content-click="true"
                    v-model="menu"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      v-model="date"
                      label="Date:"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                    <v-date-picker v-model="date"></v-date-picker>
                  </v-menu>
                </div>
                <div class="cell medium-6">
                  <p>New Orders: {{ newOrders }}</p>
                </div>
                <div class="cell medium-6">
                  <p>Edited Orders: {{ editedOrders }}</p>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="red lighten-1" @click="orderReportBox = false">Cancel</v-btn>
              <v-btn dark color="green lighten-1" @click="submit">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => {
    return {
      orderReportBox: false,
      menu: false,
      date: null,
      newOrders: 0,
      editedOrders: 0,
    };
  },
  methods: {
    ...mapActions(["fetchEditedOrdersReport"]),

    submit() {
      this.fetchEditedOrdersReport(this.date).then((res) => {
        this.newOrders = res.day_orders_count;
        this.editedOrders = res.day_edited_orders;
      });
    },
  },
};
</script>

