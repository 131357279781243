<template>
  <div class="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-3">
      <v-card class="cell" v-for="(tax, index) in taxes" :key="tax.id">
          <v-card-text>
              <div class="grid-x grid-margin-x">
                  <div class="cell">
                     {{ tax.description }}
                  </div>
                  <div class="cell medium-3">
                      Duty: {{ tax.vat }}
                  </div>
                  <div class="cell medium-3">
                      Levy: {{ tax.levy }}
                  </div>
                  <div class="cell">
                      Tariff Code: {{ tax.tariff_code }}
                  </div>
              </div>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat icon color="error" @click="openRemoveBox(tax)">
                  <v-icon>fa-times</v-icon>
              </v-btn>
              <v-btn flat icon color="accent" @click="openEditBox(tax)">
                  <v-icon>fa-pencil</v-icon>
              </v-btn>
          </v-card-actions>
      </v-card>
      <v-tooltip left>
          <v-btn
        fixed
        dark
        fab
        bottom
        right
        color="primary"
        slot="activator"
        @click="openCreateBox"
    >
    <v-icon>add</v-icon>
    </v-btn>
    <span>Add Duty</span>
      </v-tooltip>
      <v-dialog v-model="createBox" persistent max-width="400" lazy>
        <v-card>
            <v-form>
            <v-card-title class="headline">Add Customs Duty</v-card-title>
            <v-card-text>
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell medium-6">
                            <v-text-field
                            label="Name"
                            name="name"
                            @input="$v.taxForm.description.$touch()"
                            @blur="$v.taxForm.description.$touch()"
                            :error-messages="descriptionErrors"
                            v-model="taxForm.description">
                            </v-text-field>
                        </div>
                        <div class="cell medium-3">
                            <v-text-field
                            label="Duty"
                            name="duty"
                            type="number"
                            @input="$v.taxForm.vat.$touch()"
                            @blur="$v.taxForm.vat.$touch()"
                            :error-messages="vatErrors"
                            v-model="taxForm.vat">
                            </v-text-field>
                        </div>
                        <div class="cell medium-3">
                            <v-text-field
                            label="Levy"
                            name="levy"
                            type="number"
                            @input="$v.taxForm.levy.$touch()"
                            @blur="$v.taxForm.levy.$touch()"
                            :error-messages="levyErrors"
                            v-model="taxForm.levy">
                            </v-text-field>
                        </div>
                        <div class="cell">
                            <v-text-field
                            label="Tariff Code"
                            name="tariff_code"
                            @input="$v.taxForm.tariff_code.$touch()"
                            @blur="$v.taxForm.tariff_code.$touch()"
                            :error-messages="tariffCodeErrors"
                            v-model="taxForm.tariff_code">
                            </v-text-field>
                        </div>
                    </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click.native="createBox = false">Cancel</v-btn>
                <v-btn color="success" flat @click.native="submit('create')" :disabled="$v.taxForm.$invalid">Submit</v-btn>
            </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="editBox" persistent max-width="400" lazy>
        <v-card>
            <v-form>
            <v-card-title class="headline">Edit Customs Duty Rate</v-card-title>
            <v-card-text>
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell medium-6">
                            <v-text-field
                            label="Name"
                            name="name"
                            @input="$v.taxForm.description.$touch()"
                            @blur="$v.taxForm.description.$touch()"
                            :error-messages="descriptionErrors"
                            v-model="taxForm.description">
                            </v-text-field>
                        </div>
                        <div class="cell medium-3">
                            <v-text-field
                            label="Duty"
                            name="duty"
                            type="number"
                            @input="$v.taxForm.vat.$touch()"
                            @blur="$v.taxForm.vat.$touch()"
                            :error-messages="vatErrors"
                            v-model="taxForm.vat">
                            </v-text-field>
                        </div>
                        <div class="cell medium-3">
                            <v-text-field
                            label="Levy"
                            name="levy"
                            type="number"
                            @input="$v.taxForm.levy.$touch()"
                            @blur="$v.taxForm.levy.$touch()"
                            :error-messages="levyErrors"
                            v-model="taxForm.levy">
                            </v-text-field>
                        </div>
                        <div class="cell">
                            <v-text-field
                            label="Tariff Code"
                            name="tariff_code"
                            @input="$v.taxForm.tariff_code.$touch()"
                            @blur="$v.taxForm.tariff_code.$touch()"
                            :error-messages="tariffCodeErrors"
                            v-model="taxForm.tariff_code">
                            </v-text-field>
                        </div>
                    </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click.native="editBox = false">Cancel</v-btn>
                <v-btn color="success" flat @click.native="submit('edit')" :disabled="$v.taxForm.$invalid">Save</v-btn>
            </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog persistent max-width="400" lazy v-model="removeBox">
        <v-card>
            <v-card-title class="headline">Delete Custom Duty Item</v-card-title>
            <v-card-text>
                Are you sure you want to delete: {{ currentTax.description }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" flat @click.native="removeBox = false">Cancel</v-btn>
                <v-btn color="success" flat @click.native="removeTax">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators'

export default {
    props: ['taxes'],

    data() {
        return {
            createBox: false,
            editBox: false,
            taxForm: Object,
            removeBox: false,
            currentTax: Object
        }
    },

    computed: {
        descriptionErrors () {
            const errors = []
            if (!this.$v.taxForm.description.$dirty) return errors
            !this.$v.taxForm.description.required && errors.push('Description is required')
            return errors
        },
        vatErrors () {
            const errors = []
            if (!this.$v.taxForm.vat.$dirty) return errors
            !this.$v.taxForm.vat.required && errors.push('Vat is required')
            return errors
        },
        levyErrors () {
            const errors = []
            if (!this.$v.taxForm.levy.$dirty) return errors
            !this.$v.taxForm.levy.required && errors.push('Levy is required')
            return errors
        },

        tariffCodeErrors () {
            const errors = []
            if (!this.$v.taxForm.tariff_code.$dirty) return errors
            !this.$v.taxForm.tariff_code.required && errors.push('Tariff Code is required')
            return errors
        }
    },

    validations: {
        taxForm: {
            description: {
                required
            },
            vat: {
                required
            },
            levy: {
                required
            },
            tariff_code: {
                required
            }
        }
    },

    methods: {
        ...mapActions([
            'createTax',
            'updateTax',
            'deleteTax'
        ]),

        openCreateBox() {
            this.taxForm = {
                description: '',
                vat: null,
                levy: null,
                tariff_code: null
            }
            this.createBox = true;
        },

        openEditBox(tax) {
            this.taxForm = {
                id: tax.id,
                description: tax.description,
                vat: tax.vat,
                levy: tax.levy,
                tariff_code: tax.tariff_code
            }
            this.editBox = true;
        },

        openRemoveBox(tax) {
            this.removeBox = true;
            this.currentTax = tax;
        },

        submit(action) {
            if(action == 'create') {
                this.addTax();
            } else{
                this.editTax();
            }
        },

        addTax() {
            this.createTax(this.taxForm)
            .then((response) => {
                this.createBox = false;
                this.$emit('refresh-taxes');
            })
        },

        editTax() {
            this.updateTax(this.taxForm)
            .then((response) => {
                this.editBox = false;
                this.$emit('refresh-taxes');
            })
        },

        removeTax() {
            this.deleteTax(this.currentTax.id)
            .then((response) => {
                this.$emit('refresh-taxes');
            })
        }
    }
}
</script>

