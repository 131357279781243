<template>
  <div class="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-3 x-large-up-4">
      <v-card class="cell" v-for="(checkpoint, index) in checkpoints" :key="checkpoint.id">
          <v-card-text>
              <div class="grid-x grid-margin-x">
                  <div class="cell medium-6">
                     {{ checkpoint.name }}
                  </div>
                  <div class="cell medium-6">
                      {{ checkpoint.email }}
                  </div>
                  <div class="cell medium-6">
                      {{ checkpoint.address }}
                  </div>
                  <div class="cell medium-6">
                      {{ checkpoint.phone }}
                  </div>
              </div>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat icon color="error" @click="openRemoveBox(checkpoint)">
                  <v-icon>fa-times</v-icon>
              </v-btn>
              <v-btn flat icon color="accent" @click="openEditBox(checkpoint)">
                  <v-icon>fa-pencil</v-icon>
              </v-btn>
          </v-card-actions>
      </v-card>
      <v-tooltip left>
          <v-btn
        fixed
        dark
        fab
        bottom
        right
        color="primary"
        slot="activator"
        @click="openCreateBox"
    >
    <v-icon>add</v-icon>
    </v-btn>
    <span>Add Checkpoint</span>
      </v-tooltip>
      <v-dialog v-model="createBox" persistent max-width="500px" lazy>
        <v-card>
            <v-form>
            <v-card-title class="headline">Add Checkpoint</v-card-title>
            <v-card-text>
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell medium-6">
                            <v-text-field
                            label="Name"
                            name="name"
                            @input="$v.checkpointForm.name.$touch()"
                            @blur="$v.checkpointForm.name.$touch()"
                            :error-messages="nameErrors"
                            v-model="checkpointForm.name">
                            </v-text-field>
                        </div>
                        <div class="cell medium-6">
                            <v-text-field
                            label="Email"
                            name="email"
                            @input="$v.checkpointForm.email.$touch()"
                            @blur="$v.checkpointForm.email.$touch()"
                            :error-messages="emailErrors"
                            v-model="checkpointForm.email">
                            </v-text-field>
                        </div>
                        <div class="cell medium-6">
                            <v-text-field
                            label="Address"
                            name="address"
                            @input="$v.checkpointForm.address.$touch()"
                            @blur="$v.checkpointForm.address.$touch()"
                            :error-messages="addressErrors"
                            v-model="checkpointForm.address">
                            </v-text-field>
                        </div>
                        <div class="cell medium-6">
                            <v-text-field
                            label="Phone"
                            name="phone"
                            @input="$v.checkpointForm.phone.$touch()"
                            @blur="$v.checkpointForm.phone.$touch()"
                            :error-messages="phoneErrors"
                            v-model="checkpointForm.phone">
                            </v-text-field>
                        </div>
                    </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click.native="createBox = false">Cancel</v-btn>
                <v-btn color="success" flat @click.native="submit('create')" :disabled="$v.checkpointForm.$invalid">Submit</v-btn>
            </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="editBox" persistent max-width="500px" lazy>
        <v-card>
            <v-form>
            <v-card-title class="headline">Edit Checkpoint</v-card-title>
            <v-card-text>
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell medium-6">
                            <v-text-field
                            label="Name"
                            name="name"
                            @input="$v.checkpointForm.name.$touch()"
                            @blur="$v.checkpointForm.name.$touch()"
                            :error-messages="nameErrors"
                            v-model="checkpointForm.name">
                            </v-text-field>
                        </div>
                        <div class="cell medium-6">
                            <v-text-field
                            label="Email"
                            name="email"
                            @input="$v.checkpointForm.email.$touch()"
                            @blur="$v.checkpointForm.email.$touch()"
                            :error-messages="emailErrors"
                            v-model="checkpointForm.email">
                            </v-text-field>
                        </div>
                        <div class="cell medium-6">
                            <v-text-field
                            label="Address"
                            name="address"
                            @input="$v.checkpointForm.address.$touch()"
                            @blur="$v.checkpointForm.address.$touch()"
                            :error-messages="addressErrors"
                            v-model="checkpointForm.address">
                            </v-text-field>
                        </div>
                        <div class="cell medium-6">
                            <v-text-field
                            label="Phone"
                            name="phone"
                            @input="$v.checkpointForm.phone.$touch()"
                            @blur="$v.checkpointForm.phone.$touch()"
                            :error-messages="phoneErrors"
                            v-model="checkpointForm.phone">
                            </v-text-field>
                        </div>
                    </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click.native="editBox = false">Cancel</v-btn>
                <v-btn color="success" flat @click.native="submit('edit')" :disabled="$v.checkpointForm.$invalid">Save</v-btn>
            </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog persistent max-width="400" lazy v-model="removeBox">
        <v-card>
            <v-card-title class="headline">Delete Checkpoint</v-card-title>
            <v-card-text>
                Are you sure you want to delete: {{ currentCheckpoint.name }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" flat @click.native="removeBox = false">Cancel</v-btn>
                <v-btn color="success" flat @click.native="removeCheckpoint">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators'

export default {
    props: ['checkpoints'],

    data() {
        return {
            createBox: false,
            editBox: false,
            checkpointForm: Object,
            removeBox: false,
            currentCheckpoint: Object
        }
    },

    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.checkpointForm.name.$dirty) return errors
            !this.$v.checkpointForm.name.required && errors.push('Name is required')
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.checkpointForm.email.$dirty) return errors
            !this.$v.checkpointForm.email.email && errors.push('Must be valid e-mail')
            !this.$v.checkpointForm.email.required && errors.push('E-mail is required')
            return errors
        },
        addressErrors () {
            const errors = []
            if (!this.$v.checkpointForm.address.$dirty) return errors
            !this.$v.checkpointForm.address.required && errors.push('Address is required')
            return errors
        },
        phoneErrors () {
            const errors = []
            if (!this.$v.checkpointForm.phone.$dirty) return errors
            !this.$v.checkpointForm.phone.required && errors.push('Phone Number is required')
            return errors
        },
    },

    validations: {
        checkpointForm: {
            name: {
                required
            },
            email: {
                required,
                email
            },
            address: {
                required
            },
            phone: {
                required
            }
        }
    },

    methods: {
        ...mapActions([
            'createCheckpoint',
            'updateCheckpoint',
            'deleteCheckpoint'
        ]),

        openCreateBox() {
            this.checkpointForm = {
                name: '',
                email: '',
                address: '',
                phone: ''
            }
            this.createBox = true;
        },

        openEditBox(checkpoint) {
            this.checkpointForm = {
                id: checkpoint.id,
                name: checkpoint.name,
                email: checkpoint.email,
                address: checkpoint.address,
                phone: checkpoint.phone
            }
            this.editBox = true;
        },

        openRemoveBox(checkpoint) {
            this.removeBox = true;
            this.currentCheckpoint = checkpoint;
        },

        submit(action) {
            if(action == 'create') {
                this.addCheckpoint();
            } else{
                this.editCheckpoint();
            }
        },

        addCheckpoint() {
            this.createCheckpoint(this.checkpointForm)
            .then((response) => {
                this.createBox = false;
                this.$emit('refresh-checkpoints');
            })
        },

        editCheckpoint() {
            this.updateCheckpoint(this.checkpointForm)
            .then((response) => {
                this.editBox = false;
                this.$emit('refresh-checkpoints');
            })
        },

        removeCheckpoint() {
            this.deleteCheckpoint(this.currentCheckpoint.id)
            .then((response) => {
                this.$emit('refresh-checkpoints');
            })
        }
    }
}
</script>

