<template>
  <table>
    <thead>
      <tr>
        <th>iD</th>
        <th>Invoice ID</th>
        <th>Has invoice?</th>
        <th>Status</th>
        <th>Customer</th>
        <th>Method</th>
        <th>Packages</th>
        <th>Package Types</th>
        <th>Created At</th>
        <th>Description</th>
        <th>Added to sheet</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(order, i) in orders" :key="i" class="order-row">
        <td>
          <router-link
            :to="{ name: 'order.detail', params: { id: order.id } }"
            >{{ order["id"] }}</router-link
          >
        </td>
        <td>{{ order["invoice_pseudo_id"] || "N/A" }}</td>
        <td>{{ order["hasInvoice"] ? "Yes" : "No" }}</td>
        <td>{{ order["status"] }}</td>
        <td>{{ order["client_fullname"] }}</td>
        <td>{{ order["delivery"] }}</td>
        <td>{{ order["# of packages"] }}</td>
        <td>{{ order["package_types"] }}</td>
        <td>{{ order["created_at"] }}</td>
        <td>{{ shortDescription(order["description"]) }}</td>
        <td>
          <input
            type="checkbox"
            name=""
            id=""
            @change="addToSheet(order.id)"
            v-model="order.add_to_sheet"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["orders"],
  methods: {
    goToOrder(id) {
      this.$router.push({ name: "order.detail", params: { id: id } });
    },
    shortDescription(description) {
      let length = 30;
      let ending = "...";

      if (description.length > length) {
        return description.substring(0, length - ending.length) + ending;
      } else {
        return description;
      }
    },
    addToSheet(id) {
      this.$store.dispatch("addToSheet", id).then((res) => {
        console.log(res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  background-color: #ffffff;
  margin: 0 auto;
  th {
    padding: 0.75rem;
  }
}
table tr:nth-child(even) {
  background-color: #ddd;
}
.order-row {
  // cursor: pointer;
  td {
    text-align: right;
    padding: 0.75rem;
    a {
      color: black;
    }
  }
}
.order-row:hover {
  background-color: #1976d2;
  color: white;
}
</style>


