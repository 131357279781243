<template>
  <v-app>
    <div class="calc-page-wrapper">
      <img
        src="../../assets/images/logo.png"
        alt="Ship 242"
        class="float-center logo"
      />
      <div class="calculator max-w-6xl mx-auto mt-10">
        <v-card>
          <v-card-title class="headline grey--text"
            >Estimate Your Ship242 Shipping Cost</v-card-title
          >
          <v-card-text>
            <div class="grid-x grid-margin-x grid-margin-y">
              <div class="cell">
                <p class="text-red-500">
                  **Disclaimer - This calculator only provides an estimated cost
                  and may or may not be an exact representation of your final
                  invoice.
                </p>
              </div>
              <div class="cell small-12 medium-6">
                <!-- <v-select
                    label="Shipping Method"
                    v-model="form.shippingMethod"
                    @input="data = null"
                    :items="methods"
                    item-value="value"
                    item-text="label"
                  ></v-select> -->
                <label>
                  <span class="block text-lg">Shipping Method</span>
                  <div class="border border-gray-500">
                    <select
                      v-model="form.orderInfo.shipping_method"
                      class="h-10 px-2 w-full"
                      @input="data = null"
                    >
                      <option
                        :value="method.value"
                        v-for="(method, i) in methods"
                        :key="i"
                        :disabled="
                          method.value === 'consolidateN' ||
                            method.value === 'consolidateS'
                        "
                      >
                        {{ method.label }}
                      </option>
                    </select>
                  </div>
                </label>
              </div>
              <!--              <div class="cell small-12 medium-6">-->
              <!--                <label>-->
              <!--                  <span class="block text-lg">Arrival in Miami</span>-->
              <!--                  <div class="border border-gray-500">-->
              <!--                    <select-->
              <!--                      v-model="form.arrival"-->
              <!--                      class="h-10 px-2 w-full"-->
              <!--                      @input="data = null"-->
              <!--                    >-->
              <!--                      <option :value="day" v-for="(day, i) in days" :key="i">-->
              <!--                        {{ day }}-->
              <!--                      </option>-->
              <!--                    </select>-->
              <!--                  </div>-->
              <!--                </label>-->
              <!--              </div>-->
              <div class="package-details cell">
                <div class="item--header cell small-12 ">
                  <h2>Package Information</h2>
                </div>
                <div class="fields grid-x grid-margin-x grid-margin-y">
                  <div class="cell small-12 medium-3">
                    <v-text-field
                      label="Weight (lbs)"
                      v-model="form.packageInfo.weight"
                      @input="data = null"
                      required
                    ></v-text-field>
                  </div>
                  <div class="cell small-12 medium-3">
                    <v-text-field
                      label="Length (in)"
                      v-model="form.packageInfo.length"
                      @input="data = null"
                      required
                    ></v-text-field>
                  </div>
                  <div class="cell small-12 medium-3">
                    <v-text-field
                      label="Width (in)"
                      v-model="form.packageInfo.width"
                      @input="data = null"
                      required
                    ></v-text-field>
                  </div>
                  <div class="cell small-12 medium-3">
                    <v-text-field
                      label="Height (in)"
                      v-model="form.packageInfo.height"
                      @input="data = null"
                      required
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="items grid-x grid-margin-x grid-margin-y">
                <div
                  class="item cell small-12 grid-x grid-margin-x grid-margin-y"
                  v-for="(item, index) in form.items"
                >
                  <div class="item--header cell small-12">
                    <h2>Item # {{ index + 1 }}</h2>
                    <v-btn
                      small
                      color="error"
                      @click.prevent="removeItem(index)"
                      v-if="form.items.length > 1"
                      class="float-right"
                    >
                      <v-icon>fa-times</v-icon>
                    </v-btn>
                  </div>
                  <div class="cell small-12 medium-4">
                    <v-select
                      label="Duty and Tariffs"
                      autocomplete
                      :loading="loadingTaxes"
                      required
                      :items="tempTaxes"
                      item-text="label"
                      item-value="id"
                      v-model="form.items[index].tax_id"
                    ></v-select>
                  </div>
                  <div class="cell small-12 medium-4">
                    <v-text-field
                      label="Price ($)"
                      v-model="form.items[index].price"
                      @input="data = null"
                      required
                    ></v-text-field>
                  </div>
                </div>
                <div class="cell small-12" v-if="form.items.length < 3">
                  <v-btn small color="success" @click.prevent="addItem">
                    <v-icon>add</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="totals mt-4" v-if="data">
              <div class="ship242-fees">
                <h2 class="headline">Ship242 Costs</h2>
                <div class="grid-x total">
                  <div class="cell">
                    <span class="text-xl">Freight Costs:</span>
                    <span class="float-right"
                      >${{ data.total_freight_charges }}</span
                    >
                  </div>
                  <div class="cell">
                    <span class="text-xl">Insurance:</span>
                    <span class="float-right">${{ data.insurance }}</span>
                  </div>
                  <div class="cell">
                    <span class="text-xl">Vat:</span>
                    <span class="float-right"
                      >${{ data.freight_vat_total }}</span
                    >
                  </div>
                  <div class="cell">
                    <span class="text-xl">Total:</span>
                    <span class="float-right"
                      >${{ data.freight_total_charges }}</span
                    >
                  </div>
                </div>
                <div class="customs-fees mt-4">
                  <h2 class="headline">Customs Costs</h2>
                  <div class="grid-x total">
                    <div class="cell">
                      <span class="text-xl">Duty:</span>
                      <span class="float-right"
                        >${{ data.total_duty_charges }}</span
                      >
                    </div>
                    <div class="cell">
                      <span class="text-xl">Customs Processing Fee:</span>
                      <span class="float-right"
                        >${{ data.processing_fee }}</span
                      >
                    </div>
                    <div class="cell">
                      <span class="text-xl">Vat:</span>
                      <span class="float-right"
                        >${{ data.duty_vat_total }}</span
                      >
                    </div>
                    <div class="cell">
                      <span class="text-xl">Total:</span>
                      <span class="float-right"
                        >${{ data.customs_total_charges }}</span
                      >
                    </div>
                  </div>
                  <div class="cell mt-4">
                    <span class="headline">Grand Total:</span>
                    <span class="float-right">${{ data.grand_total }}</span>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="calculate"
              color="primary"
              :disabled="$v.form.$invalid"
              >Calculate</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength, minValue } from "vuelidate/lib/validators";

export default {
  created() {
    this.getTaxes();
  },
  data() {
    return {
      modal: true,
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      methods: [
        {
          value: "standard",
          label: "Standard",
        },
        {
          value: "next-day",
          label: "Priority",
        },
        {
          value: "consolidation",
          label: "Consolidation",
        },
        // {
        //   value: "code-red",
        //   label: "Code Red",
        // },
        {
          value: "consolidate-sm",
          label: "Consolidation (Small - 10 packages)",
        },
        {
          value: "consolidate-lg",
          label: "Consolidation (Large - 20 packages)",
        },
        // {
        //   value: "consolidateN",
        //   label: "Consolidate (next-day) - NOT CURRENTLY AVAILABLE",
        // },
        // {
        //   value: "consolidateS",
        //   label: "Consolidate (standard) - NOT CURRENTLY AVAILABLE",
        // },
        {
          value: "special",
          label: "Special",
        },
      ],
      form: {
        orderInfo: {
          arrival: "",
          shipping_method: "standard",
        },
        packageInfo: {
          length: "0",
          width: "0",
          height: "0",
          weight: "0",
        },
        items: [
          {
            tax_id: "",
            price: "",
          },
        ],
      },
      data: null,
      loadingTaxes: false,
      tempTaxes: [],
    };
  },

  validations: {
    form: {
      orderInfo: {
        // arrival: {
        //   required,
        // },
        shipping_method: {
          required,
        },
      },
      packageInfo: {
        length: {
          required,
          minValue: minValue(0),
        },
        width: {
          required,
          minValue: minValue(0),
        },
        height: {
          required,
          minValue: minValue(0),
        },
        weight: {
          required,
          minValue: minValue(0),
        },
      },
      items: {
        required,
        minLength: minLength(1),
        $each: {
          tax_id: {
            required,
          },
          price: {
            required,
            minValue: minValue(0),
          },
        },
      },
    },
  },

  computed: {
    ...mapGetters(["taxes"]),
  },

  watch: {
    searchTaxString(val) {
      val && this.queryTaxes(val);
    },
  },

  methods: {
    ...mapActions(["calculateShippingEstimate", "fetchTaxes"]),

    calculate() {
      this.calculateShippingEstimate(this.form).then((response) => {
        this.data = response;
      });
    },
    getTaxes(val) {
      this.loadingTaxes = true;
      this.fetchTaxes().then((response) => {
        this.tempTaxes = this.taxes;
        this.loadingTaxes = false;
      });
    },
    addItem() {
      this.form.items.push({
        tax_id: "",
        price: "",
      });
    },
    removeItem(index) {
      this.form.items.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.calc-page-wrapper {
  height: 100vh;
  background-color: #fff;
}
.logo {
  margin-top: 100px;
}
</style>
