<template>
  <v-card>
      <v-card-title>
          <div class="headline grey--text">
              Create a Ship242 Account
          </div>
      </v-card-title>
      <v-card-text>
          <div class="grid-x grid-margin-y grid-margin-x">
              <div class="cell medium-6">
                  <v-text-field
                  label="First Name"
                  required
                  name="fname"
                  @input="$v.form.first_name.$touch()"
                  @blur="$v.form.first_name.$touch()"
                  :error-messages="firstNameErrors"
                  v-model="form.first_name">
                  </v-text-field>
              </div>
              <div class="cell medium-6">
                  <v-text-field
                  label="Last Name"
                  required
                  name="last"
                  @input="$v.form.last_name.$touch()"
                  @blur="$v.form.last_name.$touch()"
                  :error-messages="lastNameErrors"
                  v-model="form.last_name">
                  </v-text-field>
              </div>
              <div class="cell medium-6">
                  <v-text-field
                  label="Email"
                  required
                  name="email"
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                  :error-messages="emailErrors"
                  v-model="form.email">
                  </v-text-field>
              </div>
              <div class="cell medium-6">
                  <v-text-field
                  label="Password"
                  required
                  name="password"
                  type="password"
                  @input="$v.form.password.$touch()"
                  @blur="$v.form.password.$touch()"
                  :error-messages="passwordErrors"
                  v-model="form.password">
                  </v-text-field>
              </div>
              <div class="cell medium-6">
                  <v-text-field
                  label="Home Phone"
                  name="home_phone"
                  v-model="form.home_phone">
                  </v-text-field>
              </div>
              <div class="cell medium-6">
                  <v-text-field
                  label="Mobile Phone"
                  name="mobile_phone"
                  v-model="form.mobile_phone">
                  </v-text-field>
              </div>
              <div class="cell medium-6">
                  <v-select
                  label="Account Type"
                  required
                  :items="accountTypes"
                  name="type"
                  v-model="form.account_type">
                  </v-select>
              </div>
              <div class="cell medium-6">
                  <v-select
                  label="Island"
                  required
                  :items="islands"
                  item-text="description"
                  item-value="id"
                  name="island"
                  v-model="form.island">
                  </v-select>
              </div>
              <div class="cell medium-6">
                  <v-select
                  label="Preferred Pickup Location"
                  required
                  :items="locations"
                  item-text="name"
                  item-value="id"
                  name="location"
                  v-model="form.location">
                  </v-select>
              </div>
              <div class="cell medium-6" v-if="form.account_type == 'business'">
                  <v-text-field
                  label="TIN Number (optional)"
                  name="tin"
                  v-model="form.tin">
                  </v-text-field>
              </div>
          </div>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="register" :disabled="$v.form.$invalid">
              Create Account
          </v-btn>
      </v-card-actions>
  </v-card>
    
</template>

<script>
import { required, email, numeric } from 'vuelidate/lib/validators';
export default {
    props: ['islands', 'locations'],

    data() {
        return {
            form: {
                email: '',
                password: '',
                first_name: '',
                last_name: '',
                home_phone: '',
                mobile_phone: '',
                account_type: 'personal',
                tin: '',
                island: 10,
                location: 2
            },
            accountTypes: [
                'personal',
                'business'
            ]
        }
    },
    
    computed: {
        firstNameErrors () {
            const errors = []
            if (!this.$v.form.first_name.$dirty) return errors
            !this.$v.form.first_name.required && errors.push('First Name is required')
            return errors
        },
        lastNameErrors () {
            const errors = []
            if (!this.$v.form.last_name.$dirty) return errors
            !this.$v.form.last_name.required && errors.push('Last Name is required')
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.form.email.$dirty) return errors
            !this.$v.form.email.email && errors.push('Must be valid e-mail')
            !this.$v.form.email.required && errors.push('E-mail is required')
            return errors
        },
        passwordErrors () {
            const errors = []
            if (!this.$v.form.password.$dirty) return errors
            !this.$v.form.password.required && errors.push('Password is required')
            return errors
        },
    },

    validations: {
        form: {
            email: {
                required,
                email
            },
            password: {
                required
            },
            first_name: {
                required
            },
            last_name: {
                required
            },
            account_type: {
                required
            },
            location: {
                required
            },
            island: {
                required
            }
        }
    },

    methods: {
        register() {
            this.$emit('register-user', this.form);
        }
    }
}
</script>

<style lang="scss" scoped>
    .card{
        background-color: $white;
    }
</style>


