import { taxService } from '../../../services';

export default {
    fetchTaxes ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            taxService.fetchTaxes()
            .then((response) => {
                commit('FETCH_TAXES', response.data.data);                
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    createTax ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            taxService.createTax(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    updateTax ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            taxService.updateTax(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    deleteTax ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            taxService.deleteTax(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    }
}