<template>
    <v-card>
        <v-card-title>
            Add CC Payment
        </v-card-title>
        <v-card-text>
            <div class="grid-x">
                <div class="cell">
                    <v-form>
                        <v-text-field
                        label="First Name"
                        required
                        @input="$v.form.firstName.$touch()"
                        @blur="$v.form.firstName.$touch()"
                        :error-messages="firstNameErrors"
                        v-model="form.firstName"
                        >
                        </v-text-field>
                        <v-text-field
                        label="Last Name"
                        required
                        @input="$v.form.lastName.$touch()"
                        @blur="$v.form.lastName.$touch()"
                        :error-messages="lastNameErrors"
                        v-model="form.lastName"
                        >
                        </v-text-field>
                        <v-text-field
                        label="Vendor"
                        required
                        @input="$v.form.vendor.$touch()"
                        @blur="$v.form.vendor.$touch()"
                        :error-messages="vendorErrors"
                        v-model="form.vendor"
                        >
                        </v-text-field>
                        <v-text-field
                        label="Description"
                        required
                        @input="$v.form.description.$touch()"
                        @blur="$v.form.description.$touch()"
                        :error-messages="descriptionErrors"
                        v-model="form.description"
                        >
                        </v-text-field>
                        <v-text-field
                        label="Cost"
                        required
                        @input="$v.form.value.$touch()"
                        @blur="$v.form.value.$touch()"
                        :error-messages="valueErrors"
                        v-model="form.value"
                        >
                        </v-text-field>
                    </v-form>
                </div>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" flat dark @click="closeModal">
                Cancel
            </v-btn>
            <v-btn color="green lighten-1" flat @click="submit" :disabled="$v.form.$invalid">
                Submit
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            form: {
                firstName: '',
                lastName: '',
                description: '',
                vendor: '',
                value: ''
            }
        }
    },

    computed: {
        firstNameErrors () {
        const errors = []
        if (!this.$v.form.firstName.$dirty) return errors
        !this.$v.form.firstName.required && errors.push('First Name is required')
        return errors
      },
      lastNameErrors () {
        const errors = []
        if (!this.$v.form.lastName.$dirty) return errors
        !this.$v.form.lastName.required && errors.push('Last Name is required')
        return errors
      },
      descriptionErrors () {
        const errors = []
        if (!this.$v.form.description.$dirty) return errors
        !this.$v.form.description.required && errors.push('Description is required')
        return errors
      },
      vendorErrors () {
        const errors = []
        if (!this.$v.form.vendor.$dirty) return errors
        !this.$v.form.vendor.required && errors.push('Vendor is required')
        return errors
      },
      valueErrors () {
        const errors = []
        if (!this.$v.form.value.$dirty) return errors
        !this.$v.form.value.required && errors.push('Cost is required')
        return errors
      }
    },

    validations: {
        form: {
            firstName: {
                required
            },
            lastName: {
                required
            },
            vendor: {
                required
            },
            description: {
                required
            },
            value: {
                required
            }
        }
    },

    methods: {
        ...mapActions([
            'makeCCPayment'
        ]),

        closeModal() {
            this.$emit('close-modal');
        },

        submit() {
            this.makeCCPayment(this.form)
            .then((response) => {
                this.$emit('cc-payment-made');
                this.openReceipt(response.id);
                this.closeModal();
            })
        },

        openReceipt(id) {
            let route = this.$router.resolve({ name: 'credits.show', params: {id: id} });
            window.open(route.href, '_blank');
        }
    }
}
</script>

