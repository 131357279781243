<template>
  <table>
    <thead>
    <tr>
      <th>Order ID</th>
      <th>Customer</th>
      <th>Method</th>
      <th>Package Type</th>
      <th>Checkpoint</th>
      <th># of items</th>
      <th>Description</th>
      <th>Created At</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(pkg, i) in packages" :key="i" class="order-row">
      <td>
        <router-link
            :to="{ name: 'order.detail', params: { id: pkg.order_id } }"
        >{{ pkg['order_id'] }}</router-link
        >
      </td>
      <td>{{ pkg["customer"] }}</td>
      <td>{{ pkg["shipping_method"] }}</td>
      <td>{{ pkg["type"] }}</td>
      <td>{{ shortDescription(pkg["checkpoint"]) }}</td>
      <td>{{ pkg["number_of_items"] }}</td>
      <td>{{ shortDescription(pkg["description"]) }}</td>
      <td>{{ pkg["created_at"] }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["packages"],
  methods: {
    goToOrder(id) {
      this.$router.push({ name: "order.detail", params: { id: id } });
    },
    shortDescription(description) {
      let length = 30;
      let ending = "...";

      if (description.length > length) {
        return description.substring(0, length - ending.length) + ending;
      } else {
        return description;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  background-color: #ffffff;
  margin: 0 auto;
  th {
    padding: 0.75rem;
  }
}
table tr:nth-child(even) {
  background-color: #ddd;
}
.order-row {
  // cursor: pointer;
  td {
    text-align: right;
    padding: 0.75rem;
    a {
      color: black;
    }
  }
}
.order-row:hover {
  background-color: #1976d2;
  color: white;
}
</style>


