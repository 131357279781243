<template>
    <div class="grid-container fluid mt-3">
        <div class="grid-x">
            <div class="cell">
                <v-card>
                    <span class="float-right">
                            <v-btn color="primary" @click.prevent="newWorkerDialog = true">
                                Create New Worker
                            </v-btn>
                        </span>
                    <v-card-title>
                        <h1 class="display-2 grey--text">
                            Workers
                        </h1>
                    </v-card-title>
                    <v-card-text>
                        <div class="grid-x grid-margin-y">
                            <div class="cell medium-4">
                                <v-text-field
                                    v-model="search"
                                    label="Search Workers"
                                    name="search"
                                    @input="filterResults(search)">
                                </v-text-field>
                            </div>
                            <div class="cell">
                                <WorkersGrid :workers="tempWorkers" v-if="!loadingWorkers" @load-worker="loadWorkerModal"/>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
        <v-dialog v-model="newWorkerDialog" max-width="750">
            <NewWorkerForm :islands="islands" :locations="locations" @add-worker="register"/>
        </v-dialog>
        <v-dialog v-model="editWorkerDialog" max-width="750">
            <v-card>
                <v-card-text>
                    <UserProfile :user="loadedWorker" :locations="locations" @update-user="updateWorkerData" v-if="workerLoaded"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NewWorkerForm from '../../components/admin/NewWorkerForm.vue';
import WorkersGrid from '../../components/admin/WorkersGrid.vue';
import UserProfile from '../../components/client/UserProfile.vue';

export default {
    created() {
        this.getLocations();
        this.getIslands();
        this.getWorkers();
    },

    components: {
        WorkersGrid,
        UserProfile,
        NewWorkerForm
    },

    data() {
        return {
            islands: [],
            locations: [],
            tempWorkers: [],
            search: '',
            loadingWorkers: false,
            success: false,
            newWorkerDialog: false,
            editWorkerDialog: false,
            workerLoaded: false,
            loadedWorker: null,
            workers: []
        }
    },

    computed: {
        // ...mapGetters([
        //     'customers'
        // ])
    },
    
    methods: {
        ...mapActions([
            'fetchLocations',
            'fetchIslands',
            'fetchWorkers',
            'fetchUserProfile',
            'createWorker',
            'editUserProfile'
        ]),

        getWorkers() {
            this.loadingWorkers = true;
            this.fetchWorkers()
            .then((response) => {
                this.workers = response.data;
                this.tempWorkers = this.workers.slice(0, 50);
                this.loadingWorkers = false;
            })
        },

        getLocations() {
            this.fetchLocations()
            .then((response) => {
                this.locations = response.data;
            })
        },

        getIslands() {
            this.fetchIslands()
            .then((response) => {
                this.islands = response.data;
            })
        },

        register(data) {
            this.createWorker(data)
            .then((response) => {
                this.getWorkers();
                this.newWorkerDialog = false;
            })
        },

        loadWorkerModal(user) {
            this.workerLoaded = false;
            this.fetchUserProfile(user)
                .then((response) => {
                    this.loadedWorker = response.data;
                    this.workerLoaded = true;
                    this.editWorkerDialog = true;
                })
        },

        updateWorkerData(data) {
            let payload = {
                user: this.loadedWorker.id,
                data: data
            }

            this.editUserProfile(payload)
            .then((response) => {
                this.getWorkers();
                this.editWorkerDialog = false;
            })
        },

        filterResults(search) {

            let val = search.toLowerCase();

            let temp = this.workers.filter(function( worker ) {

                let name = worker.full_name.toLowerCase().indexOf(val) !== -1;

                let email = worker.email.toLowerCase().indexOf(val) !== -1

                return name || email || !val;

            });

            let results = temp.slice(0, 50);
            this.tempWorkers = results;
            
        }
    }
}
</script>

