<template>
  <div class="invoice-action-buttons">
    <v-speed-dial
      large
      bottom
      right
      direction="top"
      transition="slide-y-reverse-transition"
      v-model="fab"
      fixed
    >
      <v-btn slot="activator" color="blue darken-2" dark fab hover v-model="fab">
        <v-icon>fa-list</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
      <v-tooltip left v-if="showApproveButton">
        <v-btn fab dark color="indigo" slot="activator" @click="approveInvoice">
          <v-icon>fa-thumbs-up</v-icon>
        </v-btn>
        <span>Approve Invoice</span>
      </v-tooltip>
      <v-tooltip left v-if="showUnapproveButton">
        <v-btn fab dark color="error" slot="activator" @click="unapproveInvoice">
          <v-icon>fa-thumbs-down</v-icon>
        </v-btn>
        <span>Unapprove Invoice</span>
      </v-tooltip>
      <v-tooltip left v-if="showPayButton">
        <v-btn fab dark color="success" slot="activator" @click="payInvoice">
          <v-icon>fa-usd</v-icon>
        </v-btn>
        <span>Pay Invoice</span>
      </v-tooltip>
      <v-tooltip left v-if="showCartButton">
        <v-btn fab dark color="secondary" slot="activator" @click="addToCart">
          <v-icon>fa-cart-plus</v-icon>
        </v-btn>
        <span>Add To Cart</span>
      </v-tooltip>
      <v-tooltip left v-if="showFeesButton">
        <v-btn fab dark color="light-green" slot="activator" @click="manageFees">
          <v-icon>fa-money</v-icon>
        </v-btn>
        <span>Manage Fees</span>
      </v-tooltip>
      <v-tooltip left>
        <v-btn fab dark color="warning" slot="activator" @click="printInvoice">
          <v-icon>fa-print</v-icon>
        </v-btn>
        <span>Print Invoice</span>
      </v-tooltip>
    </v-speed-dial>

    <v-dialog v-model="feeBox" persistent width="600" lazy>
      <v-card>
        <v-card-title class="headline">Manage Invoice Fees</v-card-title>
        <v-card-text>
          <div class="grid-x">
            <div class="cell">
              <v-btn color="green" icon @click="addFee">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="fees">
            <v-form>
              <div
                class="grid-x grid-margin-y grid-margin-x fee"
                v-for="(fee, index) in feeForm.fees"
                :key="index"
              >
                <div class="cell medium-7">
                  <v-text-field
                    name="name"
                    label="Name"
                    required
                    v-model="feeForm.fees[index].name"
                  ></v-text-field>
                </div>
                <div class="cell medium-3">
                  <v-text-field
                    name="amount"
                    label="Amount"
                    required
                    type="number"
                    v-model="feeForm.fees[index].amount"
                  ></v-text-field>
                </div>
                <div class="cell medium-2">
                  <v-btn color="error" icon small @click="deleteFee">
                    <v-icon>fa-times</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" flat @click.native="feeBox = false">Cancel</v-btn>
          <v-btn color="success" flat @click.native="submit" :disabled="$v.feeForm.$invalid">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="payBox" persistent width="400" lazy>
      <v-card>
        <v-card-title class="headline">Pay Invoice #{{ invoice.id }}</v-card-title>
        <v-card-text>
          <v-form>
            <v-select :items="paymentTypes" label="Pay By:" required v-model="payForm.type"></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" flat @click.native="payBox = false">Cancel</v-btn>
          <v-btn
            color="success"
            flat
            @click.native="submitPayment"
            :disabled="$v.payForm.$invalid || submitting"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength, numeric } from "vuelidate/lib/validators";

export default {
  props: ["invoice"],
  data() {
    return {
      fab: false,
      feeBox: false,
      payBox: false,
      invoiceFees: [],
      submitting: false,
      feeForm: {
        fees: []
      },
      payForm: {
        type: ""
      },
      paymentTypes: ["cash", "check", "card"]
    };
  },

  validations: {
    feeForm: {
      fees: {
        $each: {
          name: {
            required
          },
          amount: {
            required
          }
        }
      }
    },

    payForm: {
      type: {
        required
      }
    }
  },

  computed: {
    ...mapGetters(["authUser", "cartItems"]),

    addedInvoices() {
      let added = [];
      this.cartItems.forEach(item => {
        added.push(item.id);
      });
      return added;
    },

    isAdmin() {
      if (this.authUser.account_type == "admin") {
        return true;
      }
    },

    isStaff() {
      if (
        this.authUser.account_type == "admin" ||
        this.authUser.account_type == "worker"
      ) {
        return true;
      }
    },

    isSabrina() {
      return this.authUser.id == 5387 || this.authUser.id == 1243
        ? true
        : false;
    },

    showCartButton() {
      if (
        this.invoice.status == "approved" &&
        !this.addedInvoices.includes(this.$route.params.id)
      ) {
        return true;
      }
    },

    showApproveButton() {
      if (this.isStaff && this.invoice.status == "unpaid") {
        return true;
      }
    },

    showUnapproveButton() {
      if (this.isStaff && this.invoice.status == "approved") {
        return true;
      }
    },

    showPayButton() {
      if (this.isStaff && this.invoice.status == "approved") {
        return true;
      }
    },

    showFeesButton() {
      if (this.invoice.status == "unpaid" && this.isSabrina) {
        return true;
      }
    }
  },

  methods: {
    ...mapActions([
      "approveOrderInvoice",
      "unapproveOrderInvoice",
      "updateInvoiceFees",
      "makeInvoicePayment",
      "openCart",
      "addInvoiceToCart",
      "fetchUserCart"
    ]),

    approveInvoice() {
      let payload = {
        invoice: this.$route.params.id,
        worker: this.authUser.id
      };

      this.approveOrderInvoice(payload).then(response => {
        this.$emit("refresh-invoice");
      });
    },

    unapproveInvoice() {
      let payload = {
        invoice: this.$route.params.id,
        worker: this.authUser.id
      };

      this.unapproveOrderInvoice(payload).then(response => {
        this.$emit("refresh-invoice");
        this.fetchUserCart(this.authUser.id);
      });
    },

    addToCart() {
      let payload = {
        invoice: this.$route.params.id,
        user: this.authUser.id
      };

      this.addInvoiceToCart(payload).then(response => {
        this.fetchUserCart(this.authUser.id).then(response => {
          this.openCart();
        });
      });
    },

    payInvoice() {
      if (this.isAdmin) {
        this.paymentTypes.push("bank");
        this.paymentTypes.push("online");
      }
      this.payBox = true;
    },

    printInvoice() {
      window.print();
    },

    manageFees() {
      this.feeBox = true;
      this.invoiceFees = this.invoice["fees"]["data"];
      this.setFees();
    },

    setFees() {
      this.feeForm.fees = [];
      this.invoiceFees.forEach(fee => {
        this.feeForm.fees.push(fee);
      });
    },

    addFee() {
      let feeGroup = {
        name: "",
        amount: ""
      };

      this.feeForm.fees.push(feeGroup);
    },

    deleteFee(index) {
      this.feeForm.fees.splice(index, 1);
    },

    submit() {
      let payload = {
        fees: this.feeForm.fees,
        id: this.invoice.id
      };

      this.updateInvoiceFees(payload).then(response => {
        this.feeBox = false;
        this.$emit("refresh-invoice");
      });
    },

    submitPayment() {
      this.submitting = true;
      let payload = {
        id: this.invoice.id,
        type: this.payForm.type
      };

      this.makeInvoicePayment(payload).then(response => {
        this.payBox = false;
        this.$emit("refresh-invoice");
      });
    }
  }
};
</script>
