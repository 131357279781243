<template>
  <div class="grid-x grid-margin-y grid-margin-x small-up-1 medium-up-3">
    <div class="cell" v-for="(customer, index) in customers" :key="index">
      <v-card>
        <v-alert :value="customer.flagged" type="error"
          >This user has been flagged for possible invoice fraud.</v-alert
        >
        <v-card-title>
          <span class="headline">{{ customer.full_name }}</span>
        </v-card-title>
        <v-card-text>
          <div class="grid-x grid-margin-x grid-margin-y">
            <div class="cell small-6">Dept #{{ customer.id }}</div>
            <div class="cell small-6">{{ customer.email }}</div>
            <div class="cell">Started: {{ customer.created_at }}</div>
            <p class="mt-2">
              {{ customer.notes }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="warning" @click="editCustomer(customer)"
            >Edit</v-btn
          >
          <v-btn flat color="success" @click="grabOrders(customer)"
            >Orders</v-btn
          >
          <v-btn
            flat
            color="error"
            @click="flag(customer)"
            v-if="customer.flagged === 0"
            >Flag</v-btn
          >
          <v-btn flat color="error" @click="flag(customer)" v-else
            >Unflag</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["customers"],

  methods: {
    editCustomer(customer) {
      this.$emit("load-customer", customer.id);
    },
    grabOrders(customer) {
      this.$emit("get-orders", customer.id);
    },
    flag(customer) {
      this.$emit("flag-customer", customer.id);
    },
  },
};
</script>

<style>
</style>


