export default {
    'SET_AUTH_USER' (state, data) {
        let user = JSON.stringify(data.user);
        let token = data.token; 

        localStorage.setItem('ship_user', user);
        localStorage.setItem('ship_token', token);
        
        state.authUser = user;
        state.token = token;
    },

    'REMOVE_AUTH_USER' (state, data) {
        localStorage.removeItem('ship_user');
        localStorage.removeItem('ship_token');

        state.authUser = null;
        state.token = null;
    }
}