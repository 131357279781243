<template>
  <div class="grid-container fluid mt-3">
      <OrderForm />
  </div>
</template>

<script>
import OrderForm from '../../components/orders/OrderForm.vue';

export default {
    components: {
        OrderForm
    }
}
</script>

