<template>
	<v-app id="layout">
		<v-navigation-drawer
			clipped
			persistent
			disable-route-watcher
			v-model="drawer"
			enable-resize-watcher
			app
			width="220"
		>
			<v-list dense>
				<v-list-tile
					v-for="(route, index) in routes"
					:key="index"
					@click="routeTo(route.route)"
					v-if="hasAccess(route.access)"
				>
					<v-list-tile-action>
						<v-icon>{{ route.icon }}</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>{{ route.title }}</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list>
		</v-navigation-drawer>
		<v-toolbar app fixed clipped-left>
			<v-icon @click.stop="drawer = !drawer">fa-bars</v-icon>
			<v-toolbar-title>
				<img src="../assets/images/logo.png" alt class="logo" />
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<div class="greeting">
				<span class="user-name">Hello, {{ authUser.first_name }}</span>
				<v-tooltip bottom>
					<v-icon color="grey lighten-1" large slot="activator" @click="goToProfile"
						>fa-user-circle-o</v-icon
					>
					<span>My Account</span>
				</v-tooltip>
				<v-tooltip bottom>
					<v-icon color="grey lighten-1" @click="logoutUser" large slot="activator"
						>fa-sign-out</v-icon
					>
					<span>Logout</span>
				</v-tooltip>
				<span class="cart-section">
					<v-badge right>
						<span slot="badge">{{ cartItems.length }}</span>
						<v-icon large color="grey lighten-1" @click="openCart">fa-shopping-cart</v-icon>
					</v-badge>
					<Cart
						class="cart"
						v-if="cartVisible"
						:invoices="cartItems"
						:total="cartTotal"
						@close-cart="closeCart"
						@remove-invoice="removeInvoice"
					/>
				</span>
			</div>
		</v-toolbar>
		<main>
			<v-content>
        <div v-if="statsAlertBox && alertStats.outstanding_deliveries" class="delivery-alert-box" style="background-color: #FFCDD2; padding: 12px; font-size: 1.2rem;">
          <p>There are a total of {{ alertStats.outstanding_deliveries }} deliveries outstanding. <router-link :to="{name: 'deliveries.index'}">View all</router-link></p>
        </div>
				<router-view></router-view>
			</v-content>
		</main>
		<v-footer app fixed>
			<span>&copy; 2017</span>
		</v-footer>
		<v-dialog v-model="sessionBox" persistent max-width="500" lazy>
			<v-card>
				<v-card-title class="headline">Session Expired</v-card-title>
				<v-card-text>
					<p>Your session has expired. Please login to continue.</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn flat color="success" :to="{ name: 'login', query: { redirect: $route.path } }"
						>Login</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <v-dialog v-model="statsAlertBox" max-width="500" lazy>
      <v-card>
        <v-card-title class="headline">Stats on packages</v-card-title>
        <v-card-text>
          <ul style="padding-left: 0px;">
            <li>
              <p>"On the way" longer than two (2) days: {{ alertStats.on_the_way_2_days }} <router-link :to="{name: 'packages.index', query: {checkpoint: 17}}">View all</router-link></p>
            </li>
            <li>
              <p>Next-Day packages,  "In Miami" longer than two (2) days: {{ alertStats.next_day_in_miami_2_days }} <router-link :to="{name: 'packages.index', query: {checkpoint: 12, method: 'next-day'}}">View all</router-link></p>
            </li>
            <li>
              <p>Non Next-Day packages,  "In Miami" longer than two (4) days: {{ alertStats.others_in_miami_4_days }} <router-link :to="{name: 'packages.index', query: {checkpoint: 12}}">View all</router-link></p>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
	</v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Cart from "../components/cart/Cart.vue";

export default {
	components: {
		Cart,
	},
	created() {
		this.getCart();
    this.getStats();
	},
	onIdle() {
		if (this.authUser.account_type == "worker") {
			this.sessionTimeout();
		}
	},
	data() {
		return {
			drawer: true,
			sessionBox: false,
      statsAlertBox: false,
      alertStats: null,
			routes: [
				// {
				//   icon: 'dashboard',
				//   title: 'Dashboard',
				//   route: 'admin.dashboard',
				//   access: ['worker', 'admin']
				// },
				{
					icon: "dashboard",
					title: "Dashboard",
					route: "client.dashboard",
					access: ["personal", "business"],
				},
        {
          icon: "store",
          title: "Packages In",
          route: "packages.in",
          access: ["worker", "admin"],
        },
				{
					icon: "store",
					title: "Orders",
					route: "orders.index",
					access: ["worker", "admin", "basic"],
				},
        {
          icon: "store",
          title: "Packages",
          route: "packages.index",
          access: ["admin"],
        },
				// {
				//   icon: 'fa-plane',
				//   title: 'US Pickups',
				//   route: 'pickups',
				//   access: ['worker', 'admin']
				// },
				{
					icon: "fa-ship",
					title: "Shipments",
					route: "shipments.index",
					access: ["worker", "admin"],
				},
        {
          icon: "fa-car",
          title: "Deliveries",
          route: "deliveries.index",
          access: ["worker", "admin"],
        },
				{
					icon: "fa-line-chart",
					title: "Reports",
					route: "reports.index",
					access: ["admin"],
				},
				{
					icon: "fa-users",
					title: "Customers",
					route: "customers.index",
					access: ["worker", "admin"],
				},
				{
					icon: "fa-briefcase",
					title: "Workers",
					route: "workers.index",
					access: ["admin"],
				},
				{
					icon: "fa-balance-scale",
					title: "Duty & Tarrifs",
					route: "taxes",
					access: ["worker", "admin", "basic"],
				},
				{
					icon: "fa-map-marker",
					title: "Checkpoints",
					route: "checkpoints",
					access: ["admin"],
				},
				{
					icon: "fa-qrcode",
					title: "Scan Packages",
					route: "packages.scan",
					access: ["admin", "worker"],
				},
				{
					icon: "fa-cc-visa",
					title: "CC Payments",
					route: "credits",
					access: ["admin", "worker"],
				},
				{
					icon: "fa-pencil-square-o",
					title: "Activity Logs",
					route: "admin.logs",
					access: ["admin"],
				},
				{
					icon: "fa-exclamation-triangle",
					title: "Incident Report",
					route: "admin.incident",
					access: ["admin"],
				},
				{
					icon: "fa-line-chart",
					title: "The EOD",
					route: "acc.report",
					access: ["admin", "accounts", "basic"],
				},
        // {
        //   icon: "fa-line-chart",
        //   title: "End Of Day Reports",
        //   route: "eod.report",
        //   access: ["basic"],
        // },
				{
					icon: "fa-building",
					title: "Offices",
					route: "offices.index",
					access: ["admin"],
				},
				// {
				//   icon: "shopping_basket",
				//   title: "Orders For Sale",
				//   route: "retail.index",
				//   access: ["personal", "business"]
				// }
			],
		};
	},
	computed: {
		...mapGetters(["authUser", "cartVisible", "cartItems", "cartTotal"]),

		isAdmin() {
			if (this.authUser.account_type == "admin") {
				return true;
			}
		},

		isStaff() {
			if (this.authUser.account_type == "admin" || this.authUser.account_type == "worker") {
				return true;
			}
		},
		isSabrina() {
			return this.authUser.id == 5387 || this.authUser.id == 1243 ? true : false;
		},
	},
	methods: {
		...mapActions(["fetchUserCart", "logout", "openCart", "closeCart", "removeInvoiceFromCart", "fetchAlertsStats"]),

		routeTo(route) {
			this.$router.push({ name: route });
		},

		sessionTimeout() {
			this.sessionBox = true;
		},

		hasAccess(access) {
			if (access.includes(this.authUser.account_type)) {
				return true;
			}
			return false;
		},

		goToProfile() {
			this.$router.push({ name: "profile" });
		},

		logoutUser() {
			this.logout();
			this.$router.push({ name: "login" });
		},

    getStats() {
      this.fetchAlertsStats().then((response) => {
        this.alertStats = response

        if(this.isSabrina) {
          setTimeout(() => {
              this.statsAlertBox = true;
          }, 2500);
        }
      });
    },

		getCart() {
			this.fetchUserCart(this.authUser.id);
		},

		removeInvoice(invoice) {
			let payload = {
				invoice: invoice,
				user: this.authUser.id,
			};

			this.removeInvoiceFromCart(payload).then((response) => {
				this.getCart();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.logo {
	width: 50%;
	height: auto;
}
.user-name {
	margin-right: 10px;
}
.greeting {
	margin-right: 2rem !important;
	position: relative;
}
.greeting .fa {
	font-size: 2rem;
	margin-left: 10px;
	cursor: pointer;
}
.cart-section {
	position: relative;
}
.cart {
	position: absolute;
	width: 300px;
	right: 0px;
}
</style>
