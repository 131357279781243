<template>
  <div>
    <v-text-field
        single-line
        append-icon="search"
        label="Search by customer name, order id or package barcode number"
        v-model="searchString"
        @keyup="applySearchQuery"
    ></v-text-field>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  data() {
    return {
      searchString: this.$route.query.search || ''
    }
  },
  methods: {
    applySearchQuery: debounce(function () {
      this.runSearch();
    }, 500),

    runSearch() {
      this.$router.push({ query: Object.assign({}, this.$route.query, { search: this.searchString }) });
      this.$emit('on-search');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>



