import { checkpointService } from '../../../services';

export default {
    fetchCheckpoints ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            checkpointService.fetchCheckpoints()
            .then((response) => {
                commit('FETCH_CHECKPOINTS', response.data.data);                
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    createCheckpoint ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            checkpointService.createCheckpoint(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    updateCheckpoint ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            checkpointService.updateCheckpoint(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    deleteCheckpoint ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            checkpointService.deleteCheckpoint(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    createTrackings ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            checkpointService.createTrackings(payload.user, payload.data)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    }
}