export default {
    'GET_ORDERS_GRID_ITEMS' (state, data) {
          state.ordersGridItems = data;
    },

    'GET_PACKAGES_GRID_ITEMS' (state, data) {
        state.packagesGridItems = data;
    },

    'FETCH_US_PICKUPS' (state, data) {
        state.pickups = data;
  }
}