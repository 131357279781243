<template>
  <v-card class="label" :class="classObject">
    <v-card-text>
      <div class="grid-x grid-margin-x">
        <div class="cell medium-6">
          <img src="../../assets/images/logo.png" alt="Ship 242" />
        </div>
        <div class="cell medium-6">
          <VueQrcode
            :value="package.barcode"
            :options="{ size: 80 }"
            v-if="package"
          />
        </div>
        <div class="cell mt-4">
          <ul>
            <li>Total Number of Packages: {{ package.totalPackages }}</li>
            <li>Package Number: {{ package.packageNo }}</li>
            <li>Weight of Package: {{ package.weight }}lbs</li>
            <li>
              Dimensions (LxWxH): {{ package.length }} x {{ package.width }} x
              {{ package.height }}
            </li>
          </ul>
        </div>
        <div class="cell">
          <h4 class="headline">Company Details</h4>
          <ul>
            <li>{{ getLocation(package.locationId) }}</li>
            <li>Nassau, Bahamas</li>
            <li>(242) 603-SHIP(7447)</li>
            <li>ship@ship242.com</li>
            <li>www.ship242.com</li>
          </ul>
        </div>
        <div class="cell">
          <h4 class="headline">Customer Details</h4>
          <ul>
            <li>First Name: {{ package.first_name }}</li>
            <li>Last Name: {{ package.last_name }}</li>
            <li>Dept #: {{ package.userId }}</li>
          </ul>
        </div>
        <div class="cell">
          <ul>
            <li>Shipping Method: {{ package.delivery }}</li>
            <li class="order-number">{{ package.sect }}</li>
          </ul>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueQrcode from "@xkeshi/vue-qrcode";

export default {
  props: ["package"],
  components: {
    VueQrcode,
  },
  data() {
    return {
      classObject: {
        "red-border": this.package.locationId == 1,
        "green-border": this.package.locationId == 2,
      },
    };
  },
  methods: {
    getLocation(id) {
      if (id === "1") {
        return "#48 Madeira Street";
      } else {
        return "#33 Harbor Bay Shopping Plaza";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  width: 384px;
  height: 576px;
  margin: 0 auto;
  padding-top: 0.5rem;
  .cell {
    margin-bottom: 0.5rem;
    text-align: center;
    .headline {
      font-size: 1.25rem !important;
      display: inline-block;
    }
  }
  ul {
    list-style: none;
    padding-left: 0px;
    text-align: center;
    li {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
.red-border {
  border: 5px solid $primary;
}
.green-border {
  border: 5px solid $secondary;
}
.order-number {
  font-size: 2rem !important;
}
@media print {
  @page {
    size: 4in 6in;
  }

  body * {
    visibility: hidden;
  }
  .label {
    width: 384px;
    height: 576px;
    margin: 0 auto;
    padding-top: 0.5rem;
    .cell {
      margin-bottom: 0.5rem;
      text-align: center;
      .headline {
        font-size: 1.25rem !important;
        display: inline-block;
      }
    }
    ul {
      list-style: none;
      padding-left: 0px;
      text-align: center;
      li {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
  .red-border {
    border: 5px solid $primary;
  }
  .green-border {
    border: 5px solid $secondary;
  }
  .order-number {
    font-size: 2rem !important;
  }
}
</style>
