import { http } from '../plugins/http.js'

export const dataService = {
    fetchLocations() {
        return http({
            method: 'get',
            url: `/locations`,
        });
    },

    fetchIslands() {
        return http({
            method: 'get',
            url: `/islands`,
        });
    },

    fetchLogs(payload) {
        return http({
            method: 'post',
            url: `/logs`,
            data: {
              data: payload
            }
        });
    },

    calculateETA(payload) {
        return http({
            method: 'post',
            url: `/calculator`,
            data: {
                data: payload
            }
        });
    },

    calculateShippingEstimate(payload) {
        return http({
            method: 'post',
            url: `/calculatorv2`,
            data: payload
        });
    }
}