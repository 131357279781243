<template>
    <div class="grid-container fluid mt-3">
        <h1 class="display-2 grey--text">Offices Page</h1>
        <div class="grid-x grid-margin-y">
            <div class="cell small-12 mt-5">
                <v-card>
                    <v-card-text>
                        <OfficesTable :offices="offices" v-if="offices.length > 0"/>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import OfficesTable from '../../components/offices/OfficesTable.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        OfficesTable
    },

    created () {
        this.getOffices()
    },

    data: () => {
        return {
            offices: []
        }
    },

    methods: {
        ...mapActions([
            'fetchOffices'
        ]),

        getOffices () {
            this.fetchOffices()
                .then((res) => {
                    this.offices = res
                })
        }
    }

    
}
</script>

<style lang="scss" scoped>

</style>


