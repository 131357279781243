// Native imports
import "./stylus/main.styl";
import "font-awesome/css/font-awesome.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import IdleVue from "idle-vue";
import Vue from "vue";
import VueClip from "vue-clip";
import Vuelidate from "vuelidate";
import Vuetify from "vuetify";

import App from "./App.vue";
import { router } from "./routes/router";
import { store } from "./store/store";

// External imports
//Use statements
Vue.use(Vuetify);
Vue.use(Vuelidate);
Vue.use(VueClip);

// Vue Instance config
const vueInstance = new Vue({
	el: "#app",
	router,
	store,
	render: (h) => h(App),
});

Vue.use(IdleVue, {
	eventEmitter: vueInstance,
	idleTime: 1800000,
});
