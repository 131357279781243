import { http } from '../plugins/http.js';

export const checkpointService = {
    getTrackings(id) {
        return http({
            method: 'get',
            url: `/packages/${id}/trackings`
        });
    },

    fetchCheckpoints() {
        return http({
            method: 'get',
            url: '/checkpoints'
        })
    },

    createTrackings(user, trackings) {
        return http({
            method: 'post',
            url: '/scan',
            data: {
                userID: user,
                data: trackings
            }
        })
    },

    createCheckpoint(checkpoint) {
        return http({
            method: 'post',
            url: `/checkpoints`,
            data: {
                data: checkpoint
            }
        });
    },

    updateCheckpoint(checkpoint) {
        return http({
            method: 'put',
            url: `/checkpoints/${checkpoint.id}`,
            data: {
                data: checkpoint
            }
        });
    },

    deleteCheckpoint(checkpoint) {
        return http({
            method: 'delete',
            url: `/checkpoints/${checkpoint}`
        });
    }
}