<template>
  <div class="page">
    <div class="grid-x">
      <div class="cell">
        <v-card>
          <v-card-title>
            <h1 class="display-2 grey--text">Unpaid Invoices Report</h1>
          </v-card-title>
          <v-card-text>
            <table>
              <thead>
                <tr>
                  <th>Invoice ID</th>
                  <th>Order ID</th>
                  <th>Client</th>
                  <th>Value ($)</th>
                  <th>Status</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(invoice,i) in invoices" :key="i" class="order-row">
                  <td>
                    <router-link
                      :to="{name: 'invoice.show', params: {id: invoice.id}}"
                    >{{invoice.id}}</router-link>
                  </td>
                  <td>
                    <router-link
                      :to="{name: 'order.detail', params: {id: invoice.order_id}}"
                    >{{invoice.order_id}}</router-link>
                  </td>
                  <td>{{invoice.client}}</td>
                  <td>{{invoice.value}}</td>
                  <td>{{invoice.status}}</td>
                  <td>{{invoice.created_at}}</td>
                </tr>
              </tbody>
            </table>
            <div class="load-more-container">
              <label>
                Page:
                <select v-model="currentPageIndex" @change="getUnpaidInvoices()">
                  <option :value="page" v-for="(page, i) in totalPages" :key="i">{{page}}</option>
                </select>
              </label>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../plugins/http.js";

export default {
  created() {
    this.getUnpaidInvoices();
  },
  data: () => {
    return {
      invoices: [],
      currentPageIndex: 1,
      totalPages: 1,
      count: 0,
    };
  },
  methods: {
    async getUnpaidInvoices() {
      const res = await http.get(
        `/invoices/unpaid?page=${this.currentPageIndex}`
      );

      this.invoices = res.data.data;
      this.currentPageIndex = res.data.meta.current_page;
      this.totalPages = res.data.meta.last_page;
      this.count = res.data.meta.total;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  background-color: #ffffff;
  margin: 0 auto;
  th {
    padding: 0.75rem;
  }
}
table tr:nth-child(even) {
  background-color: #ddd;
}
.order-row {
  td {
    text-align: center;
    padding: 0.75rem;
  }
}
.order-row:hover {
  background-color: #1976d2;
  color: white;
  td {
    a {
      color: white;
      text-decoration: underline;
    }
  }
}
.load-more-container {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}
.load-more-container select {
  background: #ddd;
  padding: 5px 15px;
}
</style>