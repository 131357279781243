import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    state: {
        ordersGridItems: [],
        packagesGridItems: [],
    },
    mutations,
    actions,
    getters
}