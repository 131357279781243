import { shipmentService } from '../../../services';
import { dataService } from '../../../services';

export default {
    fetchShipments ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.fetchShipments(payload)
            .then((response) => {
                commit('FETCH_SHIPMENTS', response.data.data);                
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    fetchShipmentsList ({ commit }, payload) {
      return new Promise((resolve, reject) => {
          shipmentService.fetchShipmentsList(payload)
          .then((response) => {
              commit('FETCH_SHIPMENTS', response.data.data);                
              resolve(response.data);
          }, error => {
              reject(error);
          })
      })
  },

    fetchShipment ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.fetchShipment(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    fetchShipmentReport ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.fetchShipmentReport(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    createShipment ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.createShipment(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    editShipment ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.editShipment(payload.id, payload.data)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    addToC13 ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.addToC13(payload.id)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    removeFromC13 ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.removeFromC13(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    addToQb ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.addToQb(payload.id, payload.date)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    getUnshippedPackages ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.getUnshippedPackages()
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    fetchGroupedPackages ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.fetchGroupedPackages(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    sendShipmentNotifications ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.sendShipmentNotifications(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    sendSingleNotification ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.sendSingleNotification(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    calculateETA ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            dataService.calculateETA(payload)
            .then((response) => {
                resolve(response.data.data);
            }, error => {
                reject(error);
            })
        })
    },

    calculateShippingEstimate ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            dataService.calculateShippingEstimate(payload)
                .then((response) => {
                    resolve(response.data.data);
                }, error => {
                    reject(error);
                })
        })
    },

    fetchAttachments ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            shipmentService.fetchAttachments(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    updateCalculations ({ commit }, payload) {
      return new Promise((resolve, reject) => {
          shipmentService.updateCalculations(payload)
          .then((response) => {
              resolve(response.data);
          }, error => {
              reject(error);
          })
      })
    }
}