import { dataService, userService } from "../../../services";

export default {
	fetchCustomers({ commit }) {
		return new Promise((resolve, reject) => {
			userService.fetchCustomers().then(
				(response) => {
					commit("FETCH_CUSTOMERS", response.data.data);
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchCustomersPaginated({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.fetchCustomersPaginated(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchWorkers({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.fetchWorkers().then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchUserCart({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.fetchUserCart(payload).then(
				(response) => {
					commit("FETCH_CART_CONTENTS", response.data.data);
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchUserInvoices({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.fetchUserInvoices(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchUserOrders({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.fetchUserOrders(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchUserOrdersList({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.fetchUserOrdersList(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchUserProfile({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.fetchUserProfile(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	editUserProfile({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.editUserProfile(payload.user, payload.data).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	openCart({ commit }) {
		commit("OPEN_CART");
	},

	closeCart({ commit }) {
		commit("CLOSE_CART");
	},

	fetchIslands({ commit }, payload) {
		return new Promise((resolve, reject) => {
			dataService.fetchIslands(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchLocations({ commit }, payload) {
		return new Promise((resolve, reject) => {
			dataService.fetchLocations(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	createUser({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.createUser(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	createWorker({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.createWorker(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	sendPasswordResetLink({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.sendPasswordResetLink(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	resetPassword({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.resetPassword(payload.password, payload.token).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchLogs({ commit }, payload) {
		return new Promise((resolve, reject) => {
			dataService.fetchLogs(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	flagUser({ commit }, payload) {
		return new Promise((resolve, reject) => {
			userService.flagUser(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
};
