<template>
    <div class="grid-x  grid-margin-y grid-margin-x small-up-1 medium-up-3">
        <div class="cell" v-for="(worker, index) in workers" :key="index">
            <v-card>
                <v-card-title>
                    <span class="headline">
                        {{ worker.full_name }}
                    </span>
                </v-card-title>
                <v-card-text>
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-6">
                            Dept #{{ worker.id }}
                        </div>
                        <div class="cell small-6">
                            {{ worker.email }}
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat color="warning" @click="editCustomer(worker)">
                        Edit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    props: ['workers'],

    methods: {
        editCustomer(worker) {
            this.$emit('load-worker', worker.id);
        }
    }
}
</script>

<style>

</style>


