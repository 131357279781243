import { officeService } from '../../../services';

export default {
    fetchOffices ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            officeService.fetchOffices()
            .then((response) => {
                commit('FETCH_OFFICES', response.data.data);                
                resolve(response.data.data);
            }, error => {
                reject(error);
            })
        })
    },

    createOffices ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            officeService.createOffice(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    updateOffices ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            officeService.updateOffice(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    },

    deleteOffices ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            officeService.deleteOffice(payload)
            .then((response) => {
                resolve(response.data);
            }, error => {
                reject(error);
            })
        })
    }
}