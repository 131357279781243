<template>
  <div class="grid-container fluid mt-3">
    <div class="grid-x">
      <div class="cell">
        <v-card>
          <v-card-title>
            <h1 class="display-2 grey--text">Dashboard</h1>
          </v-card-title>
          <v-card-text>
            <Dashboard />
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from "../../components/client/Dashboard.vue";

export default {
  components: {
    Dashboard,
  },
};
</script>

