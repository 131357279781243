<template>
    <v-card :dark="false" v-if="order" hover :to="{ name: 'order.detail', params: { id: order['id'] } } ">
        <v-card-title>
            <div class="chips__wrapper">
                <v-tooltip top>
                    <v-chip label outline color="red lighten-2" slot="activator">#{{ order['id'] }}</v-chip>
                    <span>Order ID</span>
                </v-tooltip>
                <v-tooltip top v-if="order['invoice_id']">
                    <v-chip label outline color="green lighten-1" slot="activator">#{{ order['invoice_pseudo_id'] }}</v-chip>
                    <span>Invoice ID</span>
                </v-tooltip>
                <br>
                <v-chip label>{{ order['status'] }}</v-chip>
            </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-list>
                <v-list-tile>
                    <v-list-tile-content>
                        <v-list-tile-title>
                            <v-icon>person</v-icon> {{ order['client_fullname'] }}
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-divider inset></v-divider>
                <v-list-tile>
                    <v-list-tile-content>
                        <v-list-tile-title>
                            <v-icon>airplanemode_active</v-icon> {{ order['delivery'] }}
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-divider inset></v-divider>
                <v-list-tile>
                    <v-list-tile-content>
                        <v-list-tile-title>
                            <v-icon>card_giftcard</v-icon> {{ order['# of packages'] }}
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-divider inset></v-divider>
                <!-- <v-list-tile>
                    <v-list-tile-content>
                        <v-list-tile-title>
                            <v-icon>headset</v-icon> {{ order['# of items'] }}
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile> -->
                <v-divider inset></v-divider>
                <v-list-tile>
                    <v-list-tile-content>
                        <v-list-tile-title>
                            <v-icon>date_range</v-icon> {{ order['created_at'] }}
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-divider inset></v-divider>
                <v-list-tile>
                    <v-list-tile-content>
                        <v-list-tile-title>
                            {{ order['description'] }}
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        order: Object
    },
    methods: {
        goToOrder(id) {
            console.log(id);
        }
    }
}
</script>

<style lang="scss" scoped>
    .chips__wrapper{
        width: 100%;
    }
</style>


