<template>
  <v-form>
      <div class="grid-x grid-margin-y grid-margin-x medium-up-5">
          <div class="cell">
            <v-text-field
                label="Manifest Key"
                name="manifest_key"
                v-model="shipmentForm.manifest_key">
            </v-text-field>
          </div>
          <div class="cell">
            <v-text-field
                label="Carrier"
                name="carrier"
                @input="$v.shipmentForm.carrier.$touch()"
                @blur="$v.shipmentForm.carrier.$touch()"
                :error-messages="carrierErrors"
                v-model="shipmentForm.carrier">
            </v-text-field>
          </div>
          <div class="cell">
            <v-text-field
                label="Freight Cost"
                name="freight"
                v-model="shipmentForm.freight">
            </v-text-field>
          </div>
          <div class="cell">
            <v-text-field
                label="C13"
                name="c13"
                v-model="shipmentForm.customs">
            </v-text-field>
          </div>
          <div class="cell">
            <v-text-field
                label="Arrival Date"
                name="arrival"
                type="date"
                @input="$v.shipmentForm.arrival_date.formatted.$touch()"
                @blur="$v.shipmentForm.arrival_date.formatted.$touch()"
                :error-messages="arrivalErrors"
                v-model="shipmentForm.arrival_date.formatted">
            </v-text-field>
          </div>
      </div>
      <div class="grid-x">
          <div class="cell">
              <v-select
              multiple
              autocomplete
              chips
              :items="packages"
              item-value="id"
              item-text="label"
              max-height="auto"
              v-model="shipmentForm.packages"
              >
              <template slot="selection" slot-scope="data">
                <v-chip
                  close
                  @input="data.parent.selectItem(data.item)"
                  :selected="data.selected"
                  :key="data.item.id"
                >
                  {{ data.item.label }}
                </v-chip>
              </template>
              <template slot="item" slot-scope="data">
                <v-icon>fa-envelope-o</v-icon>&nbsp;{{ data.item.label}}
              </template>
              </v-select>
          </div>
      </div>
      <div class="grid-x">
          <div class="cell">
              <v-dialog v-model="addBoxModal" max-width="350px">
                  <v-card>
                      <v-card-text>
                          <div class="grid-x">
                              <div class="cell">
                                  <v-text-field
                        label="Box Name"
                        v-model="newBoxName"
                        ></v-text-field>
                        <v-btn color="primary" @click="pushBox">
                            Create Box
                        </v-btn>
                              </div>
                          </div>
                      </v-card-text>
                  </v-card>
              </v-dialog>
              <v-btn color="primary" @click="addBox">
                  Add Box
              </v-btn>
          </div>
          <div class="cell" v-if="shipmentForm.boxes.length > 0">
              <div class="boxes" v-for="(box, i) in shipmentForm.boxes" :key="i">
                  <h3>{{ box.name }}</h3> <v-icon color="red lighten-1" @click="removeBox(i)">fa-times</v-icon>
                  <v-select
              multiple
              autocomplete
              chips
              :items="packages"
              item-value="id"
              item-text="label"
              max-height="auto"
              v-model="shipmentForm.boxes[i].packages"
              >
              <template slot="selection" slot-scope="data">
                <v-chip
                  close
                  @input="data.parent.selectItem(data.item)"
                  :selected="data.selected"
                  :key="data.item.id"
                >
                  {{ data.item.label }}
                </v-chip>
              </template>
              <template slot="item" slot-scope="data">
                <v-icon>fa-envelope-o</v-icon>&nbsp;{{ data.item.label}}
              </template>
              </v-select>
              </div>
          </div>
      </div>
      <div class="grid-x">
          <div class="cell">
              <v-text-field
              label="Notes"
              v-model="shipmentForm.notes"
              textarea
              ></v-text-field>
          </div>
      </div>
      <div class="grid-x">
          <div class="cell">
              <v-btn color="primary" @click="submit" :disabled="$v.shipmentForm.$invalid">
                  Submit
              </v-btn>
          </div>
      </div>
  </v-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex';

export default {
    props: ['packages', 'shipment'],

    created() {
        if( this.$route.name == 'shipments.create') {
            this.initCreateForm();
        } else {
            this.initEditForm();
            this.editMode = true;
        }
    },

    data() {
        return {
            editMode: false,
            shipmentForm: Object,
            addBoxModal: false,
            newBoxName: ''
        }
    },

    validations: {
        shipmentForm: {
            carrier: {
                required
            },
            arrival_date: {
                formatted: {
                    required
                }
            },
            packages: {
                required
            }
        }
    },

    computed: {
        carrierErrors () {
            const errors = []
            if (!this.$v.shipmentForm.carrier.$dirty) return errors
            !this.$v.shipmentForm.carrier.required && errors.push('Carrier is required')
            return errors
        },
        arrivalErrors () {
            const errors = []
            if (!this.$v.shipmentForm.arrival_date.formatted.$dirty) return errors
            !this.$v.shipmentForm.arrival_date.formatted.required && errors.push('Arrival Date is required')
            return errors
        }
    },

    methods: {
        addBox () {
            this.newBoxName = ''
            this.addBoxModal = true
        },
        pushBox () {
            this.shipmentForm.boxes.push({name: this.newBoxName, packages: []})
            this.addBoxModal = false
        },
        removeBox (index) {
            this.shipmentForm.boxes.splice(index, 1)
        },
        initCreateForm() {
            this.shipmentForm = {
                manifest_key: '',
                carrier: '',
                arrival_date: {
                    formatted: ''
                },
                freight: '',
                customs: '',
                packages: [],
                boxes: [],
                notes: ''
            }
        },

        initEditForm() {
            let shipment = this.shipment;

            this.shipmentForm = {
                manifest_key: shipment.manifest_key || '',
                carrier: shipment.carrier,
                arrival_date: {
                    formatted: shipment.arrival_date
                },
                freight: shipment.freight || 0,
                customs: shipment.customs || 0,
                packages: shipment.packageIDs,
                boxes: shipment.boxes,
                notes: shipment.notes
            }
        },

        submit() {
            if(this.editMode) {
                this.$emit('shipment-edited', this.shipmentForm)
            } else {
                this.$emit('shipment-created', this.shipmentForm)
            }
        }
    }
}
</script>

