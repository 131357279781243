<template>
    <div class="grid-container fluid mt-3">
        <div class="grid-x">
            <div class="cell">
                <v-card v-if="authUser.id == 1243 || authUser.id == 5387">
                    <v-card-title>
                        <h1 class="display-2 grey--text">
                            Incident Report
                        </h1>
                    </v-card-title>
                    <v-card-text>
                        <div class="grid-x" v-if="!loadingData">
                            <div class="cell medium-4 incorrect">
                              <h3>Incorrect</h3>
                              <ul>
                                <li>Processing Fee: ${{ incorrect['totalProcessing'] }}</li>
                                <li>Vat: ${{ incorrect['vatTotal'] }}</li>
                                <li>Total of Invoices Paid: ${{ incorrect['grand_total'] }}</li>
                              </ul>
                            </div>
                            <div class="cell medium-4 correct">
                              <h3>Correct</h3>
                              <ul>
                                <li>Processing Fee: ${{ correct['totalProcessing'] }}</li>
                                <li>Vat: ${{ correct['vatTotal'] }}</li>
                                <li>Total of Invoices Paid: ${{ correct['grand_total'] }}</li>
                              </ul>
                            </div>
                            <div class="cell medium-4 diff">
                              <h3>Difference</h3>
                              <ul>
                                <li>Processing Fee: -${{ processingDiff }}</li>
                                <li>Vat: -${{ vatDiff }}</li>
                                <li>Total of Invoices Paid: -${{ totalDiff }}</li>
                              </ul>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    created() {
        this.getData();
    },

    data() {
        return {
            loadingData: false,
            processingDiff: 0,
            vatDiff: 0,
            totalDiff: 0,
            incorrect: null,
            correct: null
        }
    },

    computed: {
      ...mapGetters([
        'authUser'
      ]),
    },

    methods: {
        ...mapActions([
            'fetchInjuryData'
        ]),
        getData() {
            this.loadingData = true;
            this.fetchInjuryData()
            .then((response) => {
                this.incorrect  = response.data[0]
                this.correct  = response.data[1]
                this.processingDiff = (this.correct['totalProcessing'] - this.incorrect['totalProcessing']).toFixed(2)
                this.vatDiff = (this.correct['vatTotal'] - this.incorrect['vatTotal']).toFixed(2)
                this.totalDiff = (this.correct['grand_total'] - this.incorrect['grand_total']).toFixed(2)
                this.loadingData = false;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
    padding-left: 0px;
  }
</style>


