import { http } from '../plugins/http.js'

export const authService = {
    login(credentials) {
        return http({
            method: 'post',
            url: '/authenticate',
            data: {
                email: credentials.email,
                password: credentials.password
            }
        });
    }
}