import axios from "axios";

import { router } from "../routes/router";

let apiUrl = process.env.VUE_APP_API_URL || "//ship242.test/api/v1/";

export const http = axios.create({
	baseURL: apiUrl,
});

http.interceptors.request.use(
	function(config) {
		let token = localStorage.getItem("ship_token");

		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}

		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
);

http.interceptors.response.use(
	function(response) {
		return response;
	},
	function(error) {
		if (error.response?.status === 401) {
			localStorage.removeItem("ship_user");
			localStorage.removeItem("ship_token");

			router.push({ name: "login" });
		}

		return Promise.reject(error);
	}
);
