<template>
    <div class="grid-container fluid mt-3">
        <v-card>
            <v-card-title>
                <h1 class="display-2 grey--text">
                    Create New Shipment
                </h1>
            </v-card-title>
            <v-card-text>
                <div class="grid-x">
                    <div class="cell">
                        <ShipmentForm :packages="packages" v-if="!loadingPackages" @shipment-created="submitShipment"/>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import ShipmentForm from '../../components/shipments/ShipmentForm.vue';
// import ShipmentActionButtons from '../../components/shipments/ShipmentActionButtons.vue';
import { mapActions } from 'vuex';

export default {
    components: {
        ShipmentForm,
        // ShipmentActionButtons
    },
    
    created() {
        this.getEligiblePackages();
    },

    data() {
        return {
            packages: [],
            loadingPackages: false
        }
    },

    methods: {
        ...mapActions([
            'getUnshippedPackages',
            'createShipment'
        ]),

        getEligiblePackages() {
            this.loadingPackages = true;
            this.getUnshippedPackages()
            .then((response) => {
                this.packages = response.data;
                this.loadingPackages = false;
            })
        },

        submitShipment(shipment) {
            console.log('hit')
            this.createShipment(shipment)
            .then((response) => {
                this.$router.push( { name: 'shipments.detail', params: { id: response.data.id } } )
            })
        }
    }
  
}
</script>

