<template>
  <div>
      <PackageLabel :package="package" v-if="!loading"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PackageLabel from '../../components/packages/PackageLabel.vue';

export default {
    components: {
        PackageLabel
    },
    created() {
        this.getData();
    },
    data() {
        return {
            package: Object,
            loading: false
        }
    },
    methods: {
        ...mapActions([
            'fetchPackageLabel'
        ]),
        
        getData() {
            this.loading = true;
            this.fetchPackageLabel(this.$route.params.id)
            .then((response) => {
                this.package = response.data;
                this.loading = false;
            }, error => {

            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>


