<template>
    <v-app id="register">
        <div class="page-wrapper">
      <div class="grid-x wrapper">
          <div class="cell float-center text-sm-center">
              <img src="../../assets/images/logo.png" alt="">
          </div>
          <div class="cell medium-5 float-center form-wrapper" v-if="!submitted">
              <v-card>
                  <v-card-title>
                      <h1 class="headline">Forgot Password</h1>
                  </v-card-title>
                  <v-card-text>
                      <v-form>
                  <div class="grid-x grid-margin-y">
                      <div class="cell medium-8">
                          <v-text-field
                            required
                            label="Email"
                            name="email"
                            @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                  :error-messages="emailErrors"
                            v-model="form.email">
                            </v-text-field>
                      </div>
                  </div>
              </v-form>
                  </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn flat color="success" @click="submit" :disabled="$v.form.$invalid">
                          Submit
                      </v-btn>
                  </v-card-actions>
              </v-card>
          </div>
          <div class="cell medium-5 float-center form-wrapper" v-if="submitted">
            <v-alert color="success" icon="check_circle" value="true">
                A password reset link has been sent to your email address.
                <v-btn :to="{ name: 'login' }" color="primary">
                    Login
                </v-btn>
            </v-alert>
        </div>
      </div>
  </div>
    </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators'; 

export default {
    data() {
        return {
            submitted: false,
            form: {
                email: ''
            }
        }
    },

    computed: {
        emailErrors () {
            const errors = []
            if (!this.$v.form.email.$dirty) return errors
            !this.$v.form.email.email && errors.push('Must be valid e-mail')
            !this.$v.form.email.required && errors.push('E-mail is required')
            return errors
        },
    },

        validations: {
        form: {
            email: {
                required,
                email
            }
        }
    },
    
    methods: {
        ...mapActions([
            'sendPasswordResetLink'
        ]),

        submit(data) {
            this.sendPasswordResetLink(this.form.email)
            .then((response) => {
                this.submitted = true;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .form-wrapper{
        margin-top: 2rem;
    }
    .wrapper{
        padding-top: 5rem;
    }
    .page-wrapper{
        background-color: $accent;
        height: 100vh;
    }
</style>

