export default {
    'FETCH_CUSTOMERS' (state, data) {
          state.customers = data;
    },

    'OPEN_CART' (state) {
        state.cartVisible = true;
    },

    'CLOSE_CART' (state) {
        state.cartVisible = false;
    },

    'FETCH_CART_CONTENTS' (state, data) {
        state.cartItems = data;
        let cartTotal = 0;
        data.forEach((invoice) => {
            cartTotal += invoice.value;
        })
        state.cartTotal = cartTotal;
    }
}