<template>
    <div class="grid-container fluid mt-3">
        <div class="grid-x">
            <div class="cell">
                <v-card>
                    <v-card-title>
                        <h1 class="display-2 grey--text">
                            Credit Card Payments
                        </h1>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
      v-bind:headers="headers"
      :items="items"
      hide-actions
      class="elevation-1"
    >
    <template slot="items" slot-scope="props">
      <td><v-btn flat color="green lighten-1" @click="openReceipt(props.item.id)" :target="target">{{ props.item.id }}</v-btn></td>
      <td>{{ props.item.name }}</td>
      <td>{{ props.item.vendor }}</td>
      <td>{{ props.item.description }}</td>
      <td>${{ props.item.value }}</td>
      <td>${{ props.item.processingFee }}</td>
      <td>${{ props.item.total }}</td>
    </template>
  </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>
        <v-dialog v-model="ccDialog" persistent max-width="500px">
            <CreditPaymentsForm @cc-payment-made="getPayments" @close-modal="ccDialog=false"/>
        </v-dialog>
        <v-btn
                color="primary"
                fab
                dark
                fixed
                bottom
                right
                @click="ccDialog = true"
              >
              <v-icon>add</v-icon>
              </v-btn>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import CreditPaymentsForm from '../../components/admin/CreditPaymentsForm.vue';

export default {
    components: {
        CreditPaymentsForm
    },

    created() {
        this.getPayments();
    },

    data() {
        return {
            loadingPayments: false,
            ccDialog: false,
            items: [],
            target: '_blank',
            headers: [
                {
                    text: 'Customer',
                    align: 'left',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: 'Vendor',
                    align: 'left',
                    sortable: false,
                    value: 'vendor'
                },
                {
                    text: 'Description',
                    align: 'left',
                    sortable: false,
                    value: 'description'
                },
                {
                    text: 'Cost',
                    align: 'left',
                    sortable: false,
                    value: 'value'
                },
                {
                    text: 'Fees',
                    align: 'left',
                    sortable: false,
                    value: 'processingFee'
                },
                {
                    text: 'Total',
                    align: 'left',
                    sortable: false,
                    value: 'total'
                }
                
            ]
        }
    },

    methods: {
        ...mapActions([
            'fetchCredits'
        ]),

        getPayments() {
            this.loadingPayments = true;
            this.fetchCredits()
            .then((response) => {
                this.items = response.data;
                this.loadingPayments = false;
            })
        },

        openReceipt(id) {
            let route = this.$router.resolve({ name: 'credits.show', params: {id: id} });
            window.open(route.href, '_blank');
        }
    }
}
</script>

