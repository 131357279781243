<template>
  <v-app id="login">
    <div class="login-page-container grid-x">
      <div class="cell small-10 medium-6 large-3 float-center">
        <img src="../../assets/images/logo.png" alt="Ship 242" class="float-center logo">
        <LoginForm @got-error="gotError"/>
      </div>
    </div>
    <v-snackbar
            :timeout="timeout"
            color="error"
            v-model="error"
            bottom
            right
            >
            Incorrect credentials. Please try again.
        </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import LoginForm from '../../components/login/LoginForm.vue';

export default {
  components: {
      LoginForm
  },

  created() {
    this.logout();
  },

  data() {
    return {
      error: false,
      timeout: 4000
    }
  },

  methods: {
    ...mapActions([
      'logout'
    ]),

    gotError() {
      this.error = true;
    }
  }
}
</script>

<style lang="scss" scoped>

  .login-page-container{
    background-color: $primary;
    background: url('../../assets/images/clouds-bg.gif') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
  }

  .logo{
    margin: 4rem auto 2rem auto;
  }

</style>


