<template>
<v-card class="main invoice-area">
    <v-card-title>
        <div class="grid-x grid-padding-x invoice-header">
            <div class="cell medium-4">
                <img src="../../assets/images/logo.png" alt="Ship242 Logo" class="float-center">
            </div>
            <div class="cell medium-4">
                <ul class="float-center">
                    <li>
                        <strong>From: Ship242</strong>
                    </li>
                    <li>
                        Harbor Bay Plaza, East Bay Street, Nassau
                    </li>
                    <li>
                        (242) 603-SHIP
                    </li>
                    <li>
                        ship@ship242.com
                    </li>
                    <li>
                        TIN: 101149200
                    </li>
                    <li>
                        RBC: 05745 2883635
                    </li>
<!--                    <li>-->
<!--                        CIBC First Caribbean: 201694603-->
<!--                    </li>-->
                </ul>
            </div>
            <div class="cell medium-4">
                <ul class="float-center">
                    <li>
                        <strong>To: {{ invoice.user.data.full_name }}</strong>
                    </li>
                    <li v-if="invoice.user.data.account_type == 'business' || invoice.user.data.tin">
                        <strong>TIN: {{ invoice.user.data.tin }}</strong>
                    </li>
                    <li>
                        Vat Invoice #{{ invoice.pseudo_id }}
                    </li>
                    <li>
                        Order #{{ order.id }}
                    </li>
                    <li v-if="invoice.payment_method">
                        <strong>Paid Via: {{invoice.payment_method}}</strong>
                    </li>
                    <li>
                        {{ invoice.user.data.email }}
                    </li>
                    <li>
                        Date: {{ invoice.created_at }}
                    </li>
                </ul>
            </div>
        </div>
    </v-card-title>
    <v-card-text>
        <div class="grid-x grid-padding-x small-up-7 header-row">
            <div class="cell">
                <div>
                    Desc.
                </div>
            </div>
            <div class="cell">
                <div>
                    Qty
                </div>
            </div>
            <div class="cell">
                <div>
                    Unit Price ($)
                </div>
            </div>
            <div class="cell">
                <div>
                    Shipping ($)
                </div>
            </div>
            <div class="cell">
                <div>
                    Sales Tax ($)
                </div>
            </div>
            <div class="cell">
                <div>
                    Duty/Rate
                </div>
            </div>
            <div class="cell">
                <div>
                    Total
                </div>
            </div>
        </div>
        <div class="items">
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 item-row" v-for="(item, index) of items" :key="index">
                <div class="cell">
                    {{ item.description }}
                </div>
                <div class="cell">
                    {{ item.quantity }}
                </div>
                <div class="cell">
                    {{ item.unit_price }}
                </div>
                <div class="cell">
                    {{ item.vendor_shipping }}
                </div>
                <div class="cell">
                    {{ item.sales_tax }}
                </div>
                <div class="cell">
                    {{ item.tax.data.vat }}
                </div>
                <div class="cell total">
                    ${{ item.duty_total }}
                </div>
                <div class="cell" v-if="item.tax.data.levy > 0">
                    - Levy
                </div>
                <div class="cell" v-if="item.tax.data.levy > 0">
                    -
                </div>
                <div class="cell" v-if="item.tax.data.levy > 0">
                    {{ item.tax.data.levy }}
                </div>
                <div class="cell" v-if="item.tax.data.levy > 0">
                    -
                </div>
                <div class="cell" v-if="item.tax.data.levy > 0">
                    -
                </div>
                <div class="cell" v-if="item.tax.data.levy > 0">
                    -
                </div>
                <div class="cell total" v-if="item.tax.data.levy > 0">
                    ${{ item.levy_total }}
                </div>
            </div>
            <v-divider></v-divider>
        </div>

        <div class="packages">
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 package-row" v-for="(pkg, index) of packages" :key="index">
                <div class="cell">
                    Freight ({{ invoice.order_delivery }})
                </div>
                <div class="cell">
                    {{pkg.charged_weight}} lbs
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">
                    {{ pkg.shipping_rate }}
                </div>
                <div class="cell total">
                    ${{ pkg.shipping_total }}
                </div>
                <div class="cell" v-if="pkg.hazard">*HAZARD FEE</div>
                <div class="cell" v-if="pkg.hazard">-</div>
                <div class="cell" v-if="pkg.hazard">-</div>
                <div class="cell" v-if="pkg.hazard">-</div>
                <div class="cell" v-if="pkg.hazard">-</div>
                <div class="cell" v-if="pkg.hazard">-</div>
                <div class="cell total" v-if="pkg.hazard"> ${{ pkg.hazard }} </div>
                <div class="cell" v-if="pkg.fragile">*FRAGILE FEE</div>
                <div class="cell" v-if="pkg.fragile">-</div>
                <div class="cell" v-if="pkg.fragile">-</div>
                <div class="cell" v-if="pkg.fragile">-</div>
                <div class="cell" v-if="pkg.fragile">-</div>
                <div class="cell" v-if="pkg.fragile">-</div>
                <div class="cell total" v-if="pkg.fragile"> ${{ pkg.fragile }} </div>
            </div>
            <v-divider></v-divider>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 package-row" v-if="invoice.additional">
              <div class="cell">*OVERWEIGHT FEE</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total"> ${{ invoice.additional }} </div>
            </div>
        </div>

        <div class="grand-totals">
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row">
                <div class="cell">
                    Duty
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.duty_total }}
                </div>
            </div>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row" v-if="invoice.levy_total > 0">
                <div class="cell">
                    Levy
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.levy_total }}
                </div>
            </div>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row">
                <div class="cell">
                    Ship242 Fees
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.shipping_subtotal }}
                </div>
            </div>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row" v-if="invoice.insurance">
                <div class="cell">
                    Insurance Fees
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.insurance }}
                </div>
            </div>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row" v-if="order.delivery == 'consolidate'">
                <div class="cell">
                    Consolidation Fee
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    $10
                </div>
            </div>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row" v-if="order.warehouse_via == 'pick-up'">
                <div class="cell">
                    US Pickup Fee
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    $36
                </div>
            </div>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row">
                <div class="cell">
                    Customs Processing Fee
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.processing_fee }}
                </div>
            </div>
            <div v-if="invoice.fees.data.length > 0">
                <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row" v-for="(fee, index) in invoice.fees.data" :key="index">
                <div class="cell">
                    {{ fee.name }}
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ fee.amount }}
                </div>
            </div>
            </div>
            <v-divider></v-divider>
            <!-- <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row">
                <div class="cell">
                    Subtotal
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.subtotal }}
                </div>
            </div> -->
            <!-- <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row">
                <div class="cell">
                    Vat Subtotal ({{invoice.govtVat * 100}}%)
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.vatNoFreight }}
                </div>
            </div> -->
            <!-- <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row">
                <div class="cell">
                    Freight Vat ({{invoice.govtVat * 100}}%)
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.freightVat }}
                </div>
            </div> -->
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row">
                <div class="cell">
                    Vat Total
                    <br>(Freight Tax: ${{invoice.freightVat}} )
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.vat_total }}
                </div>
            </div>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row" v-if="invoice.payment_method == 'online'">
                <div class="cell">
                    Convenience Fee
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.convenienceFee }}
                </div>
            </div>
            <v-divider></v-divider>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row" v-if="ccFeesCondition">
                <div class="cell">
                    Convenience Fee
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total">
                    ${{ invoice.ccFees }}
                </div>
            </div>
            <v-divider></v-divider>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row mt-3" v-if="!ccFeesCondition">
                <div class="cell grand-total">
                    Total
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total grand-total">
                    ${{ invoice.value }}
                </div>
            </div>
            <div class="grid-x grid-padding-x grid-margin-y small-up-7 total-row mt-3" v-if="ccFeesCondition">
                <div class="cell grand-total">
                    Total
                </div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell">-</div>
                <div class="cell total grand-total">
                    ${{ invoice.ccValue }}
                </div>
            </div>
            <div class="paid-stamp" v-if="invoice.status == 'paid'">
                <h3 class="display-4">
                    Paid
                </h3>
                <span>{{ invoice.paid_on }}</span>
            </div>
        </div>
        <div class="c13-label" v-if="packages[0]['invoice_c13']">
            <v-chip label outline color="primary" v-if="authUser.account_type == 'admin' || authUser.account_type == 'worker'">
                {{ packages[0]['invoice_c13'] }}
            </v-chip>
        </div>
    </v-card-text>
    <v-dialog width="400" v-model="typeBox" lazy>
        <v-card>
            <v-card-title class="headline">
                Set Invoice Type
            </v-card-title>
            <v-card-text>
                <v-select
                :items="types"
                box
                label="Type"
                v-model="type"
                ></v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green lighten-1" @click="update" dark>
                    Set Invoice Type
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: ['invoice'],
    created() {
        this.setData();
        if(this.invoice.status != "paid" && this.isStaff) {
            this.typeBox = true;            
        }
    },
    data() {
        return {
            order: Object,
            packages: [],
            items: [],
            typeBox: false,
            types: [
                'online',
                'cash',
                'check',
                'bank'
            ],
            type: this.invoice.type
        }
    },

    computed: {
        ...mapGetters([
            'authUser'
        ]),

        isStaff() {
            return this.authUser.account_type == 'admin' || this.authUser.account_type == 'worker'
        },

        ccFeesCondition()
        {
            if (!this.invoice.payment_method && this.invoice.type == 'online')
            {
                return true;
            }

            return false;
        }
    },
    
    methods: {
        ...mapActions([
            'updateInvoice'
        ]),
        setData() {
            this.order = this.invoice.order.data;
            this.packages = this.invoice.order.data.packages.data;
            this.setItems(this.packages);
        },

        setItems(packages) {
            packages.forEach((pkg) => {
                this.items.push(...pkg.items.data);
            })
        },

        update() {
            let payload = {
                id: this.invoice.id,
                type: this.type
            }

            this.updateInvoice(payload)
                .then((res) => {
                    this.$emit('refresh-invoice')
                    this.typeBox = false
                })
        }
    }
}
</script>

<style lang="scss" scoped>
    .main{
        padding-bottom: 6rem;
    }
    .header-row{
        margin-bottom: 2rem;
        .cell{
            div{
                background-color: #eee;
                padding: .375rem;
                font-weight: bold;
                font-size: 1.2rem;
                border-radius: 5px;
                text-align: center;
            }
        }
    }
    .total{
        text-align: right;
    }
    .items, .packages{
        margin-bottom: 4rem;
    }
    .items .item-row .cell,
    .packages .package-row .cell,
    .grand-totals .total-row .cell{
        text-align: center;
    }
    .grand-totals .total-row .cell{
        font-weight: bold;
        font-size: 1.1rem;
    }
    .grand-totals .total-row .grand-total{
        font-size: 1.5rem;
        font-weight: bold;
        color: $primary;
    }
    .invoice-header{
        width: 100%;
        margin: 0 auto;
    }
    .invoice-header .cell ul{
        list-style: none;
        padding-left: 0px;
    }
    .c13-label{
        position: absolute;
        bottom: 1rem;
        left: 1rem;
    }
    .invoice-area, .grand-totals{
        position: relative;
    }
    .paid-stamp{
        border: 4px dotted $success;
        padding: 1rem;
        width: 500px;
        height: 200px;
        margin: 0 auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: absolute;
        text-align: center;
        h3{
            color: $success;
            font-family: Arial !important;
        }
        span{
            color: $success;
            font-size: 2rem;
        }
    }
</style>
