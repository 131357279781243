<template>
<v-card :dark="false">
  <v-card-title primary-title>
    <div>
      <h3 class="headline mb-0">Login Form</h3>
      <div>Please login to access your Ship 242 account.</div>
    </div>
  </v-card-title>
  <v-card-text>
    <v-form @submit.prevent="submit">
      <v-text-field
      label="Email"
      v-model="form.email"
      @input="$v.form.email.$touch()"
      @blur="$v.form.email.$touch()"
      :error-messages="emailErrors"
      required></v-text-field>
      <v-text-field
      label="Password"
      type="password"
      v-model="form.password"
      @input="$v.form.password.$touch()"
      @blur="$v.form.password.$touch()"
      :error-messages="passwordErrors"
      required></v-text-field>
    </v-form>
  </v-card-text>
  <v-card-actions>
    <v-btn color="warning" :to="{ name: 'register' }" flat>Sign Up</v-btn>
    <v-btn color="accent" :to="{ name: 'password.forget' }" flat>Forgot Password</v-btn>
    <v-spacer></v-spacer>
    <v-btn color="primary" class="float-right" @click.prevent="submit">Login</v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions([
      'login'
    ]),
    submit() {
      this.login(this.form)
      .then(() => {
        let redirect = this.$route.query.redirect || '/orders';
        this.$router.push( {path: redirect} )
      }, error => {
        this.$emit('got-error');
      });
    }
  },
  computed: {
     emailErrors () {
        const errors = []
        if (!this.$v.form.email.$dirty) return errors
        !this.$v.form.email.email && errors.push('Must be valid e-mail')
        !this.$v.form.email.required && errors.push('E-mail is required')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.form.password.$dirty) return errors
        !this.$v.form.password.required && errors.push('Password is required')
        return errors
      }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card{
    background-color: $white;
    padding-bottom: 1rem;
    // margin-top: 6rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .card__title--primary{
    padding-top: 1rem;
  }
</style>


