<template>
  <div>
    <p v-if="invoices.length < 1">There are currently no invoices associated with this account.</p>
    <div v-if="invoices.length > 0">
        <div class="grid-x grid-margin-x grid-margin-y approved invoice-section">
            <div class="cell small-12">
                <h3 class="headline">Approved Invoices</h3>
            </div>
            <div class="cell small-12" v-if="approved.length < 1">
                <p>You do not currently have any approved invoices.</p>
            </div>
            <div class="cell medium-3" v-for="(invoice, index) in approved" :key="index">
                <v-card>
                    <v-card-text>
                        <div class="grid-x grid-margin-x">
                            <div class="cell medium-6">
                                <router-link :to="{ name: 'invoice.show', params: {id: invoice.id} }">Invoice #{{ invoice.pseudo_id }}</router-link>
                            </div>
                            <div class="cell medium-6">
                                ${{ invoice.value }}
                            </div>
                        </div>
                    <p v-if="invoice.type != 'online'" class="text-red-500">Please contact Ship242 to switch this invoice type to "Online" before it can be added to your cart.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="addToCart(invoice.id)" color="success" flat v-if="!invoiceInCart(invoice.id)" :disabled="invoice.type != 'online'">
                            Add To Cart
                        </v-btn>
                        <v-btn :disabled="true" flat color="grey lighten-1" v-if="invoiceInCart(invoice.id)">
                            Already In Cart
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
        <div class="grid-x grid-margin-x grid-margin-y paid invoice-section">
            <div class="cell small-12">
                <h3 class="headline">Paid Invoices</h3>
            </div>
            <div class="cell small-12" v-if="paid.length < 1">
                <p>You do not currently have any paid invoices.</p>
            </div>
            <div class="cell medium-3" v-for="(invoice, index) in paid" :key="index">
                <v-card>
                    <v-card-text>
                        <div class="grid-x grid-margin-x">
                            <div class="cell medium-6">
                                <router-link :to="{ name: 'invoice.show', params: {id: invoice.id} }">Invoice #{{ invoice.pseudo_id }}</router-link>
                            </div>
                            <div class="cell medium-6">
                                ${{ invoice.value }}
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['invoices', 'cartItems'],

    created() {
        this.organizeInvoices();
    },

    data() {
        return {
            approved: [],
            paid: []
        }
    },

    watch: {
        invoices: function () {
            this.organizeInvoices();
        }
    },

    methods: {
        invoiceInCart(invoice) {
            let cart = [];
            this.cartItems.forEach((invoice) => {
                cart.push(invoice.id);
            })
            if(cart.includes(invoice)) {
                return true;
            } else {
                return false;
            }
        },

        addToCart(invoice) {
            this.$emit('invoice-added', invoice);
        },

        organizeInvoices() {
            this.invoices.forEach((invoice) => {
                if(invoice.status == 'approved') {
                    this.approved.push(invoice)
                } else if(invoice.status == 'paid') {
                    this.paid.push(invoice);
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .invoice-section{
        margin-bottom: 1rem;
    }
</style>


