<template>
    <div class="grid-container fluid mt-3">
        <h1 class="display-2 grey--text">Duty & Tariffs</h1>
        <div class="grid-x grid-margin-y">
            <div class="cell medium-4">
                <v-text-field
                v-model="search"
                label="Search Customs Duties"
                name="search"
                @input="filterResults(search)">
                </v-text-field>
            </div>
            <div class="cell">
                <TaxList :taxes="tempTaxes" v-if="!loadingTaxes" @refresh-taxes="getTaxes"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TaxList from '../../components/taxes/TaxList.vue';
import debounce from 'lodash/debounce';


export default {
    components: {
        TaxList
    },
    created() {
        this.getTaxes();
    },

    data() {
        return {
            tempTaxes: [],
            search: '',
            loadingTaxes: false
        }
    },

    computed: {
        ...mapGetters([
            'taxes'
        ])
    },

    methods: {
        ...mapActions([
            'fetchTaxes'
        ]),

        getTaxes() {
            this.loadingTaxes = true;
            this.fetchTaxes()
                .then((response) => {
                    this.tempTaxes = this.taxes.slice(0, 50);
                    this.loadingTaxes = false;
                }, error => {

                })
        },

        filterResults(search) {

            let val = search.toLowerCase();

            let temp = this.taxes.filter(function( tax ) {

                let tariffCode = tax.tariff_code || ""
                let name = tax.description.toLowerCase().indexOf(val) !== -1
                let code = tariffCode.toLowerCase().indexOf(val) !== -1

                return name || code || !val;

            });

            let results = temp.slice(0, 50);
            this.tempTaxes = results;
            
        }
    }

}
</script>

