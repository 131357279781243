<template>
  <div class="page">
    <div class="grid-x">
      <div class="cell">
        <v-card>
          <v-card-title>
            <h1 class="display-2 grey--text">Delivered Orders / No Invoice Report</h1>
          </v-card-title>
          <v-card-text>
            <table>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Client</th>
                  <th>Shipping Method</th>
                  <th>Description</th>
                  <th>Created At</th>
                  <th>Delivered At</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order,i) in orders" :key="i" class="order-row">
                  <td>
                    <router-link :to="{name: 'order.detail', params: {id: order.id}}">{{order.id}}</router-link>
                  </td>
                  <td>{{shortDescription(order.client)}}</td>
                  <td>{{order.delivery}}</td>
                  <td>{{shortDescription(order.description)}}</td>
                  <td>{{order.created_at}}</td>
                  <td>{{order.date_delivered}}</td>
                </tr>
              </tbody>
            </table>
            <div class="load-more-container">
              <label>
                Page:
                <select v-model="currentPageIndex" @change="getUnpaidInvoices()">
                  <option :value="page" v-for="(page, i) in totalPages" :key="i">{{page}}</option>
                </select>
              </label>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../plugins/http.js";

export default {
  created() {
    this.getOrders();
  },
  data: () => {
    return {
      orders: [],
      currentPageIndex: 1,
      totalPages: 1,
      count: 0,
    };
  },
  methods: {
    async getOrders() {
      const res = await http.get(
        `/orders/delivered/no-invoice?page=${this.currentPageIndex}`
      );

      this.orders = res.data.data;
      this.currentPageIndex = res.data.meta.current_page;
      this.totalPages = res.data.meta.last_page;
      this.count = res.data.meta.total;
    },
    shortDescription(description) {
      let length = 30;
      let ending = "...";

      if (description.length > length) {
        return description.substring(0, length - ending.length) + ending;
      } else {
        return description;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  background-color: #ffffff;
  margin: 0 auto;
  th {
    padding: 0.75rem;
  }
}
table tr:nth-child(even) {
  background-color: #ddd;
}
.order-row {
  // cursor: pointer;
  td {
    text-align: center;
    padding: 0.75rem;
  }
}
.order-row:hover {
  background-color: #1976d2;
  color: white;
  td {
    a {
      color: white;
      text-decoration: underline;
    }
  }
}
.load-more-container {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}
.load-more-container select {
  background: #ddd;
  padding: 5px 15px;
}
</style>