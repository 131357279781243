import { http } from '../plugins/http.js';

export const shipmentService = {
    fetchShipments(page) {
        return http({
            method: 'get',
            url: `/shipments`,
            params: {
                page: page
            }
        });
    },

    fetchShipmentsList(page) {
      return http({
          method: 'get',
          url: `/shipments/list`,
          params: {
              page: page
          }
      });
  },

    fetchShipment(id) {
        return http({
            method: 'get',
            url: `/shipments/${id}`
        });
    },

    fetchShipmentReport(payload) {
        return http({
            method: 'get',
            url: `/shipments/${payload.id}/report?page=${payload.page}`
        });
    },

    createShipment(shipment) {
        return http({
            method: 'post',
            url: `/shipments`,
            data: {
                data: shipment
            }
        });
    },

    editShipment(id, shipment) {
        return http({
            method: 'put',
            url: `/shipments/${id}`,
            data: {
                data: shipment
            }
        });
    },

    addToC13(id) {
        return http({
            method: 'post',
            url: `/packages/c13`,
            data: {
                id: id
            }
        })
    },

    removeFromC13(id) {
        return http({
            method: 'delete',
            url: `/packages/${id}/c13`,
        })
    },

    addToQb(id, date) {
        return http({
            method: 'post',
            url: `/packages/qb`,
            data: {
                id: id,
                date: date
            }
        })
    },

    getUnshippedPackages() {
        return http({
            method: 'get',
            url: `/unshipped/packages`
        })
    },

    fetchGroupedPackages(id) {
        return http({
            method: 'get',
            url: `/grouped/packages/${id}`
        });
    },

    sendShipmentNotifications(id) {
        return http({
            method: 'get',
            url: `/shipments/${id}/notify`
        });
    },

    sendSingleNotification(id) {
        return http({
            method: 'get',
            url: `/shipments/parcels/${id}/notify`
        });
    },

    fetchAttachments(id) {
        return http({
            method: 'get',
            url: `/shipments/${id}/attachments`
        });
    },

    updateCalculations(data) {
      return http({
          method: 'post',
          url: `/shipments/${data.id}/calculations`,
          data: {
              calculations: data.calculations
          }
      });
  },
}