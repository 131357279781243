import { http } from "../plugins/http.js";

export const reportService = {
	fetchEODReports() {
		return http({
			method: "get",
			url: `/reports`,
		});
	},

	createEOD(payload) {
		return http({
			method: "post",
			url: `/reports`,
			data: {
				data: payload,
			},
		});
	},

	fetchAccEODReports() {
		return http({
			method: "get",
			url: `/reports/acc`,
		});
	},

	createAccEOD(payload) {
		return http({
			method: "post",
			url: `/reports/acc`,
			data: {
				data: payload,
			},
		});
	},

	fetchEOD(payload) {
		return http({
			method: "get",
			url: `/transactions/daily`,
			params: {
				reportId: payload,
			},
		});
	},

	fetchAccEOD(payload) {
		return http({
			method: "get",
			url: `/transactions/daily/acc`,
			params: {
				reportId: payload,
			},
		});
	},

	fetchInvoiceUploads() {
		return http({
			method: "get",
			url: `/invoices/upload`,
		});
	},

	fetchOldPackages(payload) {
		return http({
			method: "get",
			url: `/packages/old`,
			params: {
				page: payload.page,
				search: payload.search,
			},
		});
	},

	fetchClientOrderReport(payload) {
		return http({
			method: "get",
			url: `/reports/orders-by-clients?page=${payload.page}`,
			params: payload.filters,
		});
	},
};
