<template>
  <div class="page">
    <div class="toolbar">
      <div class="logged-in" v-if="authUser">
        <v-navigation-drawer
          clipped
          persistent
          disable-route-watcher
          v-model="drawer"
          enable-resize-watcher
          app
          width="220"
        >
          <v-list dense>
            <v-list-tile
              v-for="(route, index) in routes"
              :key="index"
              @click="routeTo(route.route)"
              v-if="hasAccess(route.access)"
            >
              <v-list-tile-action>
                <v-icon>{{ route.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ route.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-navigation-drawer>
        <v-toolbar app fixed clipped-left>
          <v-toolbar-side-icon
            @click.stop="drawer = !drawer"
          ></v-toolbar-side-icon>
          <v-toolbar-title>
            <img src="../../assets/images/logo.png" alt class="logo" />
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="greeting">
            <span class="user-name">Hello, {{ authUser.first_name }}</span>
            <v-tooltip bottom>
              <v-icon
                color="grey lighten-1"
                large
                slot="activator"
                @click="goToProfile"
                >fa-user-circle-o</v-icon
              >
              <span>My Account</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-icon
                color="grey lighten-1"
                large
                slot="activator"
                @click="logout"
                >fa-sign-out</v-icon
              >
              <span>Logout</span>
            </v-tooltip>
            <span class="cart-section">
              <v-badge right>
                <span slot="badge">{{ cartItems.length }}</span>
                <v-icon large color="grey lighten-1" @click="openCart"
                  >fa-shopping-cart</v-icon
                >
              </v-badge>
              <Cart
                class="cart"
                v-if="cartVisible"
                :invoices="cartItems"
                :total="cartTotal"
                @close-cart="closeCart"
                @remove-invoice="removeInvoice"
              />
            </span>
          </div>
        </v-toolbar>
      </div>
      <div class="guest-toolbar container mx-auto p-4" v-else>
        <div class="flex flex-wrap justify-between">
          <div class="logo w-1/2">
            <img src="../../assets/images/logo.png" alt class="logo" />
          </div>
          <div class="actions w-1/2 text-right">
            <span
              class="cursor-pointer text-xl font-bold text-gray-600 hover:text-gray-900 uppercase"
              @click="forceLogin()"
              >Login</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="container content">
      <div class="heading mb-10">
        <h1 class="text-2xl">Orders For Sale</h1>
      </div>
      <div class="content">
        <div class="category-filter mb-4">
          <label>
            <span class="block text-lg">Category:</span>
            <select
              v-model="category"
              class="p-2 bg-gray-300"
              @change="changeCategory()"
            >
              <option value>All Categories</option>
              <option :value="tax.id" v-for="(tax, i) in taxes">
                {{ tax.description }}
              </option>
            </select>
          </label>
        </div>
        <div class="product-grid flex flex-wrap -mx-2" v-if="orders.length > 0">
          <div
            class="box w-1/3 mb-4 px-2"
            v-for="(order, i) in orders"
            v-if="order.invoice_total"
          >
            <div class="rounded shadow-lg p-2">
              <div class="detail">
                <span class="font-semibold">Order ID:</span>
                {{ order.pseudo_id }}
              </div>
              <!-- <div class="detail">
                <span class="font-semibold">Invoice ID:</span>
                {{order.invoice_id}}
              </div>-->
              <div class="detail items">
                <span class="font-semibold">Items:</span>
                {{ order.items.join(",") }}
              </div>
              <div class="detail images" v-if="order.images.length > 0">
                <span class="font-semibold">Images:</span>
                <a
                  class="text-blue-500 underline cursor-pointer mr-2"
                  :href="image.url"
                  target="_blank"
                  v-for="(image, i) in order.images"
                  :key="i"
                  >{{ image.file_name }}</a
                >
              </div>
              <div class="detail text-lg">
                <span class="font-semibold">Price:</span>
                ${{ order.invoice_total }}
              </div>
              <div class="detail button mt-2">
                <button
                  class="text-green-100 bg-green-600 text-lg rounded px-2 py-1 hover:shadow-md outline-none"
                  @click="forceLogin()"
                  v-if="!authUser"
                >
                  Buy Now
                </button>
                <button
                  class="text-green-100 bg-green-600 text-lg rounded px-2 py-1 hover:shadow-md outline-none"
                  @click="addToCart(order.invoice_id)"
                  v-if="authUser && !invoiceInCart(order.invoice_id)"
                >
                  Add To Cart
                </button>
                <button
                  class="bg-gray-400 text-lg rounded px-2 py-1 outline-none cursor-not-allowed pointer-events-none"
                  v-if="authUser && invoiceInCart(order.invoice_id)"
                >
                  Already In Cart
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="load-more-container flex justify-center mt-5"
          v-if="totalPages > 1"
        >
          <label>
            Page:
            <select
              v-model="currentPage"
              @change="getOrders()"
              class="bg-gray-400 px-2 py-1 outline-none font-semibold"
            >
              <option :value="page" v-for="(page, i) in totalPages" :key="i">
                {{ page }}
              </option>
            </select>
            of {{ totalPages }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Cart from "../../components/cart/Cart.vue";

export default {
  components: {
    Cart,
  },
  created() {
    this.getOrders();
    this.getCart();
  },
  data: () => {
    return {
      orders: [],
      taxes: [],
      currentPage: 1,
      totalPages: 0,
      category: "",
      drawer: false,
      routes: [
        // {
        //   icon: 'dashboard',
        //   title: 'Dashboard',
        //   route: 'admin.dashboard',
        //   access: ['worker', 'admin']
        // },
        {
          icon: "dashboard",
          title: "Dashboard",
          route: "client.dashboard",
          access: ["personal", "business"],
        },
        {
          icon: "store",
          title: "Orders",
          route: "orders.index",
          access: ["worker", "admin"],
        },
        // {
        //   icon: 'fa-plane',
        //   title: 'US Pickups',
        //   route: 'pickups',
        //   access: ['worker', 'admin']
        // },
        {
          icon: "fa-ship",
          title: "Shipments",
          route: "shipments.index",
          access: ["worker", "admin"],
        },
        {
          icon: "fa-line-chart",
          title: "Reports",
          route: "reports.index",
          access: ["admin"],
        },
        {
          icon: "fa-users",
          title: "Customers",
          route: "customers.index",
          access: ["worker", "admin"],
        },
        {
          icon: "fa-briefcase",
          title: "Workers",
          route: "workers.index",
          access: ["admin"],
        },
        {
          icon: "fa-balance-scale",
          title: "Duty & Tarrifs",
          route: "taxes",
          access: ["worker", "admin"],
        },
        {
          icon: "fa-map-marker",
          title: "Checkpoints",
          route: "checkpoints",
          access: ["admin"],
        },
        {
          icon: "line_style",
          title: "Scan Packages",
          route: "packages.scan",
          access: ["admin", "worker"],
        },
        {
          icon: "fa-cc-visa",
          title: "CC Payments",
          route: "credits",
          access: ["admin", "worker"],
        },
        {
          icon: "fa-pencil-square-o",
          title: "Activity Logs",
          route: "admin.logs",
          access: ["admin"],
        },
        {
          icon: "fa-exclamation-triangle",
          title: "Incident Report",
          route: "admin.incident",
          access: ["admin"],
        },
        {
          icon: "fa-line-chart",
          title: "The EOD",
          route: "acc.report",
          access: ["admin", "accounts"],
        },
        {
          icon: "fa-building",
          title: "Offices",
          route: "offices.index",
          access: ["admin"],
        },
        // {
        //   icon: "shopping_basket",
        //   title: "Orders For Sale",
        //   route: "retail.index",
        //   access: ["personal", "business"]
        // }
      ],
    };
  },
  computed: {
    ...mapGetters(["authUser", "cartVisible", "cartItems", "cartTotal"]),
    isAdmin() {
      if (this.authUser.account_type == "admin") {
        return true;
      }
    },

    isStaff() {
      if (
        this.authUser.account_type == "admin" ||
        this.authUser.account_type == "worker"
      ) {
        return true;
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchUserCart",
      "logout",
      "openCart",
      "closeCart",
      "removeInvoiceFromCart",
      "addInvoiceToCart",
    ]),
    invoiceInCart(invoice) {
      let cart = [];
      this.cartItems.forEach((invoice) => {
        cart.push(invoice.id);
      });
      if (cart.includes(invoice)) {
        return true;
      } else {
        return false;
      }
    },
    routeTo(route) {
      this.$router.push({ name: route });
    },
    hasAccess(access) {
      if (access.includes(this.authUser.account_type)) {
        return true;
      }
      return false;
    },
    getOrders() {
      let params = {
        page: this.currentPage,
        category: this.category,
      };

      this.$store.dispatch("getRetailOrders", params).then((res) => {
        this.orders = res.data;
        this.currentPage = res.meta.current_page;
        this.totalPages = res.meta.last_page;
        this.taxes = res.taxes;
      });
    },
    changeCategory() {
      this.currentPage = 1;
      this.getOrders();
    },
    logoutUser() {
      this.logout();
      this.$router.push({ name: "login" });
    },

    goToProfile() {
      this.$router.push({ name: "profile" });
    },

    getCart() {
      this.fetchUserCart(this.authUser.id);
    },
    addToCart(invoice) {
      let payload = {
        invoice: invoice,
        user: this.authUser.id,
      };

      this.addInvoiceToCart(payload).then((response) => {
        this.fetchUserCart(this.authUser.id).then((response) => {
          this.openCart();
        });
      });
    },
    removeInvoice(invoice) {
      let payload = {
        invoice: invoice,
        user: this.authUser.id,
      };

      this.removeInvoiceFromCart(payload).then((response) => {
        this.getCart();
      });
    },
    forceLogin() {
      this.$router.push({
        name: "login",
        query: { redirect: "/orders-for-sale" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container .logo img {
  width: 200px;
  height: auto;
}
.logo {
  width: 50%;
  height: auto;
}
.user-name {
  margin-right: 10px;
}
.greeting {
  margin-right: 2rem !important;
  position: relative;
}
.greeting .fa {
  font-size: 2rem;
  margin-left: 10px;
  cursor: pointer;
}
.cart-section {
  position: relative;
}
.cart {
  position: absolute;
  width: 300px;
  right: 0px;
}
.container.content {
  margin-top: 64px;
}
</style>


